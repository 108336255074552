import { breakpoint } from '@/helpers/BreakpointHelper';
import React, { FC, ImgHTMLAttributes } from 'react';
import styled from 'styled-components';

interface ItemProps {
  items: Gift[];
}

export interface Gift {
  id: string | number;
  tit: string;
  titEn: string;
  price: number;
  cnt: number;
  img: ImgHTMLAttributes<any>['src'];
}

const ListCpontainer = styled.div`
  margin-top: 24px;
  height: 424px;
  overflow-y: scroll;
  .link-item {
    position: absolute;
    right: 0;
    top: 5px;
    color: #828385;
    text-decoration: underline;
  }
`;

const ItemList = styled.ul`
  & > li {
    display: flex;
    position: relative;
    padding: 25px 0;
    border-bottom: 1px solid #e5e6e8;

    &:last-child {
      border-bottom: 0;
    }
  }
`;

const ColImg = styled.div`
  width: 160px;
  margin-left: 9px;

  ${breakpoint(`tablet`)} {
    display: none;
  }
  .img-box {
    padding-top: 100%;
    border: 1px solid #e5e6e8;
  }
`;

const ImgBox = styled.div`
  padding-top: 100%;
  border: 1px solid #e5e6e8;
`;

const ColDec = styled.div`
  position: relative;
  width: calc(100% - 195px);
  padding-left: 32px;
  padding-top: 5px;
  line-height: 1;

  ${breakpoint(`tablet`)} {
    width: calc(100% - 33px);
    padding: 0;
  }
`;

const DecWrap = styled.div`
  position: relative;

  ${ImgBox} {
    display: none;
  }

  ${breakpoint(`tablet`)} {
    padding-left: 130px;
    min-height: 110px;

    ${ImgBox} {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 110px;
      height: 110px;
      padding-top: 0;
    }
  }

  .tit {
    display: block;
  }

  p {
    margin-top: 15px;
    font-weight: 100;
  }

  .price {
    display: block;
    margin-top: 25px;
  }
`;

const SupportedGiftList: FC<ItemProps> = ({ items: itemData }) => (
  <ListCpontainer>
    <ItemList>
      {itemData.map((row) => (
        <li key={row.id}>
          <ColImg>
            <ImgBox
              className="img-box"
              css={`
                background-image: url(${row.img});
              `}
            />
          </ColImg>
          <ColDec>
            <DecWrap>
              <ImgBox
                className="img-box"
                css={`
                  background-image: url(${row.img});
                `}
              />
              <strong className="tit">{row.tit}</strong>
              <p>{row.titEn}</p>
              <strong className="price">
                {row.price.toLocaleString()} 원 ({row.cnt}개)
              </strong>
            </DecWrap>
          </ColDec>
        </li>
      ))}
    </ItemList>
  </ListCpontainer>
);
export default SupportedGiftList;
