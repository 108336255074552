import SwiperOverflowHidden from './Swiper/SwiperOverflowHidden';
import { Tit } from './Titles';
import { SUPPORT_PHRASE_DATA } from '@/data/PhraseItemData';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { hasJong } from '@/helpers/HangulHelper';
import React, { FC } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';

const Under = styled.strong`
  font-weight: 700;
  display: inline-block;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 8px;
    background: #b2def1;
    position: absolute;
    left: 0;
    bottom: 4px;
  }
  .dec {
    position: relative;
    z-index: 1;
  }
`;

const SwiperValue = styled(SwiperOverflowHidden)`
  padding: 0 30px;

  .swiper-button-prev,
  .swiper-button-next {
    margin: 0;
    left: 10px;
    bottom: 30px;
    z-index: 10;
  }
  .swiper-button-next {
    left: auto;
    right: 10px;
  }
  .swiper-container {
    padding: 0;
    text-align: center;
    ${Tit} {
      display: block;
    }

    ${Under} {
      margin-top: 16px;

      &::before {
        bottom: 7px;
      }
    }
    .swiper-slide {
      padding: 0 60px;
    }
  }

  .swiper-scrollbar {
    display: none;
  }
  .swiper-pagination {
    max-width: 530px;
    margin: 0 auto;
    margin-top: 0;
    display: flex;
    position: relative;

    .dec {
      color: #b7b8ba;
      display: inline-block;
    }
  }

  // css 오류 수정
  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 0;
    .swiper-pagination-bullet {
      margin: 0 0 48px 0;
    }
  }
  .swiper-pagination-bullet {
    width: 20%;
    position: relative;
    padding-top: 64px;
    text-align: center;
    color: #b7b8ba;
    font-weight: 700;
    /* margin-bottom: 48px; */
    cursor: pointer;
    // css 오류 수정
    height: auto;
    background: none;
    opacity: 1;

    &.swiper-pagination-bullet-active {
      color: #1cabe2;
    }

    &::before {
      content: '';
      display: block;
      width: 56px;
      height: 56px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, 0);
    }

    ${() =>
      Object.entries(SUPPORT_PHRASE_DATA).map(
        ([category, data], index) => `
      &.${category},
      &:nth-child(${index + 1}) {
        &::before {
          background-image: url(${data.icon});
        }
        &.nutrition,
        &.swiper-pagination-bullet-active {
          &::before {
            background-image: url(${data.iconActive});
          }
        }
      }
      `,
      )}
  }

  ${breakpoint(1160)} {
    .swiper-pagination {
      .swiper-pagination-bullet {
        margin-bottom: 4.14vw;
      }
    }
  }
  ${breakpoint(`tablet`)} {
    padding: 0;
    .swiper-scrollbar {
      display: block;
      left: 0;
      right: 0;
    }
    .swiper-button-prev,
    .swiper-button-next,
    .swiper-pagination {
      display: none;
    }
    .swiper-slide {
      padding: 0 !important;
      .swiper-pagination-bullet {
        width: 56px;
        margin: 0 auto;
        margin-bottom: 24px;
        color: #1cabe2;
        ${() =>
          Object.entries(SUPPORT_PHRASE_DATA).map(
            ([category, data], index) => `
              &.${category},
              &:nth-child(${index + 1}) {
                &::before {
                  background-image: url(${data.iconActive});
                }
                &.nutrition,
                &.swiper-pagination-bullet-active {
                  &::before {
                    background-image: url(${data.iconActive});
                  }
                }
              }
              `,
          )}
      }
    }
  }
`;

const SupportPhraseSwiper: FC<{ supportAmount: number }> = React.memo(
  ({ supportAmount }) => (
    <SwiperValue>
      <Swiper
        slidesPerView="auto"
        scrollbar={{ draggable: true }}
        navigation
        pagination={{
          clickable: true,
          renderBullet(index, className) {
            return `<span class="${className}">${
              Object.values(SUPPORT_PHRASE_DATA)[index].name
            }</span>`;
          },
        }}
      >
        {Object.entries(SUPPORT_PHRASE_DATA).map(([category, row]) => {
          const itemIndex = Math.floor(row.items.length * Math.random());
          const item = row.items[itemIndex];
          // 누적후원금액으로 구입가능 갯수 카운팅
          const count = Math.floor(supportAmount / item.price);
          const displayPrice = Math.floor(item.price);
          return (
            <SwiperSlide key={row.name}>
              <span className={`swiper-pagination-bullet pc-hide ${category}`}>
                {row.name}
              </span>
              <Tit size="s4">
                전 세계 모든 어린이에게 <br />
                {item.name}
                {` `}
                <strong
                  css={`
                    color: #1cabe2;
                  `}
                >
                  {count.toLocaleString()}
                </strong>
                {item.unit}
                {hasJong(item.unit) ? `을` : `를`} 전달할 수 있습니다.
              </Tit>
              <Under>
                <Tit size="s4" className="dec">
                  {item.name} 1{item.unit} = {displayPrice.toLocaleString()}원
                </Tit>
              </Under>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </SwiperValue>
  ),
);

export default SupportPhraseSwiper;
