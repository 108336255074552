import {
  PaymentControllerService,
  PaymentMasterInfoVo,
} from '@/__generated__/CommonApi';
import {
  myMissHistoryVo,
  MySupportHistoryControllerService,
} from '@/__generated__/FrontApi';
import Button, { BtnClose } from '@/components/Button';
import { PopCcontainer, PopupTitle } from '@/components/Css/Pop';
import FormGroup, { FormItem } from '@/components/Form/FormGroup';
import InputRadio, { InputRadioGroup } from '@/components/Input/InputRadio';
import InputText from '@/components/Input/InputText';
import Modal, { ModalProps } from '@/components/Modal';
import { NotiBox, NotiItem, NotiList } from '@/components/Text/TextNoti';
import InicisMobileOnceForm, {
  InicisMobileOnceRef,
} from '@/components/ThirdParty/InicisMobileOnceForm';
import InicisPcOnceForm, {
  InicisPcOnceRef,
} from '@/components/ThirdParty/InicisPcOnceForm';
import MobiliansOnceForm, {
  MobiliansOnceRef,
} from '@/components/ThirdParty/MobiliansOnceForm';
import NaverPayForm, {
  NaverPayRef,
} from '@/components/ThirdParty/NaverPayForm';
import { H3, H4, Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { isMobileDevice } from '@/helpers/BrowserHelper';
import {
  INICIS_PAY_METHOD_MOBILE_MAP,
  INICIS_PAY_METHOD_PC_MAP,
  PAYTYPE_ONCE_BANKING,
  PAYTYPE_ONCE_CARD,
  PAYTYPE_ONCE_NAVERPAY,
  PAYTYPE_ONCE_PHONE,
} from '@/helpers/PaymentHelper';
import SessionStorage from '@/services/SessionStorage';
import { usePopupStore } from '@/stores/PopupStore';
import { navigate } from 'gatsby';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';

const Container = styled.div``;

const HistoryItem = styled.li`
  display: table;
  width: 100%;
  table-layout: fixed;
  padding: 32px 0;
  position: relative;
  border-bottom: 1px solid #b7b8ba;
  ${breakpoint(800)} {
    display: block;
    padding: 24px 0;
  }
  ${Button} {
    width: 160px;
    ${breakpoint(`mobile`)} {
      width: initial;
    }
  }
`;

const InputTextCustom = styled.div<{ active?: boolean }>`
  border: 1px solid #e5e6e8;
  margin-top: -1px;
  ${({ active }) => {
    if (!active) {
      return `
            span {
              color: #2d2926 !important;
            }
          `;
    }
    return ``;
  }}
`;
const Tip = styled.p`
  color: #828385;
  font-size: 14px;
  font-weight: normal;
`;

const Month = styled.div``;
const Category = styled.div`
  min-height: 44px;
  line-height: 2;
  color: #828385;
  strong {
    color: #1cabe2;
    display: inline-block;
    margin-left: 40px;
    &.first {
      margin-left: 0;
    }
  }
  ${Button} {
    margin-left: 32px;
    ${breakpoint(640)} {
      display: block;
      margin-left: 90px;
      height: 36px;
      line-height: 36px;
      font-size: 14px;
    }
  }
  ${breakpoint(640)} {
    margin-top: 18px;
  }
`;
const PriceDetail = styled.div`
  margin-top: 18px;
  span {
    position: relative;
    padding-right: 16px;
    &.is-failed {
      color: #e2231a;
    }
    & + span {
      padding-left: 16px;
      &::before {
        content: '';
        display: block;
        width: 1px;
        height: 16px;
        background: #e5e6e8;
        position: absolute;
        left: 0;
        top: 5px;
      }
    }
  }
`;

const Cell = styled.div<{ type?: string; status?: string; price?: boolean }>`
  display: table-cell;
  ${InputTextCustom} {
    span {
      width: 10%;
      text-align: right;
      color: #b7b8ba;
    }
  }
  ${breakpoint(800)} {
    display: block;
    ${({ status }) => {
      if (status === `dark` || status === `light`) {
        return `
            position: absolute;
            right: 0;
            top: 24px;
            font-size: 16px;
            text-align: right;
            white-space: nowrap;
          `;
      }
      return ``;
    }}
    ${({ type }) => {
      if (type === `date`) {
        return `
        text-align: left;
        width: 100%;
        font-size: 16px;
          `;
      }
      if (type === `status`) {
        return `
            position: absolute;
            right: 0;
            top: 24px;
            font-size: 16px;
            text-align: right;
            white-space: nowrap;
            width: 100%;
          `;
      }
      return `padding-left: 0;`;
    }}
  }
  ${({ type }) => {
    if (type === `date` || type === `status`) {
      return `
            width: 15%;
            text-align: center;
            font-weight: bold;
            font-size: 20px;
            .sky {
              color: #1cabe2
            }
          `;
    }
    if (type === `reward`) {
      return `
        font-size: 16px;   
        width: 10%;   
        color: #828385;
      `;
    }
    if (type === `address`) {
      return `  
        width: 20%;
        text-align: right;   
        vertical-align: middle;
        ${Tip} {
          color: #828385 !important;
          margin-top: 16px;
        }
      `;
    }
    return `padding-left: 24px;`;
  }}
  ${({ price }) => {
    if (price) {
      return `
            width: 40%;
            padding-right: 16px;
            ${InputText} {
              border: none;
              width: 90%;
              display: inline-block;
            }
          `;
    }
    return ``;
  }}
  ${({ status }) => {
    if (status === `dark`) {
      return `
            color: #2d2926
          `;
    }
    if (status === `light`) {
      return `
            color: #828385;
          `;
    }
    return ``;
  }}
`;
const NoData = styled.div`
  text-align: center;
  border-top: 1px solid #e5e6e8;
  border-bottom: 1px solid #e5e6e8;
  padding: 96px 0;
  ${H3} {
    line-height: 1.7;
  }
  ${breakpoint(640)} {
    ${H3} {
      font-size: 14px;
      line-height: 28px;
    }
  }
`;

const PAY_KIND_MAP: { [key: string]: string } = {
  CMS: `자동이체`,
  CARD: `카드`,
  NPAY: `네이버페이`,
};

interface PaymentFormData {
  paymentType: string;
}

interface PaymentModalProps extends ModalProps {
  onRequestPay: (paymentType: string) => void;
}

const PaymentModal: FC<PaymentModalProps> = ({
  onRequestClose,
  onRequestPay,
  ...props
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<PaymentFormData>();

  const onSubmit: SubmitHandler<PaymentFormData> = useCallback(
    ({ paymentType }) => {
      onRequestPay(paymentType);
    },
    [onRequestPay],
  );

  return (
    <Modal
      dismissable
      maxWidth="500px"
      onRequestClose={onRequestClose}
      {...props}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <PopCcontainer>
          <PopupTitle>
            <h2>
              <Tit size="s3" color="sky">
                후원금 납입 방법을 선택해주세요
              </Tit>
            </h2>
            <BtnClose onClick={onRequestClose}>
              <span className="a11y">닫기</span>
            </BtnClose>
          </PopupTitle>
          <FormItem
            noBorder
            css={`
              padding: 20px 0 !important;
            `}
          >
            <FormGroup>
              <H4>납입방법</H4>
              <InputRadioGroup full>
                <InputRadio
                  name="paymentType"
                  ref={register}
                  tab
                  value={PAYTYPE_ONCE_CARD}
                  label="신용카드"
                  defaultChecked
                />
                {/* <InputRadio
                  name="paymentType"
                  ref={register}
                  tab
                  value={PAYTYPE_ONCE_PHONE}
                  label="휴대폰"
                /> */}
                <InputRadio
                  name="paymentType"
                  ref={register}
                  tab
                  value={PAYTYPE_ONCE_BANKING}
                  label="무통장입금"
                />
                <InputRadio
                  name="paymentType"
                  ref={register}
                  tab
                  value={PAYTYPE_ONCE_NAVERPAY}
                  label="네이버페이"
                />
              </InputRadioGroup>
            </FormGroup>
            <FormGroup>
              <Button type="submit" full>
                선택
              </Button>
            </FormGroup>
          </FormItem>
        </PopCcontainer>
      </form>
    </Modal>
  );
};

interface MyPageSupportHistoryTab2Props {
  params: URLSearchParams;
}

const MyPageSupportHistoryTab2: FC<MyPageSupportHistoryTab2Props> = ({
  params,
}) => {
  const popupStore = usePopupStore();
  const [items, setItems] = useState<myMissHistoryVo[]>([]);
  const [payingItem, setPayingItem] = useState<myMissHistoryVo>();
  const inicisPcRef = useRef<InicisPcOnceRef>(null);
  const inicisMobileRef = useRef<InicisMobileOnceRef>(null);
  const naverRef = useRef<NaverPayRef>(null);
  const mobiliansRef = useRef<MobiliansOnceRef>(null);

  const loadItems = useCallback(async () => {
    const {
      data,
    } = await MySupportHistoryControllerService.getMyMissSupportHistoryUsingGet();

    setItems(data);
  }, []);

  useEffect(() => {
    loadItems();
  }, [loadItems]);

  // 미납후원 완료 팝업
  useEffect(() => {
    if (params.get(`orderNumber`)) {
      popupStore.show(`미납후원이 성공적으로 완료되었습니다.`);
      // search 파라미터 제거
      navigate(`/mypage/support-history?tabIndex=1`);
    }
  }, [params, popupStore]);

  // 납부
  const pay = useCallback(
    async (paymentType: string, item: myMissHistoryVo) => {
      try {
        const {
          data,
          resultCode,
        } = await PaymentControllerService.paymentInfoSetGiftIdUsingPost({
          deviceType: isMobileDevice() ? `MOBILE` : `PC`,
          giftId: item.giftId,
          paymentTypeCode: paymentType,
          giftAidDate: item.giftAidDate2,
          successUrl: `${process.env.SELF_URL}/mypage/support-history?tabIndex=1`,
        });

        // paymentInfo의 resultCode까지 success여야함
        if (
          resultCode === `success` &&
          data.paymentInfo &&
          data.paymentInfo.resultCode === `success`
        ) {
          const giftInfo = data.giftInfo as PaymentMasterInfoVo;
          const {
            orderNumber,
            mkey,
            signature,
            timestamp,
            orderToken,
          } = data.paymentInfo;

          // 주문토큰 저장
          SessionStorage.setItem(`orderToken`, orderToken);
          // 이니시스
          if ([PAYTYPE_ONCE_CARD, PAYTYPE_ONCE_BANKING].includes(paymentType)) {
            // 모바일이면
            if (isMobileDevice()) {
              if (!orderNumber) {
                console.error(`주요정보가 넘어오지 않았습니다`);
                return;
              }
              inicisMobileRef.current?.init({
                payMethod: INICIS_PAY_METHOD_MOBILE_MAP[paymentType],
                goods: giftInfo.productName,
                uname: giftInfo.memberName,
                amt: giftInfo.totalAmount,
                oid: orderNumber,
              });
            } else {
              if (!orderNumber || !mkey || !signature || !timestamp) {
                console.error(`주요정보가 넘어오지 않았습니다`);
                return;
              }
              inicisPcRef.current?.init({
                payMethod: INICIS_PAY_METHOD_PC_MAP[paymentType],
                goodname: giftInfo.productName,
                buyername: giftInfo.memberName,
                buyeremail: giftInfo.email,
                buyertel: giftInfo.mobilePhoneNumber,
                price: giftInfo.totalAmount,
                mKey: mkey,
                oid: orderNumber,
                signature,
                timestamp,
              });
            }
          }
          // 네이버 결제
          else if (paymentType === PAYTYPE_ONCE_NAVERPAY) {
            if (!orderNumber) {
              console.error(`주요정보가 넘어오지 않았습니다`);
              return;
            }
            naverRef.current?.payNormal({
              productCount: 1,
              productName: giftInfo.productName,
              totalPayAmount: giftInfo.totalAmount,
              taxScopeAmount: 0,
              taxExScopeAmount: giftInfo.totalAmount,
              merchantPayKey: orderNumber,
              merchantUserKey: giftInfo.memberName,
            });
          }
          // 모빌리언스 휴대폰 결제
          else if (paymentType === PAYTYPE_ONCE_PHONE) {
            mobiliansRef.current?.init({
              goodname: giftInfo.productName,
              price: giftInfo.totalAmount,
              oid: orderNumber,
            });
          }
        }
        // 실패면서 오류메세지 있다면
        else if (data.paymentInfo?.resultMessage) {
          // 결제쪽 오류메세지 출력 (이미 모달이어서 window.alert 사용)
          // eslint-disable-next-line no-alert
          alert(data.paymentInfo.resultMessage);
        }
      } catch (e) {
        if (e.response) {
          // (이미 모달이어서 window.alert 사용)
          // eslint-disable-next-line no-alert
          alert(e.response.data.resultMessage);
        }
      }
    },
    [],
  );

  return (
    <Container>
      {items.length ? (
        items.map((item) => (
          <HistoryItem key={item.giftId + item.paymonth}>
            <Cell type="date">
              <Month>{item.paymonth}</Month>
            </Cell>
            <Cell>
              <Category>
                {item.recordType === `P` ? `정기후원` : `일시후원`}
                {` `}
                <strong>
                  {item.description}
                  {item.cdDescriptionDetail && ` [${item.cdDescriptionDetail}]`}
                </strong>
              </Category>
              <PriceDetail>
                <span>{Number(item.estimatedAmount).toLocaleString()}원</span>
                <span>{PAY_KIND_MAP[item.kind]}</span>
                <span className="is-failed">{item.statDesc}</span>
              </PriceDetail>
            </Cell>
            <Cell type="status">
              <Button size="sm" onClick={() => setPayingItem(item)}>
                납부하기
              </Button>
            </Cell>
          </HistoryItem>
        ))
      ) : (
        <NoData>
          <H3>후원내역이 없습니다.</H3>
        </NoData>
      )}
      <NotiBox
        css={`
          margin-top: 24px;
          margin-bottom: 0;
        `}
      >
        <NotiList>
          <NotiItem>당해 연도의 후원금만 추가 납부 가능합니다.</NotiItem>
          <NotiItem>
            자동이체는 선택하신 날짜에 후원금이 전달되지 않은 경우 1회에 한해
            다음 결제일 (5일 {`->`} 15일, 15일 {`->`} 25일, 25일 {`->`} 말일,
            말일{` `}
            {`->`} 5일)에 재출금이 진행됩니다.
          </NotiItem>
        </NotiList>
      </NotiBox>
      {payingItem && (
        <PaymentModal
          onRequestPay={(paymentType) => pay(paymentType, payingItem)}
          onRequestClose={() => setPayingItem(undefined)}
        />
      )}
      <InicisPcOnceForm ref={inicisPcRef} />
      <InicisMobileOnceForm ref={inicisMobileRef} />
      <NaverPayForm ref={naverRef} />
      <MobiliansOnceForm ref={mobiliansRef} />
    </Container>
  );
};

export default MyPageSupportHistoryTab2;
