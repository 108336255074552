import InputDatePicker from '../Input/InputDatePicker';
import { myHistoryCodeVo } from '@/__generated__/FrontApi';
import Button from '@/components/Button';
import FormGroup from '@/components/Form/FormGroup';
import Label from '@/components/Form/Label';
import InputRadio, { InputRadioGroup } from '@/components/Input/InputRadio';
import Select from '@/components/Select';
import { breakpoint } from '@/helpers/BreakpointHelper';
import dayjs from 'dayjs';
import React, { FC, useCallback, useState } from 'react';
import styled from 'styled-components';

const SupportSearchBox = styled.div`
  background: #f8f9fa;
  border: 1px solid #e5e6e8;
  padding: 32px 48px;
  position: relative;
  display: flex;
  align-items: flex-end;
  margin-top: 32px;
  ${FormGroup} {
    margin-bottom: 0;
  }
  ${breakpoint(800)} {
    display: block;
  }
  ${breakpoint(640)} {
    padding: 24px;
  }
`;
const RadioLabel = styled.div`
  display: table-cell;
  cursor: pointer;
  .btn {
    border-left: none;
    transition: all 0.25s;
    background: #fff;
    &:hover {
      background: #1cabe2;
      color: #fff;
    }
  }
`;
const RadioFlex = styled(InputRadioGroup)`
  border-left: 1px solid #1cabe2;
  div {
    height: 56px;
    line-height: 54px;
  }
  label {
    vertical-align: top;
  }
`;
const DateFlex = styled.div`
  display: flex;
  .blank {
    display: inline-block;
    vertical-align: middle;
    line-height: 56px;
  }
  ${breakpoint(640)} {
    .input-date {
      width: 45%;
      display: inline-block;
    }
    .blank {
      width: 10%;
      text-align: center;
    }
  }
`;
const ButtonContainer = styled.div``;
const SelectCategory = styled(FormGroup)`
  width: 352px;
  ${FormGroup} + ${FormGroup} {
    margin-top: 24px;
  }
  ${breakpoint(1024)} {
    width: 50%;
  }
  ${breakpoint(800)} {
    width: 100%;
  }
`;
const SelectDate = styled(FormGroup)`
  display: block;
  ${RadioFlex} {
    width: 352px;
    display: inline-block;
  }
  ${DateFlex} {
    width: 552px;
    margin-left: 64px;
    display: inline-flex;
    ${breakpoint(1024)} {
      width: 100%;
      margin-left: 16px;
    }
    ${breakpoint(800)} {
      margin-left: 0;
      margin-top: 16px;
    }
    ${breakpoint(640)} {
      display: block;
    }
  }
  ${Button} {
    width: 160px;
    margin-left: 32px;
    background: #fff;
    ${breakpoint(1024)} {
      margin-left: 16px;
    }
    ${breakpoint(640)} {
      width: 100%;
      margin: 40px 0 0 0;
    }
  }
  .blank {
    padding: 0 16px;
    ${breakpoint(640)} {
      padding: 0;
    }
  }
`;

interface SupportSearchProps {
  codes: myHistoryCodeVo[];
  onCodeChange: (code: string) => void;
  onPeriodChange: (start?: dayjs.Dayjs, end?: dayjs.Dayjs) => void;
}

const SupportSearch: FC<SupportSearchProps> = ({
  onPeriodChange,
  codes,
  onCodeChange,
}) => {
  const [start, setStart] = useState<dayjs.Dayjs>();
  const [end, setEnd] = useState<dayjs.Dayjs>();

  const onSearch = useCallback(() => {
    onPeriodChange(start, end);
  }, [end, onPeriodChange, start]);

  return (
    <SupportSearchBox>
      <SelectCategory>
        <FormGroup>
          <Label>구분</Label>
          <Select name="glCode" onChange={(e) => onCodeChange(e.target.value)}>
            <option value="">전체</option>
            {codes.map((code) => (
              <option value={code.glCode} key={code.glCode}>
                {code.description}
              </option>
            ))}
          </Select>
        </FormGroup>
        <FormGroup>
          <Label>기간</Label>
          <RadioFlex>
            <RadioLabel>
              <InputRadio
                tab
                label="전체"
                value="전체"
                name="periodRadio"
                defaultChecked
                onChange={(e) => {
                  if (e.target.checked) {
                    setStart(undefined);
                    setEnd(undefined);
                    onPeriodChange(undefined, undefined);
                  }
                }}
              />
            </RadioLabel>
            <RadioLabel>
              <InputRadio
                tab
                label="1개월"
                value="1개월"
                name="periodRadio"
                onChange={(e) => {
                  if (e.target.checked) {
                    const newStart = dayjs().add(-1, `month`);
                    const newEnd = dayjs();
                    setStart(newStart);
                    setEnd(newEnd);
                    onPeriodChange(newStart, newEnd);
                  }
                }}
              />
            </RadioLabel>
            <RadioLabel>
              <InputRadio
                tab
                label="3개월"
                value="3개월"
                name="periodRadio"
                onChange={(e) => {
                  if (e.target.checked) {
                    const newStart = dayjs().add(-3, `month`);
                    const newEnd = dayjs();
                    setStart(newStart);
                    setEnd(newEnd);
                    onPeriodChange(newStart, newEnd);
                  }
                }}
              />
            </RadioLabel>
            <RadioLabel>
              <InputRadio
                tab
                label="6개월"
                value="6개월"
                name="periodRadio"
                onChange={(e) => {
                  if (e.target.checked) {
                    const newStart = dayjs().add(-6, `month`);
                    const newEnd = dayjs();
                    setStart(newStart);
                    setEnd(newEnd);
                    onPeriodChange(newStart, newEnd);
                  }
                }}
              />
            </RadioLabel>
          </RadioFlex>
        </FormGroup>
      </SelectCategory>
      <SelectDate>
        <DateFlex>
          <InputDatePicker
            className="input-date"
            onChange={(value) =>
              value && !Array.isArray(value) && setStart(dayjs(value))
            }
            selected={start?.toDate()}
          />
          <span className="blank">~</span>
          <InputDatePicker
            className="input-date"
            onChange={(value) =>
              value && !Array.isArray(value) && setEnd(dayjs(value))
            }
            selected={end?.toDate()}
          />
          <ButtonContainer>
            <Button outline onClick={onSearch}>
              검색
            </Button>
          </ButtonContainer>
        </DateFlex>
      </SelectDate>
    </SupportSearchBox>
  );
};

export default SupportSearch;
