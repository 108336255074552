import { AccountCheckControllerService } from '@/__generated__/CommonApi';
import { MyPersonalInfoControllerService } from '@/__generated__/FrontApi';
import ImgBannerMyinfo from '@/assets/img/banner/banner_myinfo@2x.png';
import ImgBannerMyinfoMobile from '@/assets/img/banner/banner_myinfo_mobile@2x.png';
import iconFacebook from '@/assets/img/join/ico_facebook@2x.png';
import iconGoogle from '@/assets/img/join/ico_google@2x.png';
import iconNaver from '@/assets/img/join/ico_naver@2x.png';
import AnchorText from '@/components/AnchorText';
import Button from '@/components/Button';
import Container from '@/components/Container';
import ErrorMessage from '@/components/Form/ErrorMessage';
import FormGroup, { FormItem } from '@/components/Form/FormGroup';
import Label from '@/components/Form/Label';
import Col from '@/components/Grid/Col';
import Row from '@/components/Grid/Row';
import AddressButton from '@/components/Input/AddressButton';
import InputCheckbox from '@/components/Input/InputCheckbox';
import InputNumber from '@/components/Input/InputNumber';
import InputPassword from '@/components/Input/InputPassword';
import InputRadio from '@/components/Input/InputRadio';
import InputText from '@/components/Input/InputText';
import UserIdentification from '@/components/Mypage/UserIdentification';
import PostTooltip from '@/components/PostTooltip';
import { NotiBox, NotiList, NotiItem } from '@/components/Text/TextNoti';
import { H3_2 } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { emailRegex, mobileRegex } from '@/helpers/ValidationHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import {
  FormButton,
  FormContainer,
  RightCol,
} from '@/page-blocks/auth/AuthCommon';
import { ContainerGroup, SectionTop } from '@/page-blocks/mypage/MypageCommon';
import MypageRoutes from '@/page-blocks/mypage/MypageRoutes';
import { usePopupStore } from '@/stores/PopupStore';
import { useUserStore } from '@/stores/UserStore';
import { RouteComponentProps, WindowLocation } from '@reach/router';
import { navigate } from 'gatsby';
import { observer } from 'mobx-react-lite';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';
import SessionStorage, {
  SESSION_STORAGE_KEY__MYPAGE_AUTH,
} from '@/services/SessionStorage';

const SnsLoginText = styled.div`
  margin-bottom: 72px;
  position: relative;
  img {
    width: 48px;
    height: 48px;
    position: absolute;
    left: 0;
    top: -8px;
    ${breakpoint(`mobile`)} {
      width: 40px;
      height: 40px;
      top: -6px;
    }
  }
  span {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: -1.2px;
    padding-left: 56px;
    display: block;
    ${breakpoint(`mobile`)} {
      font-size: 16px;
      padding-left: 52px;
    }
  }
`;
const AgreeDl = styled.dl`
  margin-top: 16px;
  dt {
    margin-bottom: 8px;
  }
  dd {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  label {
    width: 100%;
    &:first-child {
      margin-left: 0;
    }
  }
`;
const BannerSizeMid = styled.div`
  width: 736px;
  height: 220px;
  margin: 96px auto 0;
  background-image: url(${ImgBannerMyinfo});
  background-size: auto 100%;
  background-position: 194px bottom;
  background-repeat: no-repeat;
  background-color: #3162af;
  color: #fff;
  p {
    color: #fff !important;
  }
  ${breakpoint(768)} {
    width: 100%;
    // height: 91vw;
  }
  ${breakpoint(640)} {
    background-image: url(${ImgBannerMyinfoMobile});
    height: 91vw;
    background-size: 100% auto;
    background-position: center bottom;
    font-size: 16px;
    p {
      font-size: 14px;
    }
  }
`;
const BannerFlex = styled.div`
  display: flex;
  height: 100%;
  padding: 0 32px 0 48px;
  align-items: center;
  justify-content: space-between;
  ${H3_2} {
    margin-bottom: 16px;
  }
  ${Button} {
    width: 256px;
    ${breakpoint(768)} {
      width: 100%;
      margin-top: 0;
    }
  }
  ${breakpoint(640)} {
    display: block;
    padding: 32px;
    position: relative;
  }
`;
const FlexCell = styled.div`
  ${breakpoint(640)} {
    &.type-button {
      width: calc(100% - 64px);
      position: absolute;
      bottom: 32px;
    }
  }
`;

interface MyInfoFormData {
  userId: string;
  name: string;
  mobilePhoneNumber: string;
  email: string;
  smsAgree: boolean;
  emailAgree: boolean;
  zip: string;
  address1: string;
  address2: string;
  privacyAgree: string;
  postAgree: boolean;
  mobileAgree: boolean;
  password: string;
  passwordNew: string;
  passwordNewConfirm: string;
}

const MyInfo: FC<
  RouteComponentProps<{
    location: WindowLocation<{
      skipAuth?: boolean;
    }>;
  }>
> = observer(({ location }) => {
  const userStore = useUserStore();
  const popupStore = usePopupStore();
  const [authed, setAuthed] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showPasswordInput, setShowPasswordInput] = useState(false);

  // 사용자 정보 조회
  const getUserInfoRefresh = useCallback(async () => {
    try {
      if (!SessionStorage.getItem(SESSION_STORAGE_KEY__MYPAGE_AUTH)) {
        return;
      }
      const res = await userStore.loadUserInfo();
      if (res) {
        setAuthed(true);
      } else {
        SessionStorage.removeItem(SESSION_STORAGE_KEY__MYPAGE_AUTH);
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  // 비밀번호 인증
  const getPasswordCheck = useCallback(async () => {
    try {
      const {
        data,
        resultCode,
      } = await AccountCheckControllerService.getPasswordCheckUsingGet({
        headers: {
          'X-AUTH-MY': SessionStorage.getItem(SESSION_STORAGE_KEY__MYPAGE_AUTH),
        },
      });
      if (
        resultCode === 'success' &&
        SessionStorage.getItem(SESSION_STORAGE_KEY__MYPAGE_AUTH)
      ) {
        // 인증여부 변조 확인
        getUserInfoRefresh();
      } else {
        SessionStorage.removeItem(SESSION_STORAGE_KEY__MYPAGE_AUTH);
      }
    } catch (e) {
      console.error(e);
    }
  }, []);
  useEffect(() => {
    getPasswordCheck();
  }, [getPasswordCheck]);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    getValues,
    control,
  } = useForm<MyInfoFormData>({
    defaultValues: {},
  });

  // 사용자정보 세팅
  useEffect(() => {
    if (userStore.user) {
      reset({
        ...userStore.user,
        mobilePhoneNumber:
          userStore.user?.mobilePhoneNumber &&
          `${userStore.user.mobilePhoneNumber}`.replace(/-/g, ``),
        emailAgree: userStore.isEmailAgree(),
        smsAgree: userStore.isSmsAgree(),
        postAgree: userStore.isPostAgree(),
      });
    }
  }, [reset, userStore, userStore.user]);

  const onSubmit: SubmitHandler<MyInfoFormData> = useCallback(
    async (formData) => {
      if (!userStore.user) {
        console.error(`로그인되지 않았습니다`);
        return;
      }

      try {
        const {
          resultCode,
        } = await MyPersonalInfoControllerService.setModifyMyPersonalInfoByUserIdUsingPost(
          {
            // 가변값
            name: formData.name,
            email: formData.email,
            mobilePhoneNumber: formData.mobilePhoneNumber,
            mailEdm: formData.emailAgree ? `Y` : `N`,
            mailSms: formData.smsAgree ? `Y` : `N`,
            mailDm: formData.postAgree ? `Y` : `N`,
            privacyAgree: formData.privacyAgree,
            zipCode: formData.zip,
            address: formData.address1,
            addressDetail: formData.address2,
            password: formData.password,
            passwordNew: formData.passwordNew,
            // 고정값
            donorId: userStore.user.donorId,
          },
          {
            headers: {
              'X-AUTH-MY': SessionStorage.getItem(
                SESSION_STORAGE_KEY__MYPAGE_AUTH,
              ),
            },
          },
        );
        if (resultCode === `success`) {
          // 완료메세지 띄우기
          popupStore.show(`저장되었습니다`);
          setShowPasswordInput(false);
        }
      } catch (e) {
        if (e.response) {
          popupStore.show(e.response.data.data.returnMessage);
        }
      }
    },
    [popupStore, userStore],
  );

  return (
    <LayoutWithTitle
      location={location}
      title="마이페이지"
      description="for every child, UNICEF"
    >
      <MypageRoutes currentKey={2} />
      {!authed && (
        <SectionTop className="by-sub-main-layout">
          <UserIdentification
            onAuthSuccess={() => {
              // 새로고침시에도 인증정보 유지
              getUserInfoRefresh();
            }}
          />
        </SectionTop>
      )}
      {authed && SessionStorage.getItem(SESSION_STORAGE_KEY__MYPAGE_AUTH) && (
        <SectionTop>
          <ContainerGroup>
            <Container>
              <NotiBox
                css={`
                  border: 1px solid #e5e6e8;
                  margin-bottom: 96px;
                `}
              >
                <NotiList>
                  <NotiItem>
                    회원님의 개인정보는 보안 인증을 통해 외부로 유출되지
                    않습니다.
                  </NotiItem>
                  <NotiItem>
                    주민등록번호와 주소는 기부금 영수증의 발급을 위해 후원자님의
                    동의 하에 수집됩니다.
                  </NotiItem>
                  <NotiItem>
                    변경할 내용을 입력하신 후 하단의 [수정하기] 버튼을 누르시면
                    정보가 수정됩니다.
                  </NotiItem>
                </NotiList>
              </NotiBox>
            </Container>
          </ContainerGroup>
          <FormContainer>
            <form onSubmit={handleSubmit(onSubmit)}>
              {userStore.user?.socialType === `N` && (
                <SnsLoginText>
                  <img src={iconNaver} alt="네이버" />
                  <span>
                    네이버 아이디 {userStore.user.email} 로 로그인하셨습니다
                  </span>
                </SnsLoginText>
              )}
              {userStore.user?.socialType === `F` && (
                <SnsLoginText>
                  <img src={iconFacebook} alt="페이스북" />
                  <span>
                    페이스북 아이디 {userStore.user.email} 로 로그인하셨습니다
                  </span>
                </SnsLoginText>
              )}
              {userStore.user?.socialType === `G` && (
                <SnsLoginText>
                  <img src={iconGoogle} alt="구글" />
                  <span>
                    구글 아이디 {userStore.user.email} 로 로그인하셨습니다
                  </span>
                </SnsLoginText>
              )}
              {!userStore.isSocial() && (
                <>
                  <FormGroup>
                    <Label required>아이디</Label>
                    <InputText ref={register} name="userId" readOnly />
                  </FormGroup>
                  <FormGroup>
                    <Label>비밀번호</Label>
                    <div>
                      {showPasswordInput ? (
                        <Button
                          size="sm"
                          color="grey"
                          onClick={() => setShowPasswordInput(false)}
                        >
                          비밀번호 변경 취소
                        </Button>
                      ) : (
                        <Button
                          size="sm"
                          color="grey"
                          onClick={() => setShowPasswordInput(true)}
                        >
                          비밀번호 변경
                        </Button>
                      )}
                    </div>
                  </FormGroup>
                  {showPasswordInput && (
                    <>
                      <FormGroup>
                        <Label required>기존 비밀번호</Label>
                        <InputPassword
                          name="password"
                          ref={register({
                            required: {
                              value: true,
                              message: `필수 입력입니다`,
                            },
                          })}
                          placeholder="기존 비밀번호 입력"
                        />
                        {errors.password && (
                          <ErrorMessage>{errors.password.message}</ErrorMessage>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label required>새 비밀번호</Label>
                        <InputPassword
                          name="passwordNew"
                          ref={register({
                            minLength: {
                              value: 8,
                              message: `8자 이상이어야 합니다`,
                            },
                            maxLength: {
                              value: 16,
                              message: `16자 이하여야 합니다`,
                            },
                            pattern: {
                              value: /(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%*?&^()+=-])/,
                              message: `영문 및 숫자,특수문자를 모두 포함해야 합니다`,
                            },
                          })}
                          placeholder="8-16자의 영문 및 숫자,특수문자를 모두 포함"
                        />
                        {errors.passwordNew && (
                          <ErrorMessage>
                            {errors.passwordNew.message}
                          </ErrorMessage>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label required>새 비밀번호 확인</Label>
                        <InputPassword
                          name="passwordNewConfirm"
                          ref={register({
                            validate: (value) =>
                              value === getValues(`passwordNew`) ||
                              `비밀번호가 일치하지 않습니다`,
                          })}
                          placeholder="8-16자의 영문 및 숫자,특수문자를 모두 포함"
                        />
                        {errors.passwordNewConfirm && (
                          <ErrorMessage>
                            {errors.passwordNewConfirm.message}
                          </ErrorMessage>
                        )}
                      </FormGroup>
                    </>
                  )}
                </>
              )}
              <FormGroup>
                <Label required>성명·단체명</Label>
                <InputText
                  ref={register}
                  name="name"
                  disabled={userStore.isDonor()}
                />
              </FormGroup>
              {userStore.isDonor() && (
                <>
                  <FormGroup>
                    <Label required>회원번호</Label>
                    <InputText ref={register} name="donorId" disabled />
                  </FormGroup>
                  {userStore.isIndividualUser() && (
                    <FormGroup>
                      <Label required>주민등록번호 </Label>
                      <InputText ref={register} name="juminno" disabled />
                      {!userStore.isValidJuminno() && (
                        <ErrorMessage>
                          * 주민등록번호가 정상 입력되지 않았습니다.
                          기부금영수증 발급을 원하시면 후원본부(02-737-1004)로
                          연락주세요.
                        </ErrorMessage>
                      )}
                    </FormGroup>
                  )}
                  {userStore.isBusinessUser() && (
                    <FormGroup>
                      <Label required>사업자번호</Label>
                      <InputText ref={register} name="juminno" disabled />
                      {!userStore.isValidJuminno() && (
                        <ErrorMessage>
                          * 사업자번호가 정상 입력되지 않았습니다. 기부금영수증
                          발급을 원하시면 후원본부(02-737-1004)로 연락주세요.
                        </ErrorMessage>
                      )}
                    </FormGroup>
                  )}
                </>
              )}
              <FormGroup>
                <Label required>휴대폰번호</Label>
                <Controller
                  control={control}
                  name="mobilePhoneNumber"
                  rules={{
                    required: {
                      value: true,
                      message: `필수 입력입니다`,
                    },
                    pattern: {
                      value: mobileRegex,
                      message: `올바른 휴대폰번호가 아닙니다`,
                    },
                    validate: {
                      isValidRange: (value) =>
                        (value.substr(0, 3) === `010` && value.length === 11) ||
                        (value.substr(0, 3) !== `010` && value.length === 10) ||
                        `휴대폰번호 자릿수를 확인해주세요`,
                    },
                  }}
                  as={
                    <InputNumber
                      format="###########"
                      placeholder="휴대폰번호 입력 ( &lsquo;-&rsquo; 제외 )"
                    />
                  }
                />
                {errors.mobilePhoneNumber && (
                  <ErrorMessage>
                    {errors.mobilePhoneNumber.message}
                  </ErrorMessage>
                )}
              </FormGroup>
              <FormGroup>
                <Label required>이메일</Label>
                <InputText
                  name="email"
                  ref={register({
                    required: {
                      value: true,
                      message: `필수 입력입니다`,
                    },
                    pattern: {
                      value: emailRegex,
                      message: `올바르지 않은 이메일주소 입니다`,
                    },
                  })}
                  placeholder="이메일주소 입력"
                  disabled={userStore.isSocial()}
                />
                {errors.email && (
                  <ErrorMessage>{errors.email.message}</ErrorMessage>
                )}
              </FormGroup>
              {userStore.isDonor() && (
                <FormGroup className="FormGroup">
                  <Label required>주소</Label>
                  <Row>
                    <Col desktop="auto">
                      <InputText ref={register} name="zip" readOnly />
                    </Col>
                    <RightCol desktop="none">
                      <AddressButton
                        onSelectAddress={(address) => {
                          setValue(`zip`, address.zonecode);
                          setValue(`address1`, address.address);
                        }}
                        renderButton={(buttonProps) => (
                          <FormButton
                            {...buttonProps}
                            outline
                            css={`
                              ${breakpoint(768)} {
                                margin-top: 0 !important;
                              }
                            `}
                          >
                            주소검색
                          </FormButton>
                        )}
                      />
                    </RightCol>
                  </Row>
                  <Row>
                    <InputText ref={register} name="address1" readOnly />
                  </Row>
                  <Row>
                    <InputText
                      ref={register}
                      name="address2"
                      placeholder="상세주소 입력"
                    />
                  </Row>
                </FormGroup>
              )}
              <FormGroup
                css={`
                  margin-bottom: 94px;
                  ${breakpoint(768)} {
                    margin-bottom: 0;
                  }
                `}
              >
                <Label>
                  전 세계 어린이를 위한 소식·후원·캠페인 안내 수신동의(선택)
                </Label>
                <Row
                  css={`
                    margin-top: 4px;
                    margin-bottom: 12px;
                  `}
                >
                  <Col>
                    <InputCheckbox
                      ref={register}
                      label="모바일"
                      name="smsAgree"
                    />
                  </Col>
                  <Col>
                    <InputCheckbox
                      ref={register}
                      label="이메일"
                      name="emailAgree"
                    />
                  </Col>
                  {userStore.isDonor() && (
                    <Col>
                      <div
                        css={`
                          position: relative;
                          display: inline-block;
                        `}
                      >
                        <InputCheckbox
                          ref={register}
                          label="우편"
                          name="postAgree"
                          onClick={(e) =>
                            setShowTooltip(e.currentTarget.checked)
                          }
                          css={`
                            z-index: 2;
                          `}
                        />
                        {showTooltip && (
                          <PostTooltip
                            onRequestClose={(isDigitalSelected) => {
                              if (isDigitalSelected) {
                                setValue(`postAgree`, false);
                                setValue(`mobileAgree`, true);
                                setValue(`emailAgree`, true);
                              }
                              setShowTooltip(false);
                            }}
                          />
                        )}
                      </div>
                    </Col>
                  )}
                </Row>
                <FormItem>
                  <NotiBox
                    css={`
                      margin-bottom: 0;
                    `}
                  >
                    <NotiList>
                      <NotiItem>
                        <strong>
                          공지사항, 긴급구호 및 회원관리 안내는 수신 여부와
                          관계없이 발송됩니다.
                        </strong>
                      </NotiItem>
                      <NotiItem>
                        동의 내용은 언제든지 웹사이트 또는 전화상담을 통해
                        변경이 가능합니다.
                      </NotiItem>
                    </NotiList>
                  </NotiBox>
                </FormItem>
                {userStore.isDonor() && (
                  <FormItem>
                    <AgreeDl>
                      <dt>개인정보 보유기간 동의(선택)</dt>
                    </AgreeDl>
                    <AgreeDl>
                      <dt>
                        후원 정보ㆍ내역을 처리 정지 요청 시까지 보관하는 데에
                        동의하십니까?
                      </dt>
                      <dd>
                        <InputRadio
                          ref={register}
                          label="네"
                          name="privacyAgree"
                          value="Y"
                        />
                        <InputRadio
                          ref={register}
                          label="아니오"
                          name="privacyAgree"
                          value="N"
                        />
                      </dd>
                    </AgreeDl>
                  </FormItem>
                )}
                <div
                  css={`
                    text-align: right;
                    margin-top: 16px;
                  `}
                >
                  <AnchorText
                    color={false}
                    onClick={() => navigate(`/mypage/drop`)}
                    css={`
                      ${breakpoint(768)} {
                        margin-top: 0 !important;
                        margin-bottom: 70px;
                      }
                    `}
                  >
                    웹사이트 회원탈퇴
                  </AnchorText>
                </div>
              </FormGroup>
              <Button type="submit" full>
                수정하기
              </Button>
            </form>
          </FormContainer>
          {!userStore.isDonor() && (
            <BannerSizeMid>
              <BannerFlex>
                <FlexCell>
                  <H3_2>유니세프에 후원 중이신가요?</H3_2>
                  <p>
                    회원정보와 후원내역을 연결하세요. <br />
                    후원정보를 쉽게 확인할 수 있습니다.
                  </p>
                </FlexCell>
                <FlexCell className="type-button">
                  <Button
                    onClick={() => navigate(`/mypage/support-history/connect`)}
                  >
                    후원내역 연결하기
                  </Button>
                </FlexCell>
              </BannerFlex>
            </BannerSizeMid>
          )}
        </SectionTop>
      )}
    </LayoutWithTitle>
  );
});

export default MyInfo;
