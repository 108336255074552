import Arrow from '@/assets/img/icons/icon_moreview_link@2x.png';
import { breakpoint } from '@/helpers/BreakpointHelper';
import styled from 'styled-components';

const SwiperOverflowHidden = styled.div`
  width: 100%;
  padding: 0 20px 0 20px;

  .swiper-container {
    overflow: hidden;
    padding-bottom: 112px;
  }
  .swiper-container {
    ${breakpoint(1024)} {
      padding-bottom: 110px;
    }
    ${breakpoint(`tablet`)} {
      padding-bottom: 40px !important;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 56px;
    height: 56px;
    box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 100%;
    background: #fff;
    position: absolute;
    top: inherit;
    bottom: 10px;
    cursor: pointer;
    &::after {
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      background: url(${Arrow}) center no-repeat;
      background-size: cover;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .swiper-button-prev {
    left: inherit;
    right: 50%;
    margin-right: -445px;

    &::after {
      transform: translate(-50%, -50%) rotate(180deg);
    }

    ${breakpoint(1160)} {
      & {
        margin: 0;
        right: 100px;
      }
    }
  }
  .swiper-button-next {
    right: 50%;
    margin-right: -525px;

    ${breakpoint(1160)} {
      & {
        margin: 0;
        right: 20px;
      }
    }
  }

  ${breakpoint(`tablet`)} {
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }

  .swiper-scrollbar {
    position: absolute;
    left: 50%;
    margin-left: -97px;
    height: 4px;
    bottom: 40px;
    width: 100%;
    max-width: 928px;
    transform: translate(-50%, 0);
    height: 4px;
    bottom: 34px;
    // 화면이 1920 이상으로 커졌을때 display: none 되는것을 방지
    // display: block !important;
    &::before {
      content: '';
      background: #e2e3e3;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      opacity: 0.4;
    }

    ${breakpoint(1160)} {
      left: 0;
      transform: translate(0, 0);
      margin-left: 0;
      width: calc(100vw - 232px);
      max-width: 100%;
    }

    ${breakpoint(`tablet`)} {
      width: auto;
      left: 20px;
      right: 20px;
      bottom: 0 !important;
    }
  }

  .swiper-scrollbar-drag {
    height: 4px;
    background: #1cabe2;
  }

  .scroll-only {
    .swiper-scrollbar {
      width: 100%;
      max-width: 100%;
      left: 0;
      margin: 0;
      transform: translate(0, 0);
    }
  }
`;

export default SwiperOverflowHidden;
