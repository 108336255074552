import { isBrowser } from '@/helpers/BrowserHelper';
import { useUserStore } from '@/stores/UserStore';
import { RouteComponentProps } from '@reach/router';
import { navigate } from 'gatsby';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';

interface PrivateRouteProps extends RouteComponentProps {
  component: FC<RouteComponentProps>;
}

const PrivateRoute: FC<PrivateRouteProps> = observer(
  ({ component: Component, location, ...props }) => {
    const userStore = useUserStore();
    const hashStr = location?.hash === null ? `` : location?.hash;
    // 로딩되지 않았으면 잠시 대기
    if (!userStore.loaded) {
      return null;
    }
    // 로그인되지 않았고, 로그인페이지가 아니면
    if (!userStore.isLoggedIn() && location?.pathname !== `/auth/login`) {
      // 로그인페이지로 이동
      if (isBrowser)
        navigate(
          `/auth/login${
            location?.pathname
              ? `?redirectTo=${location?.pathname + hashStr}`
              : ``
          }`,
        );
      return null;
    }
    return <Component location={location} {...props} />;
  },
);

export default PrivateRoute;
