import Button from '@/components/Button';
import Container from '@/components/Container';
import { H3, H3_2 } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import {
  ContainerGroup,
  SectionTop,
  TitleArea,
} from '@/page-blocks/mypage/MypageCommon';
import MypageRoutes from '@/page-blocks/mypage/MypageRoutes';
import MyPageSupportHistoryTab1 from '@/page-blocks/mypage/support-history/MyPageSupportHistoryTab1';
import MyPageSupportHistoryTab2 from '@/page-blocks/mypage/support-history/MyPageSupportHistoryTab2';
import { useUserStore } from '@/stores/UserStore';
import { navigate } from 'gatsby';
import { observer } from 'mobx-react-lite';
import React, { FC, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

const UserName = styled.span`
  font-size: 32px;
  font-weight: bold;
  color: #1cabe2;
  ${breakpoint(640)} {
    font-size: 20px;
  }
`;
const ColorSky = styled.span`
  color: #1cabe2;
`;

const PanelContainer = styled.div`
  height: 80px;
  border-bottom: 3px solid #e5e6e8;
  ${breakpoint(640)} {
    height: 56px;
  }
  .UiTabPanel {
    margin: 0;
    & > li {
      width: 50%;
      text-align: center;
    }
  }
`;
const TabButton = styled.li<{ active: boolean }>`
  cursor: pointer;
  width: 50%;
  text-align: center;
  display: block;
  font-weight: 700;
  line-height: 80px;
  font-size: 20px;
  height: 80px;
  border-bottom: 3px solid #e5e6e8;
  border-color: ${(props) => (props.active ? `#1cabe2` : `#e5e6e8`)};
  color: ${(props) => (props.active ? `#1cabe2` : `#e5e6e8`)};
  transition: border 0.25s, color 0.25s;
  ${breakpoint(640)} {
    height: 56px;
    line-height: 56px;
    font-size: 16px;
  }
  &:hover {
    border-color: #1cabe2;
    color: #1cabe2;
  }
`;
const ButtonContainer = styled.div`
  width: 352px;
  display: flex;
  justify-content: space-between;
  ${breakpoint(1024)} {
    position: relative;
  }
  ${breakpoint(640)} {
    width: 100%;
    ${Button} {
      position: static;
      width: 49%;
    }
  }
`;
const NoData = styled.div`
  text-align: center;
  border-top: 1px solid #e5e6e8;
  border-bottom: 1px solid #e5e6e8;
  padding: 96px 0;
  ${ButtonContainer} {
    width: 256px;
    margin: 16px auto 0;
  }
  ${H3} {
    line-height: 1.7;
  }
  ${breakpoint(640)} {
    ${H3} {
      font-size: 14px;
      line-height: 28px;
    }
    ${ButtonContainer} {
      ${Button} {
        width: 100%;
      }
    }
  }
`;

const MissSupportHistory: FC = observer(({ location }) => {
  const params = useMemo(() => new URLSearchParams(location.search), [
    location.search,
  ]);
  const userStore = useUserStore();
  const [activeTab, setActiveTab] = useState(
    params.get(`tabIndex`) ? Number(params.get(`tabIndex`)) : 1,
  );

  const handleTabMenu = (id: number, link: string) => {
    navigate(link);
    setActiveTab(id);
  };

  const path = useMemo(() => location.pathname, [location]);
  const targetRef = useRef<HTMLLIElement | null>(null);
  const tab = [
    {
      idx: 0,
      title: `완료 후원`,
      link: `/mypage/support-history/`,
    },
    {
      idx: 1,
      title: `놓친 후원`,
      link: `/mypage/miss-support-history/`,
    },
  ];
  return (
    <LayoutWithTitle
      location={location}
      title="마이페이지"
      description="for every child, UNICEF"
    >
      <MypageRoutes currentKey={3} />
      <SectionTop>
        <ContainerGroup>
          {userStore.user?.firstDonateDate ? (
            <Container>
              <TitleArea>
                <H3_2>
                  <UserName>{userStore.user.name}</UserName>
                  {` `}후원자님, <br />
                  {userStore.user.firstDonateDate}부터 총{` `}
                  <ColorSky>
                    {Number(userStore.user.accumTotalAmount).toLocaleString()}
                  </ColorSky>
                  원 후원하셨습니다.
                </H3_2>
              </TitleArea>
            </Container>
          ) : null}
          <Container>
            <PanelContainer>
              <ul className="UiTabPanel flex">
                {tab.map(({ title, link, idx }) => (
                  <TabButton
                    ref={(ref) => {
                      if (path === link) {
                        targetRef.current = ref;
                      }
                    }}
                    active={activeTab === idx}
                    key={link}
                    onClick={() => handleTabMenu(idx, link)}
                  >
                    {title}
                  </TabButton>
                ))}
              </ul>
            </PanelContainer>
            {userStore.isDonor() ? (
              <>
                {activeTab === 0 && <MyPageSupportHistoryTab1 />}
                {activeTab === 1 && (
                  <MyPageSupportHistoryTab2 params={params} />
                )}
              </>
            ) : (
              <NoData>
                <H3>
                  유니세프에 후원 중이신가요? <br />
                  신청할 후원내역이 확인되지 않습니다. <br />
                  회원정보와 후원내역을 연결 후 후원 내역을 관리할 수 있습니다.
                </H3>
                <ButtonContainer>
                  <Button
                    full
                    onClick={() => navigate(`/mypage/support-history/connect`)}
                  >
                    후원내역 연결하기
                  </Button>
                </ButtonContainer>
              </NoData>
            )}
          </Container>
        </ContainerGroup>
      </SectionTop>
    </LayoutWithTitle>
  );
});
export default MissSupportHistory;
