import Button from '../Button';
import InputRadio, { InputRadioGroup } from '../Input/InputRadio';
import InputText from '../Input/InputText';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { usePopupStore } from '@/stores/PopupStore';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import React, { FC, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

const Category = styled.div`
  height: 44px;
  line-height: 2;
  color: #828385;
  strong {
    color: #1cabe2;
    display: inline-block;
    margin-left: 40px;
    &.first {
      margin-left: 0;
    }
  }
  ${Button} {
    margin-left: 32px;
  }
`;
const PriceDetail = styled.div`
  margin-top: 18px;
  span {
    position: relative;
    padding-right: 16px;
    &.is-failed {
      color: #e2231a;
    }
    & + span {
      padding-left: 16px;
      &::before {
        content: '';
        display: block;
        width: 1px;
        height: 16px;
        background: #e5e6e8;
        position: absolute;
        left: 0;
        top: 5px;
      }
    }
  }
`;
const RadioLabel = styled.div`
  display: table-cell;
  cursor: pointer;
`;
const RadioFlex = styled(InputRadioGroup)`
  border-left: 1px solid #1cabe2;
  .btn {
    border-left: none;
    transition: all 0.25s;
    background: #fff;
    &:hover {
      background: #1cabe2;
      color: #fff;
    }
  }
`;
const InputTextCustom = styled.div<{ disabled?: boolean }>`
  border: 1px solid #e5e6e8;
  margin-top: -1px;
  ${({ disabled }) => {
    if (!disabled) {
      return `
            span {
              color: #2d2926 !important;
            }
          `;
    }
    return ``;
  }}
  ${breakpoint(800)} {
    height: 48px;
    line-height: 44px;
    margin-bottom: 16px;
    input {
      height: 44px;
    }
  }
`;
const Cell = styled.div<{ type?: string; status?: string; price?: boolean }>`
  display: table-cell;
  ${InputTextCustom} {
    span {
      width: 10%;
      text-align: right;
      color: #b7b8ba;
    }
  }
  ${({ type }) => {
    if (type) {
      return `
            width: 15%;
            text-align: center;
            font-weight: bold;
            font-size: 20px;
            .sky {
              color: #1cabe2
            }
          `;
    }
    return `padding-left: 24px;`;
  }}
  ${breakpoint(800)} {
    ${({ type }) => {
      if (type === `total`) {
        return `
            display: block;
            width: auto; 
            text-align: left;
            float: left;
            line-height: 44px;
            .sky {
              font-size: 14px;
            }
          `;
      }
      if (type === `status`) {
        return `
            text-align: right;
            display: block;
            width: 100%;
          `;
      }
      return `
        display: block; 
        padding-left: 0; 
        width: 100%;
      `;
    }}
  }
  ${breakpoint(640)} {
    ${({ type }) => {
      if (type === `total`) {
        return `
            position: absolute;
            right: 0;
            top: 12px;
            line-height: 44px;
          `;
      }
      if (type === `status`) {
        return `
          margin-top: 16px;
            ${Button} {
              width: 100%;
            }
          `;
      }
      return `
      `;
    }}
  }
  ${({ price }) => {
    if (price) {
      return `
            width: 40%;
            padding-right: 16px;
            ${InputText} {
              border: none;
              width: 90%;
              display: inline-block;
            }
          `;
    }
    return ``;
  }}
  ${breakpoint(800)} {
    ${({ price }) => {
      if (price) {
        return `
            padding-right: 0;
          `;
      }
      return ``;
    }}
  }
  ${({ status }) => {
    if (status === `dark`) {
      return `
            color: #2d2926
          `;
    }
    if (status === `light`) {
      return `
            color: #828385;
          `;
    }
    return ``;
  }}
`;
interface ChangePropsData {
  supportItem?: string;
  firstSupportDate?: string;
  supportTerm?: string;
  supportPay?: string;
  price?: string;
  onPriceChange?: (price: number) => void;
}
const CellChange = styled(Cell)<{ active?: boolean }>``;
// 선택 가능한 금액 옵션
const prices = [30000, 50000, 100000];
const MyRegularSupportPriceChange: FC<ChangePropsData> = observer(
  ({ onPriceChange, price, ...props }) => {
    const popupStore = usePopupStore();
    const [disablePriceInput, setDisablePriceInput] = useState(
      prices.includes(Number(price)),
    );
    const [isEditMode, setIsEditMode] = useState(false);
    const [currentPrice, setCurrentPrice] = useState<number>(
      Number(price || 0),
    );
    const [currentPriceInput, setCurrentPriceInput] = useState<string>(
      disablePriceInput ? `` : price,
    );
    const finalPrice = useMemo(
      () => (disablePriceInput ? currentPrice : Number(currentPriceInput)),
      [currentPrice, currentPriceInput, disablePriceInput],
    );

    const onSubmit = useCallback(() => {
      const originalPrice = Number(price);
      // 금액이 동일하면 무시하고 닫기
      if (originalPrice === finalPrice) {
        setIsEditMode(false);
        return;
      }
      // 금액 체크
      if (finalPrice < 10000) {
        popupStore.show(`1만 원 이상부터 정기후원이 가능합니다`);
        return;
      }
      if (finalPrice >= 10000000) {
        popupStore.show(
          `고액 정기후원은 후원본부(02-737-1004)로 연락주시기 바랍니다`,
        );
        return;
      }

      if (onPriceChange) {
        onPriceChange(finalPrice);
      }
      setIsEditMode(false);
    }, [finalPrice, onPriceChange, popupStore]);

    return (
      <>
        <Cell>
          <Category>
            <strong className="first">{props.supportItem}</strong>
          </Category>
          {!isEditMode && (
            <PriceDetail>
              {props.firstSupportDate &&
                props.firstSupportDate > dayjs().format(`YYYY.MM.DD`) && (
                  <span>첫 후원 예정일 : {props.firstSupportDate}</span>
                )}
              <span>{props.supportTerm}</span>
              <span>{props.supportPay}</span>
            </PriceDetail>
          )}
        </Cell>
        {!isEditMode && (
          <CellChange type="total">
            <span className="sky">{Number(price).toLocaleString()}원</span>
          </CellChange>
        )}
        {isEditMode ? (
          <>
            <CellChange price>
              <RadioFlex>
                <RadioLabel>
                  <InputRadio
                    tab
                    label="30,000"
                    value="30000"
                    name="changePrice"
                    checked={disablePriceInput && currentPrice === 30000}
                    onChange={() => {
                      setDisablePriceInput(true);
                      setCurrentPrice(30000);
                      setCurrentPriceInput(``);
                    }}
                  />
                </RadioLabel>
                <RadioLabel>
                  <InputRadio
                    tab
                    label="50,000"
                    value="50000"
                    name="changePrice"
                    checked={disablePriceInput && currentPrice === 50000}
                    onChange={() => {
                      setDisablePriceInput(true);
                      setCurrentPrice(50000);
                      setCurrentPriceInput(``);
                    }}
                  />
                </RadioLabel>
                <RadioLabel>
                  <InputRadio
                    tab
                    label="100,000"
                    value="100000"
                    name="changePrice"
                    checked={disablePriceInput && currentPrice === 100000}
                    onChange={() => {
                      setDisablePriceInput(true);
                      setCurrentPrice(100000);
                      setCurrentPriceInput(``);
                    }}
                  />
                </RadioLabel>
                <RadioLabel>
                  <InputRadio
                    tab
                    label="기타"
                    value="기타"
                    name="changePrice"
                    checked={!disablePriceInput}
                    onChange={() => {
                      setDisablePriceInput(false);
                    }}
                  />
                </RadioLabel>
              </RadioFlex>
              <InputTextCustom disabled={disablePriceInput}>
                <InputText
                  placeholder="금액 직접 입력"
                  disabled={disablePriceInput}
                  value={currentPriceInput}
                  onChange={(e) => setCurrentPriceInput(e.target.value)}
                />
                {` `}
                <span>원</span>
              </InputTextCustom>
            </CellChange>
            <Cell type="status">
              <Button
                type="submit"
                full
                size="sm"
                onClick={() => onSubmit()}
                disabled={!finalPrice}
                css={`
                  width: 100% !important;
                `}
              >
                완료
              </Button>
            </Cell>
          </>
        ) : (
          <Cell type="status">
            <Button
              size="sm"
              color="gray"
              outline
              onClick={() => setIsEditMode(true)}
              css={`
                padding: 10px 40px;
                ${breakpoint(640)} {
                  padding: 7px 40px;
                  font-size: 14px;
                }
              `}
            >
              후원금 변경
            </Button>
          </Cell>
        )}
      </>
    );
  },
);

export default MyRegularSupportPriceChange;
