import MyRegularSupportPriceChange from './MyRegularSupportPriceChange';
import InputText from '../Input/InputText';
import RegularAmountChangeLayerPopup, {
  RegularAmountModifyResult,
} from '../Layer/RegularAmountChangeLayerPopup';
import {
  myActiveHistoryVo,
  MySupportInfoControllerService,
} from '@/__generated__/FrontApi';
import Button from '@/components/Button';
import { breakpoint } from '@/helpers/BreakpointHelper';
import GtmHelper from '@/helpers/GtmHelper';
import { useFindSupportCategory } from '@/helpers/SupportHelper';
import { usePopupStore } from '@/stores/PopupStore';
import { useUserStore } from '@/stores/UserStore';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

const HistoryList = styled.ul``;
const HistoryItem = styled.li`
  display: table;
  width: 100%;
  table-layout: fixed;
  padding: 32px 0;
  position: relative;
  border-bottom: 1px solid #b7b8ba;
  ${breakpoint(800)} {
    display: block;
    padding: 24px 0;
  }
  ${Button} {
    max-width: none;
    width: auto;
  }
`;
const InputTextCustom = styled.div<{ active?: boolean }>`
  border: 1px solid #e5e6e8;
  margin-top: -1px;
  ${({ active }) => {
    if (!active) {
      return `
            span {
              color: #2d2926 !important;
            }
          `;
    }
    return ``;
  }}
`;
const Tip = styled.p`
  color: #828385;
  font-size: 14px;
  font-weight: normal;
`;
const Cell = styled.div<{ type?: string; status?: string; price?: boolean }>`
  display: table-cell;
  ${InputTextCustom} {
    span {
      width: 10%;
      text-align: right;
      color: #b7b8ba;
    }
  }
  ${breakpoint(800)} {
    display: block;
    ${({ status }) => {
      if (status === `dark` || status === `light`) {
        return `
            position: absolute;
            right: 0;
            top: 24px;
            font-size: 16px;
            text-align: right;
            white-space: nowrap;
          `;
      }
      return ``;
    }}
    ${({ type }) => {
      if (type === `date`) {
        return `
        text-align: left;
          `;
      }
      if (type === `status`) {
        return `
            position: absolute;
            right: 0;
            top: 24px;
            font-size: 16px;
            text-align: right;
            white-space: nowrap;
            width: 100%;
          `;
      }
      return `padding-left: 0;`;
    }}
  }
  ${({ type }) => {
    if (type === `date` || type === `status`) {
      return `
            width: 15%;
            text-align: center;
            font-weight: bold;
            font-size: 20px;
            .sky {
              color: #1cabe2
            }
          `;
    }
    if (type === `reward`) {
      return `
        font-size: 16px;   
        width: 10%;   
        color: #828385;
      `;
    }
    if (type === `address`) {
      return `  
        width: 20%;
        text-align: right;   
        vertical-align: middle;
        ${Tip} {
          color: #828385 !important;
          margin-top: 16px;
        }
      `;
    }
    return `padding-left: 24px;`;
  }}
  ${({ price }) => {
    if (price) {
      return `
            width: 40%;
            padding-right: 16px;
            ${InputText} {
              border: none;
              width: 90%;
              display: inline-block;
            }
          `;
    }
    return ``;
  }}
  ${({ status }) => {
    if (status === `dark`) {
      return `
            color: #2d2926
          `;
    }
    if (status === `light`) {
      return `
            color: #828385;
          `;
    }
    return ``;
  }}
`;

interface RegularSupportHistoryListProps {
  items: myActiveHistoryVo[];
  onItemChange: () => void;
}

// 정기후원 컴포넌트
export const RegularSupportHistoryList: FC<RegularSupportHistoryListProps> = observer(
  ({ items, onItemChange }) => {
    const userStore = useUserStore();
    const popupStore = usePopupStore();
    const [
      currentModifyResult,
      setCurrentModifyResult,
    ] = useState<RegularAmountModifyResult>();
    const supportCategory = useFindSupportCategory(currentModifyResult?.glCode);
    const [isAmountIncrease, setisAmountIncrease] = useState(false);

    const onPriceChange = useCallback(
      async (giftId: string, price: number) => {
        if (userStore.user) {
          try {
            const {
              resultCode,
              data,
            } = await MySupportInfoControllerService.modifyAmountByGiftIdUsingPost(
              {
                donorId: userStore.user.donorId,
                giftId,
                amount: `${price}`,
                incDecYn: `Y`,
              },
            );
            if (resultCode === `success`) {
              setCurrentModifyResult(data);
              // 변경이벤트 발생
              onItemChange();
            }
          } catch (e) {
            if (e.response) {
              popupStore.show(
                e.response.data.resultMessage || `오류가 발생했습니다`,
              );
            }
          }
        }
      },
      [onItemChange, popupStore, userStore.user],
    );

    useEffect(() => {
      // 정기후원금액 변경결과 넘어오면
      if (currentModifyResult && supportCategory) {
        // GTM 이벤트 발생
        GtmHelper.editPayMoney({
          supportCategory,
          oldAmount: currentModifyResult.originAmount,
          newAmount: currentModifyResult.upgradeAmount,
        });
      }
    }, [currentModifyResult, supportCategory]);

    return (
      <HistoryList>
        {items.map((item) => (
          <HistoryItem
            key={item.giftId}
            css={`
              position: relative;
            `}
          >
            <MyRegularSupportPriceChange
              supportItem={`${item.description} ${
                item.cdDescriptionDetail ? ` [${item.cdDescriptionDetail}]` : ``
              }`}
              firstSupportDate={dayjs(item.startDonateDate).format(
                `YYYY.MM.DD`,
              )}
              supportTerm={`매월 ${item.payday}일`}
              supportPay={item.paymentTypeName}
              price={`${item.bill}`}
              onPriceChange={(price) =>
                item.giftId && onPriceChange(item.giftId, price)
              }
            />
          </HistoryItem>
        ))}
        {!!currentModifyResult && (
          <RegularAmountChangeLayerPopup
            result={currentModifyResult}
            isAmountIncrease={
              String(currentModifyResult.upgradeAmount).indexOf(`-`) < 0
            }
            onRequestClose={() => setCurrentModifyResult(undefined)}
          />
        )}
      </HistoryList>
    );
  },
);

export default RegularSupportHistoryList;
