import { MemberLoginControllerService } from '@/__generated__/CommonApi';
import { MyPersonalInfoControllerService } from '@/__generated__/FrontApi';
import iconFacebook from '@/assets/img/join/ico_facebook@2x.png';
import iconGoogle from '@/assets/img/join/ico_google@2x.png';
import iconNaver from '@/assets/img/join/ico_naver@2x.png';
import Button from '@/components/Button';
import Container from '@/components/Container';
import ErrorMessage from '@/components/Form/ErrorMessage';
import FormGroup from '@/components/Form/FormGroup';
import Label from '@/components/Form/Label';
import Col from '@/components/Grid/Col';
import InputPassword from '@/components/Input/InputPassword';
import InputRadio from '@/components/Input/InputRadio';
import InputText from '@/components/Input/InputText';
import InputTextarea from '@/components/Input/InputTextarea';
import NaverLogin, {
  NaverSuccessResult,
} from '@/components/SocialLogin/NaverLogin';
import { NotiBox, NotiList, NotiItem } from '@/components/Text/TextNoti';
import { H2 } from '@/components/Titles';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import {
  FormContainer,
  SocialButton,
  SocialButtonText,
} from '@/page-blocks/auth/AuthCommon';
import {
  ButtonFlex,
  ContainerGroup,
  ContentsArea,
  DropFlex,
  DropFlexCell,
  SectionTop,
  TitleArea,
} from '@/page-blocks/mypage/MypageCommon';
import { usePopupStore } from '@/stores/PopupStore';
import { useUserStore } from '@/stores/UserStore';
import { navigate, RouteComponentProps } from '@reach/router';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { PageProps } from 'gatsby';
import { observer } from 'mobx-react-lite';
import React, { FC, useCallback, useState } from 'react';
import { ReactFacebookLoginInfo } from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { SubmitHandler, useForm } from 'react-hook-form';

interface UserDropFormData {
  dropEnewsYn: 'Y' | 'N';
  dropReason: string;
  dropMemo: string;
  password: string;
}

const UserDrop: FC<RouteComponentProps | PageProps> = observer(
  ({ location }) => {
    const userStore = useUserStore();
    const popupStore = usePopupStore();
    const [authed, setAuthed] = useState(false);
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm<UserDropFormData>({
      defaultValues: {},
    });

    const onSubmit: SubmitHandler<UserDropFormData> = useCallback(
      async (formData) => {
        if (!userStore.user) {
          return;
        }
        // 소셜회원이면
        if (userStore.isSocial()) {
          // 인증여부 확인
          if (!authed) {
            popupStore.show(`소셜 인증을 먼저 진행해주세요`);
            return;
          }
        }
        // 일반 가입이면
        else {
          // 비밀번호 검증
          try {
            const {
              resultCode,
              resultMessage,
            } = await MemberLoginControllerService.memberLoginUsingPost({
              id: userStore.user.userId,
              password: formData.password,
            });
            // 실패시
            if (resultCode !== `success`) {
              popupStore.show(resultMessage);
              return;
            }
          } catch (e) {
            if (e.response) {
              popupStore.show(e.response.data.resultMessage);
              return;
            }
          }
        }

        // 탈퇴 진행
        const {
          resultCode,
        } = await MyPersonalInfoControllerService.setDropMyinfoByUserIdUsingPost(
          {
            dropEnewsYn: formData.dropEnewsYn,
            dropReason: formData.dropReason,
            dropMemo: formData.dropMemo,
          },
        );
        // 탈퇴 성공시
        if (resultCode === `success`) {
          // 완료페이지 이동
          navigate(`/mypage/drop/complete`);
        }
      },
      [authed, popupStore, userStore],
    );

    // 네이버로 검증
    const authWithNaver = useCallback(
      async (result: NaverSuccessResult) => {
        if (userStore.user) {
          const isValid = userStore.user.socialId === result.user.id;
          if (isValid) {
            setAuthed(true);
          }
        }
      },
      [userStore.user],
    );

    // 페이스북으로 검증
    const authWithFacebook = useCallback(
      async (result: ReactFacebookLoginInfo) => {
        if (userStore.user) {
          const isValid = userStore.user.socialId === result.id;
          if (isValid) {
            setAuthed(true);
          }
        }
      },
      [userStore.user],
    );

    // 구글로 검증
    const authWithGoogle = useGoogleLogin({
      onSuccess: async (res) => {
        const userInfo = await axios.get(
          `https://www.googleapis.com/oauth2/v3/userinfo`,
          {
            headers: { Authorization: `Bearer ${res.access_token}` },
          },
        );
        if (userStore.user) {
          const isValid = userStore.user.socialId === userInfo.data.sub;
          if (isValid) {
            setAuthed(true);
          }
        }
      },
      // eslint-disable-next-line no-console
      onError: (e) => console.error(e),
    });

    return (
      <LayoutWithTitle location={location} title="웹사이트 회원탈퇴">
        <form onSubmit={handleSubmit(onSubmit)}>
          <SectionTop>
            <ContainerGroup>
              <Container>
                <NotiBox
                  css={`
                    border: 1px solid #e5e6e8;
                    margin-bottom: 0;
                  `}
                >
                  <NotiList>
                    <NotiItem>
                      유니세프 웹사이트의 회원 탈퇴를 하셔도 후원은 계속해서
                      진행됩니다.
                    </NotiItem>
                    <NotiItem>
                      단, 후원 또는 활동내역을 확인하시는데 불편이 발생할 수
                      있습니다.
                    </NotiItem>
                    <NotiItem>
                      웹 회원 탈퇴 후 재가입은 가능하지만 한 번 탈퇴한 아이디는
                      다시 사용하실 수 없습니다.
                    </NotiItem>
                    <NotiItem>
                      <strong
                        css={`
                          color: #e2231a;
                        `}
                      >
                        웹 회원 탈퇴와 함께 정기후원 중단을 원하시면 후원본부
                        02-737-1004로 연락
                      </strong>
                      주시길 바랍니다.
                    </NotiItem>
                  </NotiList>
                </NotiBox>
              </Container>
              <Container>
                <TitleArea>
                  <H2>웹사이트 회원 탈퇴 사유</H2>
                  <p>
                    불편했던 사항을 체크해주시고 유니세프에 바라는 점을
                    적어주세요.
                  </p>
                </TitleArea>
                <ContentsArea>
                  <DropFlex>
                    <DropFlexCell>
                      <InputRadio
                        ref={register({
                          required: {
                            value: true,
                            message: `필수 입력입니다`,
                          },
                        })}
                        label="아이디 변경/재가입을 위해서"
                        name="dropReason"
                        value="아이디 변경/재가입을 위해서"
                      />
                    </DropFlexCell>
                    <DropFlexCell>
                      <InputRadio
                        ref={register}
                        label="사용 빈도가 낮아서"
                        name="dropReason"
                        value="사용 빈도가 낮아서"
                      />
                    </DropFlexCell>
                    <DropFlexCell>
                      <InputRadio
                        ref={register}
                        label="필요한 정보가 없어서"
                        name="dropReason"
                        value="필요한 정보가 없어서"
                      />
                    </DropFlexCell>
                    <DropFlexCell>
                      <InputRadio
                        ref={register}
                        label="웹서비스 장애 빈번"
                        name="dropReason"
                        value="웹서비스 장애 빈번"
                      />
                    </DropFlexCell>
                    <DropFlexCell>
                      <InputRadio
                        ref={register}
                        label="기타"
                        name="dropReason"
                        value="기타"
                      />
                    </DropFlexCell>
                  </DropFlex>
                  {errors.dropReason && (
                    <ErrorMessage>{errors.dropReason.message}</ErrorMessage>
                  )}
                </ContentsArea>
              </Container>
              <Container>
                <TitleArea>
                  <H2>E-소식지 수신여부</H2>
                  <p>
                    회원탈퇴 후에도 유니세프 E-소식지를 계속 받아보시겠습니까?
                  </p>
                </TitleArea>
                <ContentsArea>
                  <DropFlex>
                    <DropFlexCell>
                      <InputRadio
                        ref={register({
                          required: {
                            value: true,
                            message: `필수 입력입니다`,
                          },
                        })}
                        label="예, 계속 받아보겠습니다"
                        name="dropEnewsYn"
                        value="Y"
                      />
                    </DropFlexCell>
                    <DropFlexCell>
                      <InputRadio
                        ref={register}
                        label="아니오"
                        name="dropEnewsYn"
                        value="N"
                      />
                    </DropFlexCell>
                  </DropFlex>
                  {errors.dropEnewsYn && (
                    <ErrorMessage>{errors.dropEnewsYn.message}</ErrorMessage>
                  )}
                </ContentsArea>
              </Container>
              <Container>
                <TitleArea>
                  <H2>유니세프에 바라는 의견</H2>
                  <p>유니세프에 바라는 의견부탁드립니다.</p>
                </TitleArea>
                <ContentsArea>
                  <DropFlex>
                    <DropFlexCell>
                      <InputTextarea
                        ref={register}
                        placeholder="유니세프에 바라는 의견부탁드립니다."
                        name="dropMemo"
                      />
                    </DropFlexCell>
                  </DropFlex>
                  {errors.dropMemo && (
                    <ErrorMessage>{errors.dropMemo.message}</ErrorMessage>
                  )}
                </ContentsArea>
              </Container>
              <Container>
                <TitleArea>
                  <H2>본인 확인</H2>
                  <p>
                    {userStore.isSocial()
                      ? `회원님의 정보 보호를 위해 한 번 더 인증해주세요.`
                      : `회원님의 정보 보호를 위해 비밀번호를 한 번 더 입력해주세요.`}
                  </p>
                </TitleArea>
                <ContentsArea>
                  {/* 일반 가입이면 */}
                  {!userStore.isSocial() && (
                    <DropFlex>
                      <DropFlexCell>
                        <FormGroup>
                          <Label required>아이디</Label>
                          <InputText
                            name="userId"
                            value={userStore.user?.userId}
                            disabled
                          />
                        </FormGroup>
                      </DropFlexCell>
                      <DropFlexCell>
                        <FormGroup>
                          <Label required>비밀번호</Label>
                          <InputPassword
                            ref={register({
                              required: {
                                value: true,
                                message: `필수 입력입니다`,
                              },
                            })}
                            name="password"
                            placeholder="비밀번호 입력"
                          />
                        </FormGroup>
                        {errors.password && (
                          <ErrorMessage>{errors.password.message}</ErrorMessage>
                        )}
                      </DropFlexCell>
                    </DropFlex>
                  )}

                  {/* 소셜 가입이면 */}
                  {userStore.isSocial() &&
                    (authed ? (
                      <Col align="center">
                        <span>인증되었습니다</span>
                      </Col>
                    ) : (
                      <>
                        {userStore.user?.socialType === `N` && (
                          <Col align="center">
                            <NaverLogin
                              clientId={process.env.NAVER_LOGIN_CLIENT_ID || ``}
                              callbackUrl={`${process.env.SELF_URL}/auth/join`}
                              render={(props) => (
                                <SocialButton {...props}>
                                  <img
                                    src={iconNaver}
                                    alt="네이버"
                                    width="60"
                                    height="60"
                                  />
                                  <br />
                                  <SocialButtonText>네이버</SocialButtonText>
                                </SocialButton>
                              )}
                              onSuccess={authWithNaver}
                              onFailure={(e) => console.error(e)}
                            />
                          </Col>
                        )}
                        {userStore.user?.socialType === `F` && (
                          <Col align="center">
                            <FacebookLogin
                              appId={process.env.FACEBOOK_LOGIN_APP_ID || ``}
                              fields="name,email"
                              disableMobileRedirect
                              render={({ onClick }) => (
                                <SocialButton onClick={onClick}>
                                  <img
                                    src={iconFacebook}
                                    alt="페이스북"
                                    width="60"
                                    height="60"
                                  />
                                  <br />
                                  <SocialButtonText>페이스북</SocialButtonText>
                                </SocialButton>
                              )}
                              callback={authWithFacebook}
                            />
                          </Col>
                        )}
                        {userStore.user?.socialType === `G` && (
                          <Col align="center">
                            <SocialButton onClick={() => authWithGoogle()}>
                              <img
                                src={iconGoogle}
                                alt="구글"
                                width="60"
                                height="60"
                              />
                              <br />
                              <SocialButtonText>구글</SocialButtonText>
                            </SocialButton>
                          </Col>
                        )}
                      </>
                    ))}
                </ContentsArea>
              </Container>
              <FormContainer>
                <ButtonFlex>
                  <li>
                    <Button onClick={() => navigate(-1)}>취소하기</Button>
                  </li>
                  <li>
                    <Button outline type="submit">
                      탈퇴하기
                    </Button>
                  </li>
                </ButtonFlex>
              </FormContainer>
            </ContainerGroup>
          </SectionTop>
        </form>
      </LayoutWithTitle>
    );
  },
);

export default UserDrop;
