import { isBrowser } from '@/helpers/BrowserHelper';
import Highcharts, { Options } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more';
import solidGauge from 'highcharts/modules/solid-gauge';
import React, { VFC } from 'react';

if (isBrowser) {
  // solid gauge 로딩
  highchartsMore(Highcharts);
  solidGauge(Highcharts);
}

const options: Options = {
  chart: {
    plotShadow: false,
    type: `solidgauge`,
    height: `350px`,
  },
  tooltip: {
    enabled: false,
  },
  accessibility: {
    point: {
      valueSuffix: `%`,
    },
  },
  pane: {
    startAngle: 0,
    endAngle: 360,
    background: [
      {
        // Track for Move
        outerRadius: `112%`,
        innerRadius: `88%`,
        backgroundColor: `rgba(28,171,226,0.3)`,
        borderWidth: 0,
      },
    ],
  },
  yAxis: {
    min: 0,
    max: 100,
    lineWidth: 0,
    tickPositions: [],
  },
  plotOptions: {
    solidgauge: {
      dataLabels: {
        enabled: false,
      },
      linecap: `round`,
      stickyTracking: false,
      rounded: true,
    },
  },
  credits: {
    enabled: false,
  },
};

const GaugeChart: VFC<Pick<Options, 'series'> & { centerText: string }> = ({
  series,
  centerText,
}) => (
  <HighchartsReact
    highcharts={Highcharts}
    options={{
      ...options,
      series,
      title: {
        text: centerText,
        align: `center`,
        verticalAlign: `middle`,
        y: 30,
        style: {
          fontSize: `16px`,
          fontFamily: `'Noto Sans KR',sans-serif`,
        },
      },
    }}
  />
);

export default GaugeChart;
