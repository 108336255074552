import { PopCcontainer, PopupTitle } from '../Css/Pop';
import IcoArrow from '@/assets/img/ico_pagination_next@2x.png';
import Button, { BtnClose } from '@/components/Button';
import Modal, { ModalProps } from '@/components/Modal';
import { H3_2 } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import dayjs from 'dayjs';
import React, { FC } from 'react';
import styled from 'styled-components';

const BtmnFlex = styled.ul`
  width: 160px;
  margin: 40px auto 0;
  padding-bottom: 40px;
`;
const PayInfoChangeContents = styled.div`
  margin-top: 64px;
  padding: 64px;
  background: #f8f9fa;
  ${breakpoint(640)} {
    padding: 40px 28px;
  }
  ${breakpoint(360)} {
    padding: 40px 12px;
  }
  li {
    position: relative;
    & + li {
      margin-top: 12px;
    }
    div {
      padding-left: 170px;
      ${breakpoint(640)} {
        padding-left: 90px;
      }
    }
    strong {
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  span {
    display: inline-block;
    position: relative;
  }
  .before {
    width: 130px;
    padding-right: 48px;
    color: #828385;
    ${breakpoint(640)} {
      width: auto;
      padding-right: 0;
    }
  }
  .after {
    padding-left: 72px;
    ${breakpoint(640)} {
      padding-left: 48px;
    }
    &::before {
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      background: url(${IcoArrow}) no-repeat center center;
      background-size: 100%;
      position: absolute;
      left: 0;
      top: 1px;
      ${breakpoint(640)} {
        left: 12px;
        top: -1px;
      }
    }
  }
`;

// modify 결과
export interface RegularAmountModifyResult {
  amount: string;
  amountUpgradeYn: 'Y' | 'N';
  donationName: string;
  donorId: string;
  email: string;
  giftId: string;
  glCode: string;
  nextPayDay: string; // "2021-07-15"
  originAmount: number; // 30000
  payDay: string; // "15"
  today: string; // "2021-07-20"
  upgradeAmount: number; // 20000
  userName: string; // "이미지"
}

interface RegularAmountProps extends ModalProps {
  result: RegularAmountModifyResult;
  isAmountIncrease?: boolean;
}

const RegularAmountChangeLayerPopup: FC<RegularAmountProps> = ({
  result,
  onRequestClose,
  isAmountIncrease,
  ...props
}) => (
  <Modal onRequestClose={onRequestClose} maxWidth="640px" {...props}>
    <PopCcontainer>
      <PopupTitle
        css={`
          border-bottom: 0;
        `}
      >
        <BtnClose
          onClick={() => onRequestClose()}
          css={`
            margin-top: 16px;
          `}
        >
          <span className="a11y">닫기</span>
        </BtnClose>
      </PopupTitle>
      {isAmountIncrease && dayjs().isBefore(dayjs(`2021-12-31`)) ? (
        <>
          <H3_2
            css={`
              padding-top: 70px;
              text-align: center;
            `}
          >
            {result.userName} 후원자님 <br />
            2022년 새해다짐챌린지에 함께해 주셔서 감사합니다.
          </H3_2>
          <p
            css={`
              padding-top: 20px;
              text-align: center;
            `}
          >
            ※유니세프 드림박스에 당첨되시면 챌린지 기간이 끝난 후 약 2~3주
            내외로 개별 연락드립니다.
          </p>
        </>
      ) : (
        <H3_2
          css={`
            padding-top: 70px;
            text-align: center;
          `}
        >
          {result.userName} 후원자님 <br />
          정기후원금액이 변경되었습니다.
        </H3_2>
      )}
      <PayInfoChangeContents>
        <ul>
          <li>
            <strong>후원명</strong>
            <div>{result.donationName || ` `}</div>
          </li>
          <li>
            <strong>기존 후원금액</strong>
            <div>{Number(result.originAmount).toLocaleString()}원</div>
          </li>
          <li>
            <strong>추가 후원금액</strong>
            <div>{Number(result.upgradeAmount).toLocaleString()}원</div>
          </li>
          <li>
            <strong>현재 후원금액</strong>
            <div
              css={`
                color: #1cabe2;
              `}
            >
              매월 {Number(result.amount).toLocaleString()}원
            </div>
          </li>
          <li>
            <strong>다음 후원일</strong>
            <div>
              {dayjs(result.nextPayDay, `YYYY-MM-DD`).format(`YYYY.MM.DD`)}
            </div>
          </li>
        </ul>
      </PayInfoChangeContents>
      <BtmnFlex>
        <Button full onClick={() => onRequestClose()} size="sm">
          확인
        </Button>
      </BtmnFlex>
    </PopCcontainer>
  </Modal>
);
export default RegularAmountChangeLayerPopup;
