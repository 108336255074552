import InputText from './InputText';
import ko from '../../helpers/date-fns/locale/ko';
import iconCalendar from '@/assets/img/icons/icon_datepicker_calendar@2x.png';
import React, { forwardRef } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import styled from 'styled-components';

import 'react-datepicker/dist/react-datepicker.css';
import './InputDatePicker.scss';

const PickerContainer = styled.div`
  position: relative;
  img {
    width: 24px;
    height: 24px;
    position: absolute;
    z-index: 1;
    left: 12px;
    top: 16px;
  }
  input {
    padding-left: 48px !important;
  }
`;

type InputDatePickerProps = ReactDatePickerProps;

const InputDatePicker = forwardRef<HTMLInputElement, InputDatePickerProps>(
  ({ className, ...props }, ref) => (
    <PickerContainer className={className}>
      <img src={iconCalendar} alt="캘린더" />
      <ReactDatePicker
        customInput={<InputText ref={ref} />}
        dateFormat="yyyy-MM-dd"
        locale={ko}
        {...props}
      />
    </PickerContainer>
  ),
);

export default InputDatePicker;
