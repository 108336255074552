import Container from '@/components/Container';
import { NotiBox, NotiItem, NotiList } from '@/components/Text/TextNoti';
import { H2, Tit } from '@/components/Titles';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import {
  CellTitle,
  ContentsArea,
  DropFlex,
  DropFlexCell,
  SectionTop,
  TitleArea,
} from '@/page-blocks/mypage/MypageCommon';
import { useUserStore } from '@/stores/UserStore';
import { RouteComponentProps } from '@reach/router';
import dayjs from 'dayjs';
import React, { FC, useEffect, useState } from 'react';

const MyPageDropCompletePage: FC<RouteComponentProps> = ({ location }) => {
  const userStore = useUserStore();
  // 로그아웃 전 user 정보 저장
  const [user] = useState(userStore.user);

  useEffect(() => {
    // 로그아웃 처리
    if (userStore.isLoggedIn()) {
      userStore.logout();
    }
  }, [userStore]);

  return (
    <LayoutWithTitle location={location} title="웹사이트 회원탈퇴">
      <SectionTop>
        <Container>
          <TitleArea>
            <H2>
              <Tit size="s1-5" color="sky" weight="normal">
                웹사이트 회원탈퇴가 <br />
                정상적으로 완료되었습니다.
              </Tit>
            </H2>
            <p
              css={`
                display: block !important;
              `}
            >
              그 동안 유니세프를 이용해주셔서 감사합니다.
              <br />
              <strong>회원탈퇴를 하셔도 후원은 계속 진행됩니다.</strong>
              {` `}
              정기후원 중단을 원하시면{` `}
              <strong
                css={`
                  color: #1cabe2;
                `}
              >
                후원본부 02-737-1004
              </strong>
              로 연락주시기 바랍니다. <br />
              보다 더 좋은 서비스를 제공하는 유니세프가 되겠습니다.
            </p>
          </TitleArea>
          <SectionTop>
            <ContentsArea>
              <DropFlex>
                <DropFlexCell>
                  <CellTitle>아이디</CellTitle>
                  {` `}
                  {user?.socialId ? (
                    <>
                      {user.socialType === `N` && `네이버가입`}
                      {user.socialType === `F` && `페이스북가입`}
                      {user.socialType === `G` && `구글가입`}
                    </>
                  ) : (
                    user?.userId
                  )}
                </DropFlexCell>
                <DropFlexCell>
                  <CellTitle>탈퇴일</CellTitle>
                  {` `}
                  {dayjs().format(`YYYY년 M월 DD일`)}
                </DropFlexCell>
              </DropFlex>
            </ContentsArea>
            <NotiBox
              css={`
                margin: 24px 0 0 0;
              `}
            >
              <NotiList>
                <NotiItem>
                  재가입 시 이전에 사용하시던 동일 아이디로는 회원가입 되지
                  않습니다.
                </NotiItem>
              </NotiList>
            </NotiBox>
          </SectionTop>
        </Container>
      </SectionTop>
    </LayoutWithTitle>
  );
};

export default MyPageDropCompletePage;
