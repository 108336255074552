import Button from '@/components/Button';
import ContactUs from '@/components/ContactUs';
import Container from '@/components/Container';
import { FormItem } from '@/components/Form/FormGroup';
import Row from '@/components/Grid/Row';
import { H2 } from '@/components/Titles';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import {
  ButtonGroup,
  FormContainer,
  PageTitle,
} from '@/page-blocks/auth/AuthCommon';
import { SectionTop } from '@/page-blocks/mypage/MypageCommon';
import { navigate } from 'gatsby';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';

const SupportHistoryConnectError: FC = observer(({ location }) => (
  <LayoutWithTitle location={location} title="후원내역 연결하기">
    <SectionTop>
      <FormContainer
        css={`
          margin-top: 96px;
          margin-bottom: 200px;
        `}
      >
        <PageTitle>
          <H2
            css={`
              text-align: center;
            `}
          >
            등록된 개인정보와 일치하는
            <br />
            후원정보를 찾을 수 없습니다.
          </H2>
        </PageTitle>
        <FormItem noBorder>
          <ButtonGroup
            css={`
              margin-top: 80px;
            `}
          >
            <Row>
              <Button outline onClick={() => navigate(`/mypage/my-info`)}>
                개인정보 변경
              </Button>
              <Button
                onClick={() => navigate(`/mypage/support-history/connect`)}
              >
                다시 시도하기
              </Button>
            </Row>
          </ButtonGroup>
        </FormItem>
      </FormContainer>
      <Container>
        <ContactUs
          itemData={[
            {
              id: 1,
              tit: `후원내역 연결에 어려움이 있으신가요?`,
              desc: `가족 명의로 후원하고 계시거나 후원 내역 연결에 문제가 있으시다면 후원본부로 연락 주시기 바랍니다.`,
              costumer: `후원본부`,
              tel: `02-737-1004`,
              email: `donor@unicef.or.kr`,
            },
          ]}
        />
      </Container>
    </SectionTop>
  </LayoutWithTitle>
));
export default SupportHistoryConnectError;
