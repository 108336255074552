import { MyQnaControllerService } from '@/__generated__/FrontApi';
import IconNotice from '@/assets/img/icons/icon_infomation_outline@2x.png';
import BgThanksTo from '@/assets/img/img_onetoone_form@2x.png';
import Button from '@/components/Button';
import Container from '@/components/Container';
import ErrorMessage from '@/components/Form/ErrorMessage';
import InputRadio from '@/components/Input/InputRadio';
import InputText from '@/components/Input/InputText';
import InputTextarea from '@/components/Input/InputTextarea';
import { NotiBox, NotiList, NotiItem } from '@/components/Text/TextNoti';
import { H3, H4, Tit } from '@/components/Titles';
import { ONE_TO_ONE_CATEGORIES } from '@/helpers/BoardHelper';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { SectionTop, ContainerGroup } from '@/page-blocks/mypage/MypageCommon';
import MypageRoutes from '@/page-blocks/mypage/MypageRoutes';
import { usePopupStore } from '@/stores/PopupStore';
import { useUserStore } from '@/stores/UserStore';
import { navigate } from '@reach/router';
import { observer } from 'mobx-react-lite';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';

const ThanksTo = styled.div`
  padding: 80px 479px 80px 88px;
  position: relative;
  background: #fff url(${BgThanksTo}) no-repeat right bottom;
  background-size: 369px;
  ${H4} {
    line-height: 2;
    margin-top: 16px;
  }
  p {
    margin-top: 48px;
  }
  &::before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: #e2f0f6;
    position: absolute;
    left: -60px;
    top: 10px;
    transform: rotate(-6deg);
    z-index: -1;
  }
  ${breakpoint(1160)} {
    padding: 40px 300px 40px 40px;
  }
  ${breakpoint(1024)} {
    background-size: auto 80%;
    p {
      margin-top: 16px;
    }
  }
  ${breakpoint(640)} {
    padding: 24px 24px 180px 24px;
    background-size: auto 220px;
    ${H4} {
      line-height: 1.6;
      margin-top: 16px;
    }
    &::before {
      transform: rotate(-3deg);
    }
  }
`;
const FormFullContainer = styled(Container)`
  ${H3} {
    margin-bottom: 24px;
  }
  ${NotiBox} {
    margin-top: 16px;
  }
  ${breakpoint(640)} {
    ${H3} {
      margin-bottom: 16px;
      font-size: 16px;
    }
  }
`;
const QnaCategory = styled.div`
  margin: 0 -24px -24px 0;
  ${breakpoint(1160)} {
    margin: 0 -2% -2% 0;
  }
`;
const InputRadioButton = styled.div`
  width: 204px;
  margin: 0 24px 24px 0;
  display: inline-block;
  span {
    font-size: 16px;
  }
  .btn {
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
  }
  &:hover {
    .btn {
      background: #1cabe2;
      color: #fff;
    }
  }
  ${breakpoint(1160)} {
    width: 18%;
    margin: 0 2% 2% 0;
  }
  ${breakpoint(1024)} {
    width: 23%;
  }
  ${breakpoint(800)} {
    min-width: 31.3%;
    width: auto;
    &:last-child {
      width: 64.6%;
    }
    span {
      font-size: 14px;
    }
    div {
      height: 56px;
      line-height: 56px;
    }
    label {
      max-width: none;
    }
  }
`;
const LimitCount = styled.span<{ type?: 'textarea' }>`
  display: inline-block;
  width: 8%;
  padding: 0 20px;
  color: #b7b8ba;
  text-align: right;
  ${({ type }) => {
    if (type === `textarea`) {
      return `width: 100%; display: block; padding: 20px`;
    }
  }}
  ${breakpoint(1024)} {
    white-space: nowrap;
  }
  ${breakpoint(640)} {
    width: 16%;
    padding: 0 16px 0 0;
    ${({ type }) => {
      if (type === `textarea`) {
        return `width: 100%; padding: 16px;`;
      }
    }}
  }
`;
const InputTextLimit = styled.div`
  border: 1px solid #e5e6e8;
  ${InputText} {
    border: none;
    width: 92%;
    display: inline-block;
  }
  ${InputTextarea} {
    border: none;
    height: 415px;
  }
  ${breakpoint(640)} {
    ${InputText} {
      width: 84%;
    }
    ${InputTextarea} {
      height: 240px;
    }
  }
`;
const ButtonContainer = styled(Container)`
  text-align: center;
  ${Button} {
    width: 256px;
    & + ${Button} {
      margin-left: 32px;
    }
  }
  ${breakpoint(640)} {
    ${Button} {
      width: 46%;
      & + ${Button} {
        margin-left: 8%;
      }
    }
  }
`;

const NoticeBox = styled.div`
  background: #f8f9fa;
  padding: 16px 24px 16px 56px;
  position: relative;
  &::before {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    background: url(${IconNotice}) no-repeat center center;
    background-size: 100%;
    position: absolute;
    left: 24px;
    top: 18px;
  }
`;

interface OneToOneFormData {
  optData4: string;
  subject: string;
  contents: string;
}

const OneToOneForm: FC = observer(() => {
  const userStore = useUserStore();
  const popupStore = usePopupStore();
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<OneToOneFormData>({
    defaultValues: {},
  });
  const subject = watch(`subject`, ``);
  const contents = watch(`contents`, ``);

  const onSubmit: SubmitHandler<OneToOneFormData> = useCallback(
    async (formData) => {
      const {
        resultCode,
        resultMessage,
      } = await MyQnaControllerService.myQnaInsertUsingPost({
        ...formData,
        boardCategoryCode: 17,
        writerName: userStore.user?.name,
      });
      if (resultCode === `success`) {
        popupStore.show(resultMessage, {
          onConfirm: () => navigate(`/mypage/onetoone`),
        });
      }
      // 폼 초기화 (재전송 방지)
      reset();
    },
    [popupStore, reset, userStore.user?.name],
  );

  const [notice, setNotice] = useState<any>();

  const loadItems = useCallback(async () => {
    const {
      data,
      resultCode,
    } = await MyQnaControllerService.getMyQnaNoticeUsingGet({});
    if (resultCode === `success`) {
      setNotice(data);
    }
  }, []);

  useEffect(() => {
    loadItems();
  }, [loadItems]);

  return (
    <LayoutWithTitle title="마이페이지" description="for every child, UNICEF">
      <MypageRoutes currentKey={7} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <SectionTop>
          <ContainerGroup>
            <Container>
              <ThanksTo>
                <Tit size="s1-5" color="sky" weight="normal">
                  후원자님 감사합니다.
                </Tit>
                <H4 weight="normal" color="sky">
                  후원자님이 보내주신 사랑 덕분에 <br />전 세계 수많은 어린이가
                  희망의 삶을 되찾았습니다.
                </H4>
                <p>
                  후원하시면서 궁금한 점이나 요청하실 사항 있으면 자유롭게
                  의견을 남겨주시기 바랍니다. <br />
                  남기신 문의에 대한 답변은 개인정보에 입력하신 이메일로
                  전송해드립니다.
                  <br />
                  <br />
                  일부 문의 사항의 경우 정확한 본인 확인을 위하여 전화를 드리고
                  있으니 양해해 주시기 바랍니다. 문의하신 순서에 따라 답변을
                  드리는 관계로 문의가 많을 경우 답변이 다소 지연될 수 있습니다.
                  가능한 한 최대한 빨리 답변을 드리겠습니다. <br />
                  유니세프를 후원해 주신 소중한 뜻을 기억해 어린이가 행복한
                  세상을 만드는 일에 최선의 노력을 다하겠습니다.
                </p>
              </ThanksTo>
            </Container>
            <FormFullContainer>
              <H3>문의 유형</H3>
              <QnaCategory>
                {Object.entries(ONE_TO_ONE_CATEGORIES).map(([value, label]) => (
                  <InputRadioButton
                    key={value}
                    css={`
                      display: ${label === `아우인형` ? `none` : ``};
                    `}
                  >
                    <InputRadio
                      ref={register({
                        required: {
                          value: true,
                          message: `필수 입력입니다.`,
                        },
                      })}
                      name="optData4"
                      label={label}
                      value={value}
                      tab
                    />
                  </InputRadioButton>
                ))}
              </QnaCategory>
              {errors.optData4 && (
                <ErrorMessage>{errors.optData4.message}</ErrorMessage>
              )}
            </FormFullContainer>
            {notice && (
              <FormFullContainer>
                {/* <ContainerGroup> */}
                <div>
                  <NoticeBox
                    css={`
                      position: relative;
                    `}
                  >
                    <div dangerouslySetInnerHTML={{ __html: notice.content }} />
                  </NoticeBox>
                </div>
                {/* </ContainerGroup> */}
              </FormFullContainer>
            )}

            <FormFullContainer>
              <H3>제목</H3>
              <InputTextLimit>
                <InputText
                  ref={register({
                    required: {
                      value: true,
                      message: `필수 입력입니다.`,
                    },
                  })}
                  name="subject"
                  placeholder="제목을 입력해주세요. (최소 5자 이상)"
                  maxLength="30"
                />
                <LimitCount>
                  <span>{subject.length}</span> / 30
                </LimitCount>
              </InputTextLimit>
              {errors.subject && (
                <ErrorMessage>{errors.subject.message}</ErrorMessage>
              )}
            </FormFullContainer>
            <FormFullContainer>
              <H3>문의 내용</H3>
              <InputTextLimit>
                <InputTextarea
                  ref={register({
                    required: {
                      value: true,
                      message: `필수 입력입니다.`,
                    },
                  })}
                  name="contents"
                  placeholder="문의 내용을 입력해주세요. (최소 10자 이상)"
                  maxLength="500"
                />
                <LimitCount type="textarea">
                  <span>{contents.length}</span> / 500
                </LimitCount>
              </InputTextLimit>
              {errors.contents && (
                <ErrorMessage>{errors.contents.message}</ErrorMessage>
              )}
              <NotiBox>
                <NotiList>
                  <NotiItem>
                    한 번 등록한 문의 내용은 수정이 불가능합니다.
                  </NotiItem>
                  <NotiItem>
                    접수된 내용은 빠른 시간 내에 답변을 드리도록 하겠습니다.
                  </NotiItem>
                </NotiList>
              </NotiBox>
            </FormFullContainer>
            <ButtonContainer>
              <Button outline>취소</Button>
              <Button type="submit" disabled={isSubmitting}>
                등록
              </Button>
            </ButtonContainer>
          </ContainerGroup>
        </SectionTop>
      </form>
    </LayoutWithTitle>
  );
});

export default OneToOneForm;
