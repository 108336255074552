import {
  FoBannerControllerService,
  FoBannerVo,
  myActiveHistoryVo,
  MySupportInfoControllerService,
  RegularPayInfoVo,
} from '@/__generated__/FrontApi';
import Button from '@/components/Button';
import CampaignCard, { CampaignData } from '@/components/Card/CampaignCard';
import Container from '@/components/Container';
import Col from '@/components/Grid/Col';
import Row from '@/components/Grid/Row';
import PayInfoChangeLayerPopup, {
  PayInfoChangeResult,
} from '@/components/Layer/PayInfoChangeLayerPopup';
import MyPayInformation from '@/components/Mypage/MyPayInformation';
import { RegularSupportHistoryList } from '@/components/Mypage/RegularSupportHistoryList';
import { H2 } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import GtmHelper from '@/helpers/GtmHelper';
import {
  PAYTYPE_REGULAR_AUTO_BANKING,
  PAYTYPE_REGULAR_CARD,
} from '@/helpers/PaymentHelper';
import { masking } from '@/helpers/StringHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import {
  ContainerGroup,
  ContentsArea,
  SectionTop,
  TitleArea,
  PayInfoList,
  PayInfoItem,
} from '@/page-blocks/mypage/MypageCommon';
import MypageRoutes from '@/page-blocks/mypage/MypageRoutes';
import { usePopupStore } from '@/stores/PopupStore';
import { useUserStore } from '@/stores/UserStore';
import { RouteComponentProps } from '@reach/router';
import { observer } from 'mobx-react-lite';
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';

const NoData = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  padding: 96px 16px;
  ${breakpoint(800)} {
    font-size: 16px;
  }
  ${breakpoint(640)} {
    font-size: 14px;
    padding: 72px 16px;
  }
`;

const SupportInfo: FC<RouteComponentProps> = observer(({ location }) => {
  const searchParams = useMemo(() => {
    if (location?.search) {
      const params = new URLSearchParams(location.search);
      return Object.fromEntries(params);
    }
  }, [location?.search]);
  const userStore = useUserStore();
  const popupStore = usePopupStore();
  const [isEditingPayInfo, setIsEditingPayInfo] = useState(false);
  const [items, setItems] = useState<myActiveHistoryVo[]>([]);
  const [payInfo, setPayInfo] = useState<RegularPayInfoVo>();
  // 계정정보 수정 결과
  const [result, setResult] = useState<PayInfoChangeResult>();

  const payment = useRef<null | HTMLDivElement>();

  const scrollToSectionDonate = () => {
    /**
     * 2022. 04. 22. jinsyu
     * 마이페이지 결제정보 영역 앵커 처리
     */

    if (payment && payment.current) {
      payment.current.scrollIntoView({ behavior: `smooth` });
    }
    // const y =
    //   document.querySelector(`.donate-container`).getBoundingClientRect().top +
    //   window.pageYOffset +
    //   -180;
    // if (isIE()) {
    //   document.querySelector(`.donate-container`)?.scrollIntoView({
    //     behavior: `smooth`,
    //   });
    // } else {
    //   window.scrollTo({ top: y, behavior: `smooth` });
    // }
  };
  useEffect(() => {
    scrollToSectionDonate();
  }, []);

  // 정기후원내역 로드
  const loadRegularItems = useCallback(async () => {
    const {
      data,
    } = await MySupportInfoControllerService.getMySupportInfoListUsingGet();
    setItems(data);
  }, []);

  useEffect(() => {
    loadRegularItems();
  }, [loadRegularItems]);

  // 결제정보 로드
  const loadPaymentInfo = useCallback(async () => {
    const {
      data,
    } = await MySupportInfoControllerService.getMyPayInfoUsingGet();
    setPayInfo(data);
  }, []);

  useEffect(() => {
    loadPaymentInfo();
  }, [loadPaymentInfo]);

  // 쿼리스트링으로 넘어온 결과값 처리
  useEffect(() => {
    if (searchParams?.resultCode === `success`) {
      setResult(searchParams);
    }
    // success 가 아니고, 메세지가 있다면 띄우기
    else if (searchParams?.resultMessage) {
      popupStore.show(searchParams.resultMessage);
    }
  }, [popupStore, searchParams]);

  useEffect(() => {
    // 정기후원 결제수단 변경결과 넘어오면
    if (result) {
      // GTM 이벤트 발생
      GtmHelper.editPayMethod({
        oldPayDay: result.oldPayDay,
        newPayDay: result.payDay,
        oldPayTypeCd: result.oldPayTypeCd,
        newPayTypeCd: result.payTypeCd,
      });
    }
  }, [result]);

  const [banners, setBanners] = useState<CampaignData[]>([]);

  const loadBanners = useCallback(async () => {
    try {
      const {
        data: mypageSupportInfoBanners,
      } = await FoBannerControllerService.selectBannerListUsingGet({
        kind: '5002',
      });
      setBanners(
        mypageSupportInfoBanners.map((banner: FoBannerVo) => ({
          id: banner.indexNumber,
          pcImage: banner.imgAttGrpNoPc,
          mobileImage: banner.imgAttGrpNoMobile,
          link: banner.targetUrl,
          linkTargetBlank: banner.targetBlankYn === `Y`,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadBanners();
  }, [loadBanners]);

  const MySupportBanners = styled.div`
    margin-top: 56px;
    margin-bottom: 60px;

    .bn-slide {
      .swiper-slide {
        width: 100%;

        .img-box {
          padding-top: 19.64%;
        }
      }
    }
  `;

  return (
    <LayoutWithTitle
      location={location}
      title="마이페이지"
      description="for every child, UNICEF"
    >
      <MypageRoutes currentKey={4} />
      <SectionTop>
        <ContainerGroup>
          <Container>
            <TitleArea>
              <H2>정기후원 정보</H2>
              <p>현재 유니세프에 후원하고 계신 정기후원 정보입니다.</p>
            </TitleArea>
            <ContentsArea
              css={`
                padding: 0;
              `}
            >
              {items.length ? (
                <RegularSupportHistoryList
                  items={items}
                  onItemChange={() => loadRegularItems()}
                />
              ) : (
                <NoData>후원내역이 없습니다.</NoData>
              )}
            </ContentsArea>
          </Container>

          <MySupportBanners>
            {banners.length > 0 && (
              <Container>
                <CampaignCard
                  campaigns={banners}
                  autoplay
                  overflowHidden
                  className="bn-slide"
                  img
                />
              </Container>
            )}
          </MySupportBanners>

          <Container ref={payment}>
            {payInfo?.existYn === `Y` ? (
              <>
                <TitleArea
                  css={`
                    position: relative;
                  `}
                >
                  <H2>결제정보</H2>
                  {!isEditingPayInfo && (
                    <Row>
                      <Col>
                        후원금의 결제정보 변경을 원하시면 [수정]버튼을
                        눌러주세요.
                      </Col>
                      <Button
                        size="sm"
                        color="gray"
                        outline
                        width="80"
                        onClick={() => {
                          if (payInfo?.isModifyOkYn === `Y`) {
                            setIsEditingPayInfo(true);
                            return;
                          }
                          popupStore.show(
                            `청구일에는 결제수단 변경이 불가합니다.\n(문의: 02-737-1004)`,
                          );
                        }}
                        css={`
                          ${breakpoint(800)} {
                            width: 80px;
                          }
                          ${breakpoint(640)} {
                            position: absolute;
                            right: 0;
                            top: 0;
                            padding: 7px;
                            font-size: 14px;
                          }
                        `}
                      >
                        수정
                      </Button>
                    </Row>
                  )}
                </TitleArea>
                <ContentsArea>
                  {isEditingPayInfo ? (
                    <MyPayInformation
                      supporterType={userStore.user?.donorType}
                      memberTypeCode={userStore.user?.donorTypeCode}
                      payInfo={payInfo}
                      onSuccess={(result) => {
                        setResult(result);
                        setIsEditingPayInfo(false);
                        loadPaymentInfo();
                      }}
                    />
                  ) : (
                    <PayInfoList>
                      <PayInfoItem>
                        <strong>결제수단</strong>
                        {payInfo?.paymentTypeName}
                      </PayInfoItem>
                      {payInfo?.paymentType === PAYTYPE_REGULAR_CARD && (
                        <>
                          <PayInfoItem>
                            <strong>카드 소유주 생년월일</strong>
                            {payInfo.cardJuminNumber &&
                              masking(payInfo.cardJuminNumber)}
                          </PayInfoItem>
                          <PayInfoItem>
                            <strong>카드번호</strong>
                            {payInfo.cardNumber &&
                              masking(payInfo.cardNumber, 3, 3)}
                          </PayInfoItem>
                        </>
                      )}
                      {payInfo?.paymentType ===
                        PAYTYPE_REGULAR_AUTO_BANKING && (
                        <>
                          {/* QA중 제거요청이 있어 반영함 21.08.05 */}
                          {/* <PayInfoItem>
                        <strong>계좌 소유주</strong>
                        {payInfo.accountType}
                      </PayInfoItem> */}
                          <PayInfoItem>
                            <strong>예금주명</strong>
                            {payInfo.accountOwnerName &&
                              masking(payInfo.accountOwnerName, 1)}
                          </PayInfoItem>
                          <PayInfoItem>
                            <strong>예금주 생년월일</strong>
                            {payInfo.accountJuminNumber &&
                              masking(payInfo.accountJuminNumber)}
                          </PayInfoItem>
                          <PayInfoItem>
                            <strong>계좌번호</strong>
                            {payInfo?.bankName} <br />
                            {payInfo?.accountNumber &&
                              masking(payInfo.accountNumber, 3, 3)}
                          </PayInfoItem>
                        </>
                      )}
                      <PayInfoItem>
                        <strong>결제일</strong>
                        {payInfo?.paymentDay}일
                      </PayInfoItem>
                    </PayInfoList>
                  )}
                </ContentsArea>
              </>
            ) : (
              <>
                <TitleArea>
                  <H2>결제정보</H2>
                  <p>
                    후원금의 결제정보 변경을 원하시면 [수정]버튼을 눌러주세요.
                  </p>
                </TitleArea>
                <ContentsArea>
                  <NoData>결제 정보가 없습니다.</NoData>
                </ContentsArea>
              </>
            )}
          </Container>
        </ContainerGroup>
      </SectionTop>
      {!!result && (
        <PayInfoChangeLayerPopup
          isOpen
          onRequestClose={() => setResult(undefined)}
          result={result}
        />
      )}
    </LayoutWithTitle>
  );
});

export default SupportInfo;
