export interface PaginateOptions {
  // 페이지당 아이템 수
  itemsPerPage: number;
  // 페이지 그룹 사이즈
  pageGroupSize: number;
  // 총 아이템 갯수
  totalItemCount: number;
  // base uri
  baseUri: string;
}

// 한 페이지의 페이지네이션 정보
export interface PageInfo {
  pageGroupSize: number;
  totalPageCount: number;
  currentPageGroup: number;
  currentPage: number;
  lastOfGroup: number;
  firstOfGroup: number;
  nextGroup: number;
  prevGroup: number;
  baseUri: string;
  itemsPerPage: number;
  totalItemCount: number;
  keyword?: string;
  category?: string;
  optData2?: string;
}

// 페이지의 context 타입
export interface PaginateContext {
  limit: number;
  skip: number;
  pageInfo: PageInfo;
}

export const paginate: (options: PaginateOptions) => PaginateContext[] = ({
  itemsPerPage = 10,
  pageGroupSize = 5,
  totalItemCount,
  baseUri,
}) => {
  // 전체 페이지 수
  const totalPageCount = Math.ceil(totalItemCount / itemsPerPage);
  return Array.from({ length: totalPageCount }).map((_, i) => {
    // 현재 페이지
    const currentPage = i + 1;
    // 현재 페이지 그룹 번호
    const currentPageGroup = Math.ceil(currentPage / pageGroupSize);
    // 페이지그룹내 마지막 페이지
    const lastOfGroup = Math.min(
      currentPageGroup * pageGroupSize,
      totalPageCount,
    );
    // 페이지그룹내 첫번째 페이지
    const firstOfGroup = Math.max(
      currentPageGroup * pageGroupSize - (pageGroupSize - 1),
      1,
    );
    const nextGroup = Math.min(lastOfGroup + 1, totalPageCount);
    const prevGroup = Math.max(firstOfGroup - 1, 1);

    return {
      limit: itemsPerPage,
      skip: i * itemsPerPage,
      pageInfo: {
        pageGroupSize,
        totalPageCount,
        currentPageGroup,
        currentPage,
        lastOfGroup,
        firstOfGroup,
        nextGroup,
        prevGroup,
        baseUri,
        itemsPerPage,
        totalItemCount,
      },
    };
  });
};

// 한페이지 페이지네이션 옵션
export interface CurrentPageInfoOptions extends PaginateOptions {
  // 현재 페이지
  currentPage: number;
  // 검색 키워드
  keyword?: string;
  // 해시태그
  category?: string;
  // faq 하위 카테고리
  optData2?: string;
}

/**
 * 현재페이지의 페이지네이션 정보 가져오기
 */
export const getCurrentPageInfo: (
  options: CurrentPageInfoOptions,
) => PageInfo = ({
  itemsPerPage = 10,
  pageGroupSize = 5,
  totalItemCount,
  baseUri,
  currentPage = 1,
  keyword = ``,
  category = ``,
  optData2 = ``,
}) => {
  // 전체 페이지 수
  const totalPageCount = Math.ceil(totalItemCount / itemsPerPage);
  // 현재 페이지 그룹 번호
  const currentPageGroup = Math.ceil(currentPage / pageGroupSize);
  // 페이지그룹내 마지막 페이지
  const lastOfGroup = Math.min(
    currentPageGroup * pageGroupSize,
    totalPageCount,
  );
  // 페이지그룹내 첫번째 페이지
  const firstOfGroup = Math.max(
    currentPageGroup * pageGroupSize - (pageGroupSize - 1),
    1,
  );
  const nextGroup = Math.min(lastOfGroup + 1, totalPageCount);
  const prevGroup = Math.max(firstOfGroup - 1, 1);

  const pageInfo = {
    pageGroupSize,
    totalPageCount,
    currentPageGroup,
    currentPage,
    lastOfGroup,
    firstOfGroup,
    nextGroup,
    prevGroup,
    baseUri,
    itemsPerPage,
    totalItemCount,
    keyword,
    category,
    optData2,
  };

  return pageInfo;
};
