import {
  myHistoryCodeVo,
  MySupportHistoryControllerService,
} from '@/__generated__/FrontApi';
import Button, { BtnClose } from '@/components/Button';
import { PopCcontainer, PopupTitle } from '@/components/Css/Pop';
import InputText from '@/components/Input/InputText';
import Modal from '@/components/Modal';
import SupportedGiftList, { Gift } from '@/components/Mypage/SupportedGiftList';
import SupportSearch from '@/components/Mypage/SupportSearch';
import { H3, H3_2, Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { PAYTYPE_ONCE_BANKING } from '@/helpers/PaymentHelper';
import {
  SUPPORT_CATEGORY_ONCE_IG,
  SUPPORT_CATEGORY_ONCE_MEMORY,
} from '@/helpers/SupportHelper';
import dayjs from 'dayjs';
import { navigate } from 'gatsby';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';

const Container = styled.div``;

const HistoryItem = styled.li`
  display: table;
  width: 100%;
  table-layout: fixed;
  padding: 32px 0;
  position: relative;
  border-bottom: 1px solid #b7b8ba;
  ${breakpoint(800)} {
    display: block;
    padding: 24px 0;
  }
  ${Button} {
    max-width: none;
    width: auto;
  }
`;

const InputTextCustom = styled.div<{ active?: boolean }>`
  border: 1px solid #e5e6e8;
  margin-top: -1px;
  ${({ active }) => {
    if (!active) {
      return `
            span {
              color: #2d2926 !important;
            }
          `;
    }
    return ``;
  }}
`;
const Tip = styled.p`
  color: #828385;
  font-size: 14px;
  font-weight: normal;
`;

const Month = styled.div``;
const Category = styled.div`
  min-height: 44px;
  line-height: 2;
  color: #828385;
  strong {
    color: #1cabe2;
    display: inline-block;
    margin-left: 40px;
    &.first {
      margin-left: 0;
    }
  }
  ${Button} {
    margin-left: 32px;
    ${breakpoint(640)} {
      display: block;
      margin-left: 90px;
      padding: 7px 20px;
      font-size: 14px;
    }
  }
  ${breakpoint(640)} {
    margin-top: 18px;
  }
`;
const PriceDetail = styled.div`
  margin-top: 18px;
  span {
    position: relative;
    padding-right: 16px;
    &.is-failed {
      color: #e2231a;
    }
    & + span {
      padding-left: 16px;
      &::before {
        content: '';
        display: block;
        width: 1px;
        height: 16px;
        background: #e5e6e8;
        position: absolute;
        left: 0;
        top: 5px;
      }
    }
  }
`;
const DueDateText = styled.div`
  color: #e2231a;
  margin-top: 16px;
  white-space: pre;
`;

const Cell = styled.div<{ type?: string; status?: string; price?: boolean }>`
  display: table-cell;
  ${InputTextCustom} {
    span {
      width: 10%;
      text-align: right;
      color: #b7b8ba;
    }
  }
  ${breakpoint(800)} {
    display: block;
    ${({ status }) => {
      if (status === `dark` || status === `light`) {
        return `
            position: absolute;
            right: 0;
            top: 24px;
            font-size: 16px;
            text-align: right;
            white-space: nowrap;
          `;
      }
      return ``;
    }}
    ${({ type }) => {
      if (type === `date`) {
        return `
        text-align: left;
        font-size: 16px;
        width: 100%;
          `;
      }
      if (type === `status`) {
        return `
            position: absolute;
            right: 0;
            top: 24px;
            font-size: 16px;
            text-align: right;
            white-space: nowrap;
            width: 100%;
          `;
      }
      return `padding-left: 0;`;
    }}
  }
  ${({ type }) => {
    if (type === `date` || type === `status`) {
      return `
            width: 15%;
            text-align: center;
            font-weight: bold;
            font-size: 20px;
            .sky {
              color: #1cabe2
            }
          `;
    }
    if (type === `reward`) {
      return `
        font-size: 16px;   
        width: 10%;   
        color: #828385;
      `;
    }
    if (type === `address`) {
      return `  
        width: 20%;
        text-align: right;   
        vertical-align: middle;
        ${Tip} {
          color: #828385 !important;
          margin-top: 16px;
        }
      `;
    }
    return `padding-left: 24px;`;
  }}
  ${({ price }) => {
    if (price) {
      return `
            width: 40%;
            padding-right: 16px;
            ${InputText} {
              border: none;
              width: 90%;
              display: inline-block;
            }
          `;
    }
    return ``;
  }}
  ${({ status }) => {
    if (status === `dark`) {
      return `
            color: #2d2926
          `;
    }
    if (status === `light`) {
      return `
            color: #828385;
          `;
    }
    return ``;
  }}
`;
const NoData = styled.div`
  text-align: center;
  border-top: 1px solid #e5e6e8;
  border-bottom: 1px solid #e5e6e8;
  padding: 96px 0;
  ${H3} {
    line-height: 1.7;
  }
  ${breakpoint(640)} {
    ${H3} {
      font-size: 14px;
      line-height: 28px;
    }
  }
`;

const FormTotal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 36px 0;
  border-top: 1px solid #e5e6e8;
`;
const MyMemoryData = styled.div`
  margin-top: 32px;
  li {
    position: relative;
    padding-left: 84px;
    .ellipsis {
      font-weight: bold;
      position: absolute;
      left: 0;
      top: 0;
    }
    & + li {
      margin-top: 24px;
    }
  }
`;
const BtmnFlex = styled.div`
  width: 100%;
  margin: 40px auto 0;
  padding-bottom: 40px;
  text-align: center;

  ${Button} {
    width: 160px;
    &.full {
      width: 100%;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
`;

interface myHistoryVo {
  /** 기념일 */
  anniDate?: string;

  /** 기념구분 */
  anniType?: string;

  /** 무통장입금조회번호 */
  apprNo?: string;

  /** 후원금액 */
  bill?: number;

  /** 후원명 */
  cdDescriptionDetail?: string;

  /** 현재 날짜 */
  currentDate?: string;

  /** 후원명 */
  description?: string;

  /** 후원상품명 */
  donName?: string;

  /** 후원날짜2 */
  giftAidDate?: string;

  /** 후원날짜 */
  giftDate?: string;

  /** 후원GiftID */
  giftId?: string;

  /** 결제유형 */
  giftType?: string;

  /** 무통장 결제유형 정보 */
  giftTypeByO?: string;

  /** 정기후원코드 */
  glCode?: string;

  /** 생명을 구하는 선물 상품목록 */
  igProduct?: any[];

  /** 후원한 월 */
  payMonth?: string;

  /** 후원상태값 */
  payStatus?: string;

  /** 결제타입 */
  paymentType?: string;

  /** 약정정보 */
  pledgePayment?: string;

  /** 후원 유형 타입 */
  recordType?: string;

  /** 후원코드 */
  solicitCode?: string;

  /** 사연스토리 */
  story?: string;

  /** 이니시스 승인번호 */
  tidInicis?: string;

  /** 기념기부 프로젝트 일련번호 */
  aprjtIdx?: string;
}

// 한 페이지당 보여줄 아이템 수
const pagePerCount = 10;

interface MyPageSupportHistoryTab1Props {}

const MyPageSupportHistoryTab1: FC<MyPageSupportHistoryTab1Props> = () => {
  const [startDate, setStartDate] = useState<dayjs.Dayjs>();
  const [endDate, setEndDate] = useState<dayjs.Dayjs>();
  const [selectedCode, setSelectedCode] = useState<string>();
  const [codes, setCodes] = useState<myHistoryCodeVo[]>([]);
  const [items, setItems] = useState<myHistoryVo[]>([]);
  // 생구선 팝업 데이터
  const [selectedIgItem, setSelectedIgItem] = useState<myHistoryVo>();
  // 기념기부 팝업 데이터
  const [selectedMemoryItem, setSelectedMemoryItem] = useState<myHistoryVo>();

  // 페이징 관련 변수
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const hasMore = useMemo(() => total > pagePerCount * page, [page, total]);

  // 선택된 history의 생구선 목록 데이터 매핑
  const selectedGifts = useMemo(
    () =>
      selectedIgItem?.igProduct?.map<Gift>((product) => ({
        id: product.productName,
        tit: product.productName,
        titEn: product.productNameEn,
        price: Number(product.productPrice),
        img: product.imgProductAttImgNo1_290,
        cnt: Number(product.productCount),
      })),
    [selectedIgItem?.igProduct],
  );

  // 선택된 history의 생구선 총합
  const selectedGiftAmount = useMemo(
    () =>
      selectedGifts?.reduce(
        (result, gift) => result + gift.price * gift.cnt,
        0,
      ),
    [selectedGifts],
  );

  // 후원코드목록 로드
  const loadCodes = useCallback(async () => {
    const {
      data,
    } = await MySupportHistoryControllerService.getMyCompleteCodeListUsingGet();
    setCodes(data);
  }, []);

  useEffect(() => {
    loadCodes();
  }, [loadCodes]);

  // 후원내역 로드
  const loadItems = useCallback(async () => {
    const {
      data,
      totalCount,
    } = await MySupportHistoryControllerService.getMyCompleteHistoryUsingGet({
      startDate: startDate?.format(`YYYY-MM-DD`),
      endDate: endDate?.format(`YYYY-MM-DD`),
      glCode: selectedCode,
      pagePerCount,
      page,
    });

    setItems((prevItems) => [...prevItems, ...data]);
    setTotal(totalCount);
  }, [endDate, page, selectedCode, startDate]);

  useEffect(() => {
    loadItems();
  }, [loadItems]);

  // 다음 페이지 로드
  const loadNextPage = useCallback(() => {
    setPage(page + 1);
  }, [page]);

  // 코드값 변할때 페이징 초기화
  const handleCodeChange = useCallback((code: string) => {
    setSelectedCode(code);
    setPage(1);
    setItems([]);
  }, []);

  // 코드값 변할때 페이징 초기화
  const handlePeriodChange = useCallback(
    (start: dayjs.Dayjs | undefined, end: dayjs.Dayjs | undefined) => {
      setStartDate(start);
      setEndDate(end);
      setPage(1);
      setItems([]);
    },
    [],
  );

  const url = 'test';

  return (
    <Container>
      <SupportSearch
        codes={codes}
        onCodeChange={handleCodeChange}
        onPeriodChange={handlePeriodChange}
      />
      {/* 목록 아이템 */}
      {items.length ? (
        <>
          {items.map((item) => (
            <HistoryItem key={item.giftId}>
              <Cell type="date">
                <Month>
                  {item?.giftAidDate
                    ? moment(item?.giftAidDate).format('YYYY-MM')
                    : '-'}
                </Month>
              </Cell>
              <Cell>
                <Category>
                  {item.pledgePayment === `Y` ? `정기후원` : `일시후원`}
                  {` `}
                  <strong>
                    {item.description}
                    {item.cdDescriptionDetail &&
                      ` [${item.cdDescriptionDetail}]`}
                  </strong>
                  {/* 생구선이고, 후원한 선물 데이터가 있다면, 보기 버튼 활성화 */}
                  {item.glCode === SUPPORT_CATEGORY_ONCE_IG &&
                    !!item.igProduct?.length && (
                      <Button
                        size="sm"
                        color="grey"
                        width={160}
                        onClick={() => setSelectedIgItem(item)}
                      >
                        후원한 선물
                      </Button>
                    )}
                  {/* 기념기부 */}
                  {item.glCode === SUPPORT_CATEGORY_ONCE_MEMORY && (
                    <Button
                      size="sm"
                      color="grey"
                      width={160}
                      onClick={() => setSelectedMemoryItem(item)}
                    >
                      사연보기
                    </Button>
                  )}
                </Category>
                <PriceDetail>
                  <span>{Number(item.bill).toLocaleString()}원</span>
                  <span>{item.giftType}</span>
                  <span>{item.giftDate}</span>
                </PriceDetail>
                {item.payType === PAYTYPE_ONCE_BANKING && (
                  <DueDateText>{item.giftTypeByO}</DueDateText>
                )}
              </Cell>
              <Cell
                type="status"
                status={item.payStatus?.includes(`완료`) ? `light` : `dark`}
              >
                {item.payStatus}
              </Cell>
            </HistoryItem>
          ))}
          {hasMore && (
            <ButtonContainer>
              <Button
                outline
                ico="moreArrow"
                width={352}
                onClick={() => {
                  loadNextPage();
                }}
              >
                <span className="ico">더 보기</span>
              </Button>
            </ButtonContainer>
          )}
        </>
      ) : (
        <NoData>
          <H3>후원내역이 없습니다.</H3>
        </NoData>
      )}
      {/* 생구선 팝업 */}
      {selectedIgItem && (
        <Modal
          onRequestClose={() => setSelectedIgItem(undefined)}
          maxWidth="712px"
        >
          <PopCcontainer>
            <PopupTitle>
              <h2>
                <Tit size="s3" color="sky">
                  생명을 구하는 선물 후원내역
                </Tit>
              </h2>
              <BtnClose onClick={() => setSelectedIgItem(undefined)}>
                <span className="a11y">닫기</span>
              </BtnClose>
            </PopupTitle>
            <H3_2
              css={`
                margin-top: 32px;
              `}
            >
              유니세프와 함께 지구촌 어린이들에게 그 무엇보다 기쁜 선물,
              <br />
              생명을 구하는 선물을 후원해주셔서 감사드립니다.
            </H3_2>
            {selectedGifts && <SupportedGiftList items={selectedGifts} />}
            <FormTotal>
              <Tit size="s3">총 후원금액</Tit>
              <Tit size="s2" color="sky">
                {selectedGiftAmount?.toLocaleString()}원({selectedGifts?.length}
                개)
              </Tit>
            </FormTotal>
            <BtmnFlex>
              <Button onClick={() => setSelectedIgItem(undefined)} size="sm">
                확인
              </Button>
            </BtmnFlex>
          </PopCcontainer>
        </Modal>
      )}
      {selectedMemoryItem && (
        <Modal
          onRequestClose={() => setSelectedMemoryItem(undefined)}
          maxWidth="712px"
        >
          <PopCcontainer>
            <PopupTitle>
              <h2>
                <Tit size="s3" color="sky">
                  나의 기념기부 사연 - {selectedMemoryItem.anniType}
                </Tit>
              </h2>
              <BtnClose onClick={() => setSelectedMemoryItem(undefined)}>
                <span className="a11y">닫기</span>
              </BtnClose>
            </PopupTitle>
            <MyMemoryData>
              <ul>
                <li>
                  <strong className="ellipsis">기념일</strong>
                  {dayjs(selectedMemoryItem.anniDate, `YYYYMMDD`).format(
                    `YYYY-MM-DD`,
                  )}
                </li>
                <li>
                  <span className="ellipsis">사연</span>
                  {selectedMemoryItem.story}
                </li>
              </ul>
            </MyMemoryData>
            <BtmnFlex>
              {selectedMemoryItem.aprjtIdx ? (
                <Button
                  className="full"
                  onClick={() =>
                    navigate(
                      `/involve/individual/together/?aid=${selectedMemoryItem.aprjtIdx}`,
                    )
                  }
                  size="sm"
                >
                  기념기부 바로가기
                </Button>
              ) : (
                <Button
                  onClick={() => setSelectedMemoryItem(undefined)}
                  size="sm"
                >
                  확인
                </Button>
              )}
            </BtmnFlex>
          </PopCcontainer>
        </Modal>
      )}
    </Container>
  );
};

export default MyPageSupportHistoryTab1;
