import { SupportModuleControllerService } from '@/__generated__/CommonApi';
import { MyPersonalInfoControllerService } from '@/__generated__/FrontApi';
import iconFacebook from '@/assets/img/join/ico_facebook@2x.png';
import iconGoogle from '@/assets/img/join/ico_google@2x.png';
import iconNaver from '@/assets/img/join/ico_naver@2x.png';
import Button from '@/components/Button';
import ContactUs from '@/components/ContactUs';
import Container from '@/components/Container';
import ErrorMessage from '@/components/Form/ErrorMessage';
import SuccessMessage from '@/components/Form/SuccessMessage';
import Col from '@/components/Grid/Col';
import Row from '@/components/Grid/Row';
import HR from '@/components/HR';
import InputRadio, { InputRadioGroup } from '@/components/Input/InputRadio';
import InputText from '@/components/Input/InputText';
import { NotiBox, NotiItem, NotiList } from '@/components/Text/TextNoti';
import NiceAuthenticationForm, {
  NiceAuthResult,
} from '@/components/ThirdParty/NiceAuthenticationForm';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { FormButton, RightCol } from '@/page-blocks/auth/AuthCommon';
import { SectionTop } from '@/page-blocks/mypage/MypageCommon';
import { usePopupStore } from '@/stores/PopupStore';
import { useUserStore } from '@/stores/UserStore';
import { navigate } from 'gatsby';
import { observer } from 'mobx-react-lite';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';

const FormRow = styled(Row)`
  margin: 50px 0;
  justify-content: flex-start;
  align-items: flex-start;
  ${breakpoint(640)} {
    display: block;
    margin: 24px 0;
  }
`;

const FormLeft = styled.div`
  width: 200px;
  font-weight: bold;
  ${breakpoint(640)} {
    width: 100%;
    margin-bottom: 8px;
  }
`;
const FormRight = styled(Col)`
  max-width: 544px;
  ${breakpoint(640)} {
    label {
      display: block;
    }
    label + label {
      margin-top: 8px;
    }
  }
`;

const Form = styled.form``;

const SnsLoginText = styled.div`
  position: relative;
  span {
    display: block;
    padding-left: 32px;
  }
  img {
    position: absolute;
    left: 0;
    width: 24px;
    height: 24px;
  }
`;

interface ConnectFormData {
  supportGubun: 'A' | 'B' | 'C';
  birthday: string;
  bizNumber: string;
  smsAuthCode: string;
}

const SupportHistoryConnect: FC = observer(({ location }) => {
  const popupStore = usePopupStore();
  const userStore = useUserStore();
  const [receivedAuthCode, setReceivedAuthCode] = useState(``);
  const [smsAuthed, setSmsAuthed] = useState(false);
  const [niceResult, setNiceResult] = useState<NiceAuthResult>();
  const {
    register,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<ConnectFormData>({
    defaultValues: {
      // 단체는 연결하기 로직이 없음. 기본은 개인.
      supportGubun: userStore.isBusinessUser() ? `B` : `A`,
    },
  });

  const smsAuthCode = watch(`smsAuthCode`);
  const supportGubun = watch(`supportGubun`);

  const onSubmit: SubmitHandler<ConnectFormData> = useCallback(
    async ({ bizNumber, ...formData }) => {
      try {
        const {
          resultCode,
          data,
        } = await SupportModuleControllerService.setConnectionByUserInfoUsingPost(
          {
            ...formData,
            birthOrBizNumber:
              formData.supportGubun === `A`
                ? niceResult?.birth.substring(2, 8)
                : bizNumber,
            mobilePhoneNumber:
              userStore.user?.mobilePhoneNumber &&
              `${userStore.user.mobilePhoneNumber}`.replace(/-/g, ``),
            name: userStore.user?.name,
          },
        );
        if (resultCode === `success`) {
          popupStore.show(
            data?.returnMessage || `후원내역 연결에 성공했습니다`,
            {
              onConfirm: () => {
                // 회원정보 리로딩 (jwt 토큰속 donorId 업데이트)
                userStore.refreshJwt().then(() => {
                  // 마이페이지 후원내역으로 이동
                  navigate(`/mypage/support-history`);
                });
              },
            },
          );
        }
      } catch (e) {
        if (e.response) {
          // 400 에러면
          if (e.response.status === 400) {
            navigate(`/mypage/support-history/connect-error`);
          } else if (e.response.status === 500) {
            popupStore.show(
              e.response.data.data.returnMessage || `서버 오류가 발생했습니다.`,
            );
          }
        }
      }

      // nice 인증결과 초기화 (일회용)
      setNiceResult(undefined);
    },
    [niceResult, popupStore, userStore],
  );

  // 휴대폰 인증번호 체크
  const checkSmsAuthCode = useCallback(() => {
    // 넘어온 코드와 일치하는지 확인
    if (smsAuthCode !== receivedAuthCode) {
      setError(`smsAuthCode`, {
        type: `required`,
        message: `인증번호가 일치하지 않습니다`,
        shouldFocus: true,
      });
      return;
    }

    clearErrors(`smsAuthCode`);
    setSmsAuthed(true);
  }, [clearErrors, receivedAuthCode, setError, smsAuthCode]);

  // 휴대폰 인증번호 발송
  const sendSmsAuthCode = useCallback(async () => {
    const {
      resultCode,
      data,
    } = await MyPersonalInfoControllerService.getSmsAuthKeyUsingGet();
    if (resultCode === `success`) {
      // 인증코드 저장
      setReceivedAuthCode(data.smsAuthKey);
    }
  }, []);

  // 후원내역 연결된 아이디면 뒤로가기
  useEffect(() => {
    if (userStore.isDonor()) {
      popupStore.show(`이미 후원내역이 연결된 아이디입니다`, {
        onConfirm: () => navigate(`/mypage/support-history`),
      });
    }
  }, [popupStore, userStore]);

  useEffect(() => {
    // nice 인증 성공시 폼 전송
    if (niceResult) {
      handleSubmit(onSubmit)();
    }
  }, [handleSubmit, niceResult, onSubmit]);

  return (
    <LayoutWithTitle location={location} title="후원내역 연결하기">
      <SectionTop>
        <Container>
          <NotiBox>
            <NotiList>
              <NotiItem>
                회원정보와 후원내역 연결을 하시면 후원정보와 후원내역을 간편하게
                조회하실 수 있습니다.
              </NotiItem>
              <NotiItem>
                최초 후원신청시 기입하신 정보(후원자명, 주민등록번호,
                휴대전화번호)와 본인 인증 정보가 일치하는 경우 후원내역을 연결
                하실 수 있습니다.
              </NotiItem>
              <NotiItem>
                개인정보는 철저한 보안시스템 하에 관리되고 있으며, 절대 외부로
                유출되지 않습니다.
              </NotiItem>
              <NotiItem>
                주민등록번호, 사업자번호 등 식별번호 없이 후원하신 경우
                후원본부로 연락 주시기 바랍니다.
              </NotiItem>
            </NotiList>
          </NotiBox>
        </Container>
        <Container>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormRow>
              <FormLeft>후원인 구분</FormLeft>
              <FormRight>
                <InputRadioGroup>
                  <InputRadio
                    ref={register}
                    name="supportGubun"
                    label="개인후원자"
                    value="A"
                    css={`
                      margin-right: 80px;
                    `}
                  />
                  <InputRadio
                    ref={register}
                    name="supportGubun"
                    label="사업자 또는 법인"
                    value="B"
                  />
                </InputRadioGroup>
              </FormRight>
            </FormRow>
            <FormRow>
              <FormLeft>웹가입 ID</FormLeft>
              <FormRight>
                {userStore.isSocial() ? (
                  <>
                    {userStore.user?.socialType === `N` && (
                      <SnsLoginText>
                        <img src={iconNaver} alt="네이버" />
                        <span>
                          네이버 아이디 {userStore.user.email} 로 로그인
                        </span>
                      </SnsLoginText>
                    )}
                    {userStore.user?.socialType === `F` && (
                      <SnsLoginText>
                        <img src={iconFacebook} alt="페이스북" />
                        <span>
                          페이스북 아이디 {userStore.user.email} 로 로그인
                        </span>
                      </SnsLoginText>
                    )}
                    {userStore.user?.socialType === `G` && (
                      <SnsLoginText>
                        <img src={iconGoogle} alt="구글" />
                        <span>
                          구글 아이디 {userStore.user.email} 로 로그인
                        </span>
                      </SnsLoginText>
                    )}
                  </>
                ) : (
                  <span>{userStore.user?.userId}</span>
                )}
              </FormRight>
            </FormRow>
            <FormRow>
              <FormLeft>후원자 명</FormLeft>
              <FormRight>{userStore.user?.name}</FormRight>
            </FormRow>
            {/* 기업의 경우 */}
            {supportGubun === `B` && (
              <FormRow>
                <FormLeft>사업자 번호</FormLeft>
                <FormRight>
                  <InputText
                    name="bizNumber"
                    ref={register({
                      required: {
                        value: true,
                        message: `필수 입력입니다`,
                      },
                    })}
                    placeholder="사업자번호 입력(&lsquo;-&rsquo;제외)"
                  />
                  {errors.bizNumber && (
                    <ErrorMessage>{errors.bizNumber.message}</ErrorMessage>
                  )}
                </FormRight>
              </FormRow>
            )}
            <FormRow>
              <FormLeft>휴대폰 번호</FormLeft>
              <FormRight>{userStore.user?.mobilePhoneNumber}</FormRight>
            </FormRow>
            {/* 기업의 경우 */}
            {supportGubun === `B` && (
              <FormRow>
                <FormLeft>인증 번호</FormLeft>
                <FormRight>
                  <Row>
                    <Col>
                      <InputText
                        name="smsAuthCode"
                        ref={register({
                          required: {
                            value: true,
                            message: `필수 입력입니다`,
                          },
                        })}
                        placeholder="인증번호 입력"
                        readOnly={smsAuthed}
                      />
                    </Col>
                    <RightCol>
                      {receivedAuthCode ? (
                        <>
                          {smsAuthed ? (
                            <FormButton color="grey" disabled>
                              확인
                            </FormButton>
                          ) : (
                            <FormButton onClick={checkSmsAuthCode}>
                              확인
                            </FormButton>
                          )}
                        </>
                      ) : (
                        <FormButton outline onClick={sendSmsAuthCode}>
                          인증번호 발송
                        </FormButton>
                      )}
                    </RightCol>
                  </Row>
                  {errors.smsAuthCode && (
                    <ErrorMessage>{errors.smsAuthCode.message}</ErrorMessage>
                  )}
                  {smsAuthed && (
                    <SuccessMessage>인증이 완료되었습니다.</SuccessMessage>
                  )}
                </FormRight>
              </FormRow>
            )}
            <HR color="#e5e6e8" />
            <Row
              justifyContent="center"
              css={`
                margin: 120px 0;
                ${breakpoint(640)} {
                  margin: 72px 0;
                }
              `}
            >
              {/* 개인이면 본인인증 */}
              {supportGubun === `A` ? (
                <NiceAuthenticationForm
                  onSuccess={(result) => {
                    setNiceResult(result);
                  }}
                  renderSubmitButton={(props) => (
                    <Button
                      {...props}
                      css={`
                        width: 544px;
                      `}
                    >
                      후원내역 연결하기
                    </Button>
                  )}
                />
              ) : (
                <Button
                  type="submit"
                  css={`
                    width: 544px;
                  `}
                >
                  후원내역 연결하기
                </Button>
              )}
            </Row>
          </Form>
          <ContactUs
            itemData={[
              {
                id: 1,
                tit: `후원내역 연결에 어려움이 있으신가요?`,
                desc: `가족 명의로 후원하고 계시거나 후원 내역 연결에 문제가 있으시다면 후원본부로 연락 주시기 바랍니다.`,
                costumer: `후원본부`,
                tel: `02-737-1004`,
                email: `donor@unicef.or.kr`,
              },
            ]}
          />
        </Container>
      </SectionTop>
    </LayoutWithTitle>
  );
});
export default SupportHistoryConnect;
