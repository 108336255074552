import { Tit } from './Titles';
import IconOff from '@/assets/img/ico_accordion_off@2x.png';
import IconOn from '@/assets/img/ico_accordion_on@2x.png';
import { breakpoint } from '@/helpers/BreakpointHelper';
import React, {
  ButtonHTMLAttributes,
  createContext,
  FC,
  HTMLAttributes,
  ReactElement,
  useCallback,
  useContext,
  useState,
} from 'react';
import styled from 'styled-components';

const AccordionContext = createContext<{
  activeKey: string | number | undefined;
  setActiveKey: (key: string | number) => void;
}>({
  activeKey: ``,
  setActiveKey: () => null,
});

const AccordionContainer = styled.ul`
  border-top: 1px solid #2d2926;
  border-bottom: 1px solid #e5e6e8;
  & > li {
    header {
      border-top: 1px solid #e5e6e8;
      margin-top: -1px;
    }

    .accordion-opener {
      &:not(.plain-item) {
        padding-right: 40px !important;

        ${Tit} {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 98%;
        }
      }
    }

    &.is-active {
      border-bottom: 1px solid #1cabe2;
      header {
        border-color: #1cabe2;
      }

      .accordion-opener {
        ${Tit} {
          white-space: normal;
          text-overflow: initial;
          word-break: break-all;
          overflow: visible;
        }
      }
    }
    &:first-child {
      header {
        border-top: none;
      }
      &.is-active {
        border-bottom: 1px solid #1cabe2;
        header {
          border-top: none;
        }
      }
    }
  }
  ${breakpoint(`tablet`)} {
    & > li {
      .accordion-opener {
        padding-left: 0 !important;
      }
    }
  }
`;
const AccordionList = styled.li<{ active: boolean }>`
  // border-top: 1px solid #e5e6e8;
  position: relative;
  // border-color: ${(props) => (props.active ? `#1cabe2` : `#e5e6e8`)};
  z-index: ${(props) => (props.active ? `1` : `0`)};
  color: ${(props) => props.active && `#1cabe2`};
  .dates {
    color: #828385;
  }
  .icons {
    color: ${(props) => (props.active ? `#1cabe2` : `#828385`)};
    border-color: ${(props) => (props.active ? `#1cabe2` : `#828385`)};
  }

  &.is-active {
    header::after {
      background: url(${IconOn}) no-repeat center center;
      background-size: 32px;
    }
  }
`;
const AccordionHeader = styled.header`
  position: relative;
  &::after {
    content: '';
    display: block;
    width: 32px;
    height: 32px;
    background: url(${IconOff}) no-repeat center center;
    background-size: 32px;
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translate(0, -50%);
    z-index: -1;
  }
  ${breakpoint(640)} {
    &::after {
      right: 0;
    }
  }
`;
const AccordionContent = styled.div<{ active: boolean }>`
  display: ${(props) => (props.active ? `block` : `none`)};
  word-break: break-all;
`;

interface AccordionItemProps extends HTMLAttributes<HTMLLIElement> {
  toggleKey: string | number;
  renderToggle: (onClick: ButtonHTMLAttributes<any>['onClick']) => ReactElement;
}

export const AccordionItem: FC<AccordionItemProps> = ({
  children,
  toggleKey,
  renderToggle,
  ...props
}) => {
  const { activeKey, setActiveKey } = useContext(AccordionContext);
  const onToggle = useCallback(
    () =>
      toggleKey === activeKey ? setActiveKey(``) : setActiveKey(toggleKey),
    [activeKey, setActiveKey, toggleKey],
  );

  return (
    <AccordionList
      {...props}
      active={activeKey === toggleKey}
      className={activeKey === toggleKey && `is-active`}
    >
      <AccordionHeader>{renderToggle(onToggle)}</AccordionHeader>
      <AccordionContent
        active={activeKey === toggleKey}
        className="accordion-content"
      >
        {children}
      </AccordionContent>
    </AccordionList>
  );
};

const Accordion: FC<HTMLAttributes<any> & { initialKey?: string | number }> = ({
  children,
  initialKey,
  ...props
}) => {
  const [activeKey, setActiveKey] = useState<string | number | undefined>(
    initialKey,
  );

  return (
    <AccordionContext.Provider
      value={{
        activeKey,
        setActiveKey,
      }}
    >
      <AccordionContainer {...props}>{children}</AccordionContainer>
    </AccordionContext.Provider>
  );
};

export default Accordion;
