import { PopCcontainer, PopupTitle } from '../Css/Pop';
import IcoArrow from '@/assets/img/ico_pagination_next@2x.png';
import Button, { BtnClose } from '@/components/Button';
import Modal, { ModalProps } from '@/components/Modal';
import { H3_2 } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { PAYMENT_TYPE_NAMES } from '@/helpers/PaymentHelper';
import { useUserStore } from '@/stores/UserStore';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import styled from 'styled-components';

const BtmnFlex = styled.ul`
  width: 160px;
  margin: 40px auto 0;
  padding-bottom: 40px;
`;
const PayInfoChangeContents = styled.div`
  margin-top: 64px;
  padding: 64px;
  background: #f8f9fa;
  ${breakpoint(640)} {
    padding: 40px 28px;
  }
  ${breakpoint(360)} {
    padding: 40px 12px;
  }
  li {
    position: relative;
    & + li {
      margin-top: 12px;
    }
    div {
      padding-left: 170px;
      ${breakpoint(640)} {
        padding-left: 90px;
      }
    }
    strong {
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  span {
    display: inline-block;
    position: relative;
  }
  .before {
    width: 130px;
    padding-right: 48px;
    color: #828385;
    ${breakpoint(640)} {
      width: auto;
      padding-right: 0;
    }
  }
  .after {
    padding-left: 72px;
    ${breakpoint(640)} {
      padding-left: 48px;
    }
    &::before {
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      background: url(${IcoArrow}) no-repeat center center;
      background-size: 100%;
      position: absolute;
      left: 0;
      top: 1px;
      ${breakpoint(640)} {
        left: 12px;
        top: -1px;
      }
    }
  }
`;

export interface PayInfoChangeResult {
  oldPayDay: string;
  oldPayTypeCd: string;
  payDay: string;
  payTypeCd: string;
}

interface PayInfoChangeLayerPopupProps extends ModalProps {
  result: PayInfoChangeResult;
}

const PayInfoChangeLayerPopup: FC<PayInfoChangeLayerPopupProps> = observer(
  ({ onRequestClose, result, ...props }) => {
    const userStore = useUserStore();

    return (
      <Modal onRequestClose={onRequestClose} maxWidth="640px" {...props}>
        <PopCcontainer>
          <PopupTitle
            css={`
              border-bottom: 0;
            `}
          >
            <BtnClose
              onClick={() => onRequestClose()}
              css={`
                margin-top: 16px;
              `}
            >
              <span className="a11y">닫기</span>
            </BtnClose>
          </PopupTitle>
          <H3_2
            css={`
              padding-top: 70px;
              text-align: center;
            `}
          >
            {userStore.user?.name} 후원자님 <br />
            결제정보가 다음과 같이 변경되었습니다.
          </H3_2>
          <PayInfoChangeContents>
            <ul>
              <li>
                <strong>후원방법</strong>
                <div>
                  <span className="before">
                    {PAYMENT_TYPE_NAMES[result.oldPayTypeCd]}
                  </span>
                  <span className="after">
                    {PAYMENT_TYPE_NAMES[result.payTypeCd]}
                  </span>
                </div>
              </li>
              <li>
                <strong>결제일</strong>
                <div>
                  <span className="before">매월 {result.oldPayDay}일</span>
                  <span className="after">매월 {result.payDay}일</span>
                </div>
              </li>
            </ul>
          </PayInfoChangeContents>
          <BtmnFlex>
            <Button full onClick={() => onRequestClose()} size="sm">
              확인
            </Button>
          </BtmnFlex>
        </PopCcontainer>
      </Modal>
    );
  },
);
export default PayInfoChangeLayerPopup;
