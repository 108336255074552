import Button from '../Button';
import ErrorMessage from '../Form/ErrorMessage';
import FormGroup, { FormItem } from '../Form/FormGroup';
import FormTextTip from '../Form/FormTextTip';
import Signature from '../Form/Signature';
import Col from '../Grid/Col';
import Row from '../Grid/Row';
import InputGroupCard, {
  InputGroupCardDash,
  InputGroupCardSlash,
} from '../Input/InputGroupCard';
import InputNumber from '../Input/InputNumber';
import InputRadio, { InputRadioGroup } from '../Input/InputRadio';
import InputText from '../Input/InputText';
import { PayInfoChangeResult } from '../Layer/PayInfoChangeLayerPopup';
import Select from '../Select';
import {
  NotiBox,
  NotiParagraph,
  NotiTitle,
  NotiItem,
  NotiList,
} from '../Text/TextNoti';
import InicisAccountCheck from '../ThirdParty/InicisAccountCheck';
import NaverPayForm, { NaverPayRef } from '../ThirdParty/NaverPayForm';
import {
  MySupportInfoControllerService,
  RegularPayInfoVo,
} from '@/__generated__/FrontApi';
import IconArs from '@/assets/img/ico_ars@2x.png';
import LabelText from '@/components/Form/LabelText';
import { H4 } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import {
  PAYTYPE_REGULAR_AUTO_BANKING,
  PAYTYPE_REGULAR_CARD,
  PAYTYPE_REGULAR_NAVERPAY,
  REGCD_COMPLETE,
  REGCD_MODIFY,
  REGCD_NEW,
} from '@/helpers/PaymentHelper';
import {
  ENUM_SUPPORTER_TYPE,
  MEMBER_TYPES,
  TYPE_MEMBER_TYPES,
} from '@/helpers/SupportHelper';
import { FormButton, FormContainer } from '@/page-blocks/auth/AuthCommon';
import { DonateFormItem, LabelFlex } from '@/page-blocks/donate/DonateCommon';
import LocalStorage from '@/services/LocalStorage';
import { usePopupStore } from '@/stores/PopupStore';
import { useUserStore } from '@/stores/UserStore';
import axios from 'axios';
import dayjs from 'dayjs';
import { graphql, useStaticQuery } from 'gatsby';
import { observer } from 'mobx-react-lite';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { bizIdRegex } from '@/helpers/ValidationHelper';

const H4Title = styled(H4)`
  font-size: 20px;
  padding-bottom: 16px;
`;
const DirectDebit = styled.div`
  dl {
    margin-bottom: 19px;

    &:last-child {
      margin-bottom: 0;
    }
    dt {
      margin-bottom: 13px;
    }
  }
`;

interface MyPayInformationProps {
  payInfo: RegularPayInfoVo | undefined;
  supporterType: 'person' | 'group';
  memberTypeCode: keyof TYPE_MEMBER_TYPES;
  onSuccess: (result: PayInfoChangeResult) => void;
}

interface PayInfoFormData {
  paymentType: string;
  accountType: '본인' | '타인';
  bankCode: string;
  accountNumber: string;
  accountOwnerName: string;
  accountJuminNumber: string;
  accountOwnerPhone: string;
  relation: string;
  paymentDay: '05' | '15' | '25' | 'end';
  businessNumber: string;
  acsType: 'ARS' | 'sign';
  // 전자서명 바이너리
  signBinary: string;
  // 신용카드 정보
  cardNumber1: string;
  cardNumber2: string;
  cardNumber3: string;
  cardNumber4: string;
  cardExpireM: string;
  cardExpireY: string;
  cardJuminNumber: string;
}

type SubmitParams = Parameters<
  typeof MySupportInfoControllerService.modifyAccountProcessUsingPost
>[0];

const MyPayInformation: FC<MyPayInformationProps> = observer(
  ({ supporterType, memberTypeCode, payInfo, onSuccess }) => {
    const popupStore = usePopupStore();
    const userStore = useUserStore();
    const naverRef = useRef<NaverPayRef>(null);
    const {
      register,
      watch,
      setValue,
      control,
      formState: { errors, isSubmitting },
      handleSubmit,
      getValues,
      reset,
      trigger,
    } = useForm<PayInfoFormData>({
      defaultValues: {
        signBinary: ``,
        cardNumber1: ``,
        cardNumber2: ``,
        cardNumber3: ``,
        cardNumber4: ``,
        cardExpireM: ``,
        cardExpireY: ``,
      },
    });
    const {
      allBank: { nodes: banks },
    } = useStaticQuery(graphql`
      query {
        allBank {
          nodes {
            code
            description
          }
        }
      }
    `);

    const [isValidBankAccount, setIsValidBankAccount] = useState(false);

    const paymentType = watch(`paymentType`);
    const accountType = watch(`accountType`);
    const acsType = watch(`acsType`);

    const cardNumberError =
      errors.cardNumber1 ||
      errors.cardNumber2 ||
      errors.cardNumber3 ||
      errors.cardNumber4;

    const cardExpireError = errors.cardExpireM || errors.cardExpireY;

    const onSubmit: SubmitHandler<PayInfoFormData> = useCallback(
      async (formData) => {
        if (!userStore.user) {
          console.error(`사용자정보 없음`);
          return;
        }

        if (
          formData.paymentType === PAYTYPE_REGULAR_CARD &&
          !userStore.user.email
        ) {
          popupStore.show(
            `개인정보 메뉴에서 이메일주소를 등록 후 시도해 주세요.`,
          );
          return;
        }

        const params: SubmitParams = {
          accountName: formData.accountOwnerName,
          accountNo: formData.accountNumber,
          accountContact: formData.accountOwnerPhone,
          relation: formData.relation,
          acsType: formData.acsType,
          bankCode: formData.bankCode,
          birthday:
            formData.paymentType === PAYTYPE_REGULAR_CARD
              ? formData.cardJuminNumber
              : formData.accountJuminNumber,
          businessNumber: formData.businessNumber,
          cardNo:
            formData.paymentType === PAYTYPE_REGULAR_CARD
              ? formData.cardNumber1 +
                formData.cardNumber2 +
                formData.cardNumber3 +
                formData.cardNumber4
              : undefined,
          validDate:
            formData.paymentType === PAYTYPE_REGULAR_CARD
              ? formData.cardExpireY + formData.cardExpireM
              : undefined,
          signBinary: formData.signBinary,
          // 필수정보
          payDay: formData.paymentDay,
          payTypeCd: formData.paymentType,
          // 기타 정보
          donorId: userStore.user.donorId,
        };

        const finalFormData = new FormData();
        Object.keys(params).forEach(
          (key) =>
            params[key] !== undefined && finalFormData.append(key, params[key]),
        );

        try {
          const {
            data: { resultCode, resultMessage, data },
          } = await axios.post(
            `${process.env.BACKEND_URL}/unicef/api/fo/my/support-info/pay/modify`,
            finalFormData,
            {
              headers: {
                'X-AUTH-TOKEN': LocalStorage.getToken(),
              },
            },
          );

          if (resultCode === `success`) {
            // 네이버 페이의 경우
            if (formData.paymentType === PAYTYPE_REGULAR_NAVERPAY) {
              if (!data.reserveId) {
                popupStore.show(`네이버페이 주요정보가 넘어오지 않았습니다`);
                return;
              }
              naverRef.current?.payRecurrent(data);
              return;
            }
            // 나머지는 바로 완료
            onSuccess(data);
          } else {
            popupStore.show(resultMessage);
          }
        } catch (e) {
          if (e.response) {
            popupStore.show(
              e.response.data.resultMessage || `오류가 발생했습니다.`,
            );
          }
        }
      },
      [onSuccess, popupStore, userStore.user],
    );

    // 자동이체 & 개인/단체 선택에 따라 기본값 세팅
    useEffect(() => {
      if (paymentType === PAYTYPE_REGULAR_AUTO_BANKING) {
        if (accountType === `본인`) {
          setValue(`accountOwnerName`, userStore.user?.name);
          setValue(`accountJuminNumber`, userStore.getBirthday());
          setValue(`acsType`, `sign`);
          // 계좌인증상태 초기화
          setIsValidBankAccount(false);
        }
        if (accountType === `타인`) {
          setValue(`acsType`, `ARS`);
          // 계좌인증상태 초기화
          setIsValidBankAccount(false);
        }
      }
    }, [accountType, paymentType, setValue, userStore]);

    // 정기후원정보 있다면 세팅
    useEffect(() => {
      if (!payInfo) {
        return;
      }
      const defaultData = {
        cardNumber1: ``,
        cardNumber2: ``,
        cardNumber3: ``,
        cardNumber4: ``,
        cardExpireM: ``,
        cardExpireY: ``,
        signBinary: ``,
        paymentType: payInfo.paymentType,
        paymentDay: payInfo.paymentDay,
        acsType: payInfo?.acsType === `ARS` ? `ARS` : `sign`,
      };
      if (payInfo.paymentType === PAYTYPE_REGULAR_AUTO_BANKING) {
        reset({
          ...defaultData,
          accountType: payInfo.accountType === `타인` ? `타인` : `본인`,
          accountOwnerName: payInfo.accountOwnerName,
          accountJuminNumber: payInfo.accountJuminNumber,
          relation: payInfo.relation,
          bankCode: payInfo.bankCode,
          accountNumber: payInfo.accountNumber,
          accountOwnerPhone: payInfo.accountOwnerPhone,
          acsType: payInfo.accountType === `타인` ? `ARS` : defaultData.acsType,
        });
        // 신규,변경,등록완료 상태의 계좌는 인증된것으로 간주
        if ([REGCD_NEW, REGCD_MODIFY, REGCD_COMPLETE].includes(payInfo.regCd)) {
          setIsValidBankAccount(true);
        }
      } else if (payInfo.paymentType === PAYTYPE_REGULAR_CARD) {
        reset({
          ...defaultData,
          cardNumber1: payInfo.cardNumber?.slice(0, 4),
          cardNumber2: payInfo.cardNumber?.slice(4, 8),
          cardNumber3: payInfo.cardNumber?.slice(8, 12),
          cardNumber4: payInfo.cardNumber?.slice(12, payInfo.cardNumber.length),
          cardExpireM: payInfo.validMonth,
          cardExpireY: payInfo.validYear,
          cardJuminNumber: payInfo.cardJuminNumber,
        });
      } else if (payInfo.paymentType === PAYTYPE_REGULAR_NAVERPAY) {
        reset(defaultData);
      }
    }, [payInfo, reset, setValue]);

    return (
      <>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormContainer
            css={`
              ${breakpoint(640)} {
                padding: 0;
              }
            `}
          >
            <FormItem>
              <FormGroup noMargin>
                <H4Title>결제수단</H4Title>
                <InputRadioGroup full>
                  <InputRadio
                    ref={register({
                      required: {
                        value: true,
                        message: `필수 입력입니다`,
                      },
                    })}
                    name="paymentType"
                    tab
                    value={PAYTYPE_REGULAR_AUTO_BANKING}
                    label="자동이체"
                    defaultChecked
                  />
                  <InputRadio
                    ref={register}
                    name="paymentType"
                    tab
                    value={PAYTYPE_REGULAR_CARD}
                    label="신용카드"
                  />
                  <InputRadio
                    ref={register}
                    name="paymentType"
                    tab
                    value={PAYTYPE_REGULAR_NAVERPAY}
                    label="네이버페이"
                  />
                </InputRadioGroup>
              </FormGroup>
            </FormItem>
            {paymentType === PAYTYPE_REGULAR_AUTO_BANKING && (
              <>
                <DonateFormItem>
                  <FormGroup>
                    <H4>자동이체 계좌 정보</H4>
                    {/* 개인 선택의 경우 */}
                    {supporterType === `person` && (
                      <InputRadioGroup full>
                        <InputRadio
                          name="accountType"
                          ref={register}
                          tab
                          value="본인"
                          label="본인"
                        />
                        <InputRadio
                          name="accountType"
                          ref={register}
                          tab
                          value="타인"
                          label="타인"
                          onClick={() => {
                            setValue(`accountOwnerName`, ``);
                            setValue(`accountJuminNumber`, ``);
                          }}
                        />
                      </InputRadioGroup>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <LabelText require>예금주명</LabelText>
                    <InputText
                      name="accountOwnerName"
                      ref={register({
                        required: {
                          value: true,
                          message: `필수 입력입니다`,
                        },
                      })}
                      placeholder="예금주명 입력"
                      readOnly={accountType === `본인` || isValidBankAccount}
                    />
                    {errors.accountOwnerName && (
                      <ErrorMessage>
                        {errors.accountOwnerName.message}
                      </ErrorMessage>
                    )}
                  </FormGroup>
                  {/* 개인의 경우 */}
                  {supporterType === ENUM_SUPPORTER_TYPE.person ? (
                    <>
                      {accountType === `타인` && (
                        <FormGroup>
                          <LabelText require>예금주와의 관계</LabelText>
                          <Select
                            name="relation"
                            ref={register({
                              required: {
                                value: true,
                                message: `필수 입력입니다`,
                              },
                            })}
                          >
                            <option value="">관계 선택</option>
                            <option>부모</option>
                            <option>친척</option>
                            <option>지인</option>
                            <option>선생님</option>
                            <option>기타</option>
                          </Select>
                        </FormGroup>
                      )}
                      <FormGroup>
                        <LabelText require>예금주 생년월일</LabelText>
                        <InputText
                          name="accountJuminNumber"
                          ref={register({
                            required: {
                              value: true,
                              message: `필수 입력입니다`,
                            },
                            validate: (value) =>
                              dayjs(value, `YYMMDD`, true).isValid() ||
                              `올바르지 않은 형식입니다`,
                          })}
                          placeholder="예금주 생년월일 6자리 입력(YYMMDD)"
                          readOnly={accountType === `본인`}
                        />
                        {errors.accountJuminNumber && (
                          <ErrorMessage>
                            {errors.accountJuminNumber.message}
                          </ErrorMessage>
                        )}
                      </FormGroup>
                      {accountType === `타인` && (
                        <FormGroup>
                          <LabelText require>예금주 휴대폰번호</LabelText>
                          <InputText
                            name="accountOwnerPhone"
                            ref={register({
                              required: {
                                value: true,
                                message: `필수 입력입니다`,
                              },
                            })}
                            placeholder="예금주 휴대폰번호 입력 ( &lsquo;-&rsquo; 제외 )"
                          />
                          {errors.accountOwnerPhone && (
                            <ErrorMessage>
                              {errors.accountOwnerPhone.message}
                            </ErrorMessage>
                          )}
                        </FormGroup>
                      )}
                    </>
                  ) : (
                    <>
                      {/* 법인/단체 경우 */}
                      {memberTypeCode &&
                      Object.keys(
                        MEMBER_TYPES[ENUM_SUPPORTER_TYPE.corporation],
                      ).includes(memberTypeCode) ? (
                        // 법인의 경우
                        <FormGroup>
                          <LabelText require>사업자번호</LabelText>
                          <InputText
                            name="businessNumber"
                            ref={register({
                              required: {
                                value: true,
                                message: `필수 입력입니다`,
                              },
                              pattern: {
                                value: bizIdRegex,
                                message: `올바른 사업자등록번호가 아닙니다. (숫자 10자리)`,
                              },
                            })}
                            placeholder="사업자번호 입력(&lsquo;-&rsquo;제외)"
                          />
                          {errors.businessNumber && (
                            <ErrorMessage>
                              {errors.businessNumber.message}
                            </ErrorMessage>
                          )}
                        </FormGroup>
                      ) : (
                        // 단체의 경우
                        <FormGroup>
                          <LabelText require>예금주 생년월일</LabelText>
                          <InputText
                            name="accountJuminNumber"
                            ref={register({
                              required: {
                                value: true,
                                message: `필수 입력입니다`,
                              },
                              validate: (value) =>
                                dayjs(value, `YYMMDD`, true).isValid() ||
                                `올바르지 않은 형식입니다`,
                            })}
                            placeholder="예금주 생년월일 6자리 입력(YYMMDD)"
                          />
                          {errors.accountJuminNumber && (
                            <ErrorMessage>
                              {errors.accountJuminNumber.message}
                            </ErrorMessage>
                          )}
                        </FormGroup>
                      )}
                    </>
                  )}
                  <FormGroup>
                    <LabelText require>계좌번호</LabelText>
                    <Select
                      name="bankCode"
                      ref={register({
                        required: {
                          value: true,
                          message: `필수 입력입니다`,
                        },
                      })}
                      readOnly={isValidBankAccount}
                    >
                      <option value="">은행 선택</option>
                      {banks.map((bank) => (
                        <option key={bank.code} value={bank.code}>
                          {bank.description}
                        </option>
                      ))}
                    </Select>
                    {errors.bankCode && (
                      <ErrorMessage>{errors.bankCode.message}</ErrorMessage>
                    )}
                    <Row
                      className="certify"
                      css={`
                        margin-top: 8px;
                      `}
                    >
                      <div className="col-right">
                        <Controller
                          control={control}
                          name="accountNumber"
                          rules={{
                            required: {
                              value: true,
                              message: `필수 입력입니다`,
                            },
                            validate: () =>
                              isValidBankAccount || `계좌인증은 필수입니다`,
                          }}
                          as={
                            <InputNumber
                              placeholder="계좌번호 입력(&lsquo;-&rsquo; 제외)"
                              readOnly={isValidBankAccount}
                              allowNegative={false}
                              allowLeadingZeros
                            />
                          }
                        />
                      </div>
                      <div className="col-left">
                        {isValidBankAccount ? (
                          <FormButton color="grey" disabled>
                            인증완료
                          </FormButton>
                        ) : (
                          <InicisAccountCheck
                            getParams={() => ({
                              name: getValues(`accountOwnerName`),
                              account: getValues(`accountNumber`),
                              bankCode: getValues(`bankCode`),
                            })}
                            renderSubmitButton={(buttonProps) => (
                              <FormButton outline {...buttonProps}>
                                계좌인증
                              </FormButton>
                            )}
                            onSuccess={() => {
                              setIsValidBankAccount(true);
                              trigger(`accountNumber`);
                            }}
                            onError={(message) => popupStore.show(message)}
                          />
                        )}
                      </div>
                    </Row>
                    {errors.accountNumber && (
                      <ErrorMessage>
                        {errors.accountNumber.message}
                      </ErrorMessage>
                    )}
                    <Row
                      className="certify"
                      css={`
                        margin-top: 8px;
                        align-items: center;
                      `}
                    >
                      <div className="col-right">
                        <FormTextTip>
                          <strong>
                            가상 계좌, 미성년자 명의 계좌, 기업은행 휴대폰
                            계좌는 사용하실 수 없습니다.
                          </strong>
                        </FormTextTip>
                      </div>
                    </Row>
                  </FormGroup>
                </DonateFormItem>
                <DonateFormItem>
                  <FormGroup noMargin>
                    <H4>결제일</H4>
                    <InputRadioGroup full>
                      <InputRadio
                        name="paymentDay"
                        ref={register({
                          required: {
                            value: true,
                            message: `필수 입력입니다`,
                          },
                        })}
                        value="05"
                        label="05일"
                      />
                      <InputRadio
                        name="paymentDay"
                        ref={register}
                        value="15"
                        label="15일"
                      />
                      <InputRadio
                        name="paymentDay"
                        ref={register}
                        value="25"
                        label="25일"
                      />
                      <InputRadio
                        name="paymentDay"
                        ref={register}
                        value="30"
                        label="말일"
                      />
                    </InputRadioGroup>
                    {errors.paymentDay && (
                      <ErrorMessage>{errors.paymentDay.message}</ErrorMessage>
                    )}
                  </FormGroup>
                </DonateFormItem>
                <DonateFormItem noBorder>
                  <FormGroup>
                    <H4>자동이체 출금 동의</H4>
                    <DirectDebit>
                      {accountType === `본인` && (
                        <dl>
                          <dt>
                            <InputRadio
                              name="acsType"
                              ref={register({
                                required: {
                                  value: true,
                                  message: `필수 입력입니다`,
                                },
                              })}
                              value="sign"
                              label="전자서명"
                            />
                          </dt>
                          {acsType === `sign` && (
                            <dd>
                              <Controller
                                control={control}
                                name="signBinary"
                                rules={{
                                  required: {
                                    value: true,
                                    message: `필수 입력입니다`,
                                  },
                                }}
                                render={(renderProps) => (
                                  <Signature {...renderProps} />
                                )}
                              />
                              {errors.signBinary && (
                                <ErrorMessage>
                                  {errors.signBinary.message}
                                </ErrorMessage>
                              )}
                            </dd>
                          )}
                        </dl>
                      )}
                      <dl>
                        <dt>
                          <InputRadio
                            name="acsType"
                            ref={register({
                              required: {
                                value: true,
                                message: `필수 입력입니다`,
                              },
                            })}
                            value="ARS"
                            label="ARS"
                          />
                        </dt>
                        {acsType === `ARS` && (
                          <dd>
                            <NotiBox>
                              <NotiParagraph>
                                금융결제원의 방침에 따라 개인정보 보호를 위해
                                잠시 후 ARS전화로 자동이체 출금동의를
                                진행합니다.
                              </NotiParagraph>
                              <NotiParagraph>
                                수신거부 또는 입력하신 휴대폰번호가 다를 경우
                                출금동의여부 확인이 어려우니 아래 번호의 전화를
                                꼭 수신해 주시기 바랍니다.
                              </NotiParagraph>
                              <a
                                href="tel:027238215"
                                css={`
                                  font-size: 24px;
                                  font-weight: bold;
                                  line-height: 1.58;
                                  letter-spacing: -1.2px;
                                  margin-top: 24px;
                                  display: block;
                                  color: #1cabe2;
                                `}
                              >
                                <img
                                  src={IconArs}
                                  alt="ARS 아이콘"
                                  css={`
                                    display: inline-block;
                                    width: 40px;
                                    margin-right: 16px;
                                    vertical-align: top;
                                  `}
                                />
                                02 - 723 - 8215
                              </a>
                            </NotiBox>
                          </dd>
                        )}
                      </dl>
                    </DirectDebit>
                    {errors.acsType && (
                      <ErrorMessage>{errors.acsType.message}</ErrorMessage>
                    )}
                  </FormGroup>
                  <NotiBox>
                    <NotiTitle>자동이체 후원 시 유의사항</NotiTitle>
                    <NotiParagraph>
                      <NotiItem>
                        자동이체의 경우, 금융결제원을 통해 은행에 접수하는
                        과정을 거쳐야 합니다. 신청하신 약정일로부터 영업일(평일)
                        기준 5일 전에 신청하신 경우에만 해당 월에 후원이
                        가능합니다.
                      </NotiItem>
                      <NotiItem>
                        매월 선택하신 날짜(평일 기준)에 약정하신 금액이 자동
                        출금됩니다. 그러나 해당 일에 후원금이 출금되지 않은
                        경우, 1회에 한해 다음 결제일(5일→15일, 15일→25일,
                        25일→말일, 말일→익월 5일)에 재출금이 진행됩니다.
                      </NotiItem>
                    </NotiParagraph>
                  </NotiBox>
                </DonateFormItem>
              </>
            )}
            {paymentType === PAYTYPE_REGULAR_CARD && (
              <>
                <DonateFormItem>
                  <H4>카드번호</H4>
                  <Col
                    css={`
                      margin-top: 8px;
                    `}
                  >
                    <InputGroupCard>
                      <Controller
                        control={control}
                        name="cardNumber1"
                        rules={{
                          required: {
                            value: true,
                            message: `필수 입력입니다`,
                          },
                        }}
                        as={<InputNumber format="####" placeholder="1234" />}
                      />
                      <InputGroupCardDash />
                      <Controller
                        control={control}
                        name="cardNumber2"
                        rules={{
                          required: {
                            value: true,
                            message: `필수 입력입니다`,
                          },
                        }}
                        as={<InputNumber format="####" placeholder="0000" />}
                      />
                      <InputGroupCardDash />
                      <Controller
                        control={control}
                        name="cardNumber3"
                        rules={{
                          required: {
                            value: true,
                            message: `필수 입력입니다`,
                          },
                        }}
                        as={<InputNumber format="####" placeholder="0000" />}
                      />
                      <InputGroupCardDash />
                      <Controller
                        control={control}
                        name="cardNumber4"
                        rules={{
                          required: {
                            value: true,
                            message: `필수 입력입니다`,
                          },
                        }}
                        as={<InputNumber format="####" placeholder="0000" />}
                      />
                    </InputGroupCard>
                  </Col>
                  {cardNumberError && (
                    <ErrorMessage>{cardNumberError.message}</ErrorMessage>
                  )}
                </DonateFormItem>
                <DonateFormItem>
                  <H4>유효기간</H4>
                  <InputGroupCard>
                    <Controller
                      control={control}
                      name="cardExpireM"
                      rules={{
                        required: {
                          value: true,
                          message: `필수 입력입니다`,
                        },
                      }}
                      as={<InputNumber format="##" placeholder="MM" />}
                    />
                    <InputGroupCardSlash />
                    <Controller
                      control={control}
                      name="cardExpireY"
                      rules={{
                        required: {
                          value: true,
                          message: `필수 입력입니다`,
                        },
                      }}
                      as={<InputNumber format="##" placeholder="YY" />}
                    />
                  </InputGroupCard>
                  {cardExpireError && (
                    <ErrorMessage>{cardExpireError.message}</ErrorMessage>
                  )}
                </DonateFormItem>
                {Object.keys(
                  MEMBER_TYPES[ENUM_SUPPORTER_TYPE.corporation],
                ).includes(memberTypeCode || ``) ? (
                  // 법인의 경우
                  <DonateFormItem>
                    <H4>카드 소유주 사업자번호</H4>
                    <Controller
                      control={control}
                      name="cardJuminNumber"
                      rules={{
                        required: {
                          value: true,
                          message: `필수 입력입니다`,
                        },
                      }}
                      as={
                        <InputNumber
                          format="##########"
                          placeholder="사업자번호 입력(&lsquo;-&rsquo; 제외)"
                          css={`
                            margin-top: 12px;
                          `}
                        />
                      }
                    />
                    {errors.cardJuminNumber && (
                      <ErrorMessage>
                        {errors.cardJuminNumber.message}
                      </ErrorMessage>
                    )}
                  </DonateFormItem>
                ) : (
                  // 개인, 단체의 경우
                  <DonateFormItem>
                    <H4>카드 소유주 생년월일</H4>
                    <Controller
                      control={control}
                      name="cardJuminNumber"
                      rules={{
                        required: {
                          value: true,
                          message: `필수 입력입니다`,
                        },
                        validate: (value) =>
                          dayjs(value, `YYMMDD`, true).isValid() ||
                          `올바르지 않은 형식입니다`,
                      }}
                      as={
                        <InputNumber
                          format="######"
                          placeholder="생년월일 6자리 입력(YYMMDD)"
                          css={`
                            margin-top: 12px;
                          `}
                        />
                      }
                    />
                    {errors.cardJuminNumber && (
                      <ErrorMessage>
                        {errors.cardJuminNumber.message}
                      </ErrorMessage>
                    )}
                  </DonateFormItem>
                )}
                <DonateFormItem>
                  <H4>결제일</H4>
                  <LabelFlex
                    css={`
                      margin: 0;
                    `}
                  >
                    <li>
                      <InputRadio
                        name="paymentDay"
                        ref={register({
                          required: {
                            value: true,
                            message: `필수 입력입니다`,
                          },
                        })}
                        value="10"
                        label="10일"
                      />
                    </li>
                    <li>
                      <InputRadio
                        name="paymentDay"
                        ref={register}
                        value="20"
                        label="20일"
                      />
                    </li>
                    <li />
                  </LabelFlex>
                  {errors.paymentDay && (
                    <ErrorMessage>{errors.paymentDay.message}</ErrorMessage>
                  )}
                  <NotiBox
                    css={`
                      margin-top: 8px;
                    `}
                  >
                    <NotiTitle>신용카드 후원 시 유의사항</NotiTitle>
                    <NotiList>
                      <NotiItem>
                        신용카드의 경우, 카드사에 전달하는 과정을 거쳐야 합니다.
                        신청하신 약정일로부터 영업일(평일) 기준 2일 전에
                        신청하신 경우에만 해당 월에 후원이 가능합니다.
                      </NotiItem>
                      <NotiItem>
                        매월 선택하신 날짜에 약정하신 금액이 자동 인출 또는
                        승인됩니다.
                      </NotiItem>
                    </NotiList>
                  </NotiBox>
                </DonateFormItem>
              </>
            )}
            {paymentType === PAYTYPE_REGULAR_NAVERPAY && (
              <DonateFormItem>
                <H4>결제일</H4>
                <LabelFlex
                  css={`
                    margin: 0;
                  `}
                >
                  <li>
                    <InputRadio
                      name="paymentDay"
                      ref={register({
                        required: {
                          value: true,
                          message: `필수 입력입니다`,
                        },
                      })}
                      value="12"
                      label="12일"
                    />
                  </li>
                  <li>
                    <InputRadio
                      name="paymentDay"
                      ref={register}
                      value="18"
                      label="18일"
                    />
                  </li>
                  <li />
                </LabelFlex>
                {errors.paymentDay && (
                  <ErrorMessage>{errors.paymentDay.message}</ErrorMessage>
                )}
                <NotiBox
                  css={`
                    margin-top: 8px;
                  `}
                >
                  <NotiTitle>네이버페이 후원 시 유의사항</NotiTitle>
                  <NotiList>
                    <NotiItem>
                      네이버페이의 경우, 네이버페이에 전달하는 과정을 거쳐야
                      합니다. 신청하신 약정일로부터 영업일(평일) 기준 1일 전에
                      신청하신 경우에만 해당 월에 후원이 가능합니다.
                    </NotiItem>
                    <NotiItem>
                      매월 선택하신 날짜에 약정하신 금액이 자동 출금 또는
                      승인됩니다. 그러나 해당 일에 후원금이 전달되지 않은 경우
                      또는 미승인 시, 1회에 한해 선택하신 결제일 기준 이틀
                      뒤(12일→14일, 18일→20일)에 재출금이 진행됩니다.
                    </NotiItem>
                  </NotiList>
                </NotiBox>
              </DonateFormItem>
            )}
            <Button
              full
              css={`
                margin: 92px 0;
              `}
              type="submit"
              disabled={isSubmitting}
            >
              변경완료
            </Button>
          </FormContainer>
        </form>

        <NaverPayForm ref={naverRef} />
      </>
    );
  },
);

export default MyPayInformation;
