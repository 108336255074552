import Row from '../Grid/Row';
import { AccountCheckControllerService } from '@/__generated__/CommonApi';
import iconFacebook from '@/assets/img/join/ico_facebook@2x.png';
import iconGoogle from '@/assets/img/join/ico_google@2x.png';
import iconNaver from '@/assets/img/join/ico_naver@2x.png';
import Button from '@/components/Button';
import ErrorMessage from '@/components/Form/ErrorMessage';
import FormGroup from '@/components/Form/FormGroup';
import Label from '@/components/Form/Label';
import Col from '@/components/Grid/Col';
import InputPassword from '@/components/Input/InputPassword';
import NaverLogin, {
  NaverSuccessResult,
} from '@/components/SocialLogin/NaverLogin';
import { H3_1, H3_2 } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import {
  FormContainer,
  SocialButton,
  SocialButtonText,
} from '@/page-blocks/auth/AuthCommon';
import { usePopupStore } from '@/stores/PopupStore';
import { useUserStore } from '@/stores/UserStore';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { observer } from 'mobx-react-lite';
import React, { FC, useCallback } from 'react';
import { ReactFacebookLoginInfo } from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';
import SessionStorage, {
  SESSION_STORAGE_KEY__MYPAGE_AUTH,
} from '@/services/SessionStorage';

declare global {
  interface Window {
    naver: any;
  }
}

interface UserData {
  password: any;
}

const UserForm = styled.form``;

interface UserIdentificationProps {
  onAuthSuccess: () => void;
}

const UserIdentification: FC<UserIdentificationProps> = observer(
  ({ onAuthSuccess }) => {
    const userStore = useUserStore();
    const popupStore = usePopupStore();

    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm<UserData>({
      defaultValues: {
        password: ``,
      },
    });

    const rtnAuthSuccess = (token: string) => {
      SessionStorage.setItem(SESSION_STORAGE_KEY__MYPAGE_AUTH, token);
      onAuthSuccess();
    };

    // 네이버로 검증
    const authWithNaver = useCallback(
      async (result: NaverSuccessResult) => {
        if (userStore.user) {
          const isValid = userStore.user.socialId === result.user?.id;
          if (isValid) {
            const {
              data,
              resultCode,
              resultMessage,
            } = await AccountCheckControllerService.setSnsAuthCheckUsingGet({
              socialId: result.user?.id || '',
              snsDiv: userStore.user?.socialType || '',
            });
            if (resultCode === `success`) {
              // 성공시
              rtnAuthSuccess(data?.token);
            } else if (resultMessage) {
              popupStore.show(resultMessage);
            }
          }
        }
      },
      [userStore.user],
    );

    // 페이스북으로 검증
    const authWithFacebook = useCallback(
      async (result: ReactFacebookLoginInfo) => {
        if (userStore.user) {
          const isValid = userStore.user.socialId === result.id;
          if (isValid) {
            const {
              data,
              resultCode,
              resultMessage,
            } = await AccountCheckControllerService.setSnsAuthCheckUsingGet({
              socialId: result.id || '',
              snsDiv: userStore.user?.socialType || '',
            });

            if (resultCode === `success`) {
              // 성공시
              rtnAuthSuccess(data?.token);
            } else if (resultMessage) {
              popupStore.show(resultMessage);
            }
          }
        }
      },
      [userStore.user],
    );

    // 구글로 검증
    const authWithGoogle = useGoogleLogin({
      onSuccess: async (res) => {
        const userInfo = await axios.get(
          `https://www.googleapis.com/oauth2/v3/userinfo`,
          {
            headers: { Authorization: `Bearer ${res.access_token}` },
          },
        );
        if (userStore.user) {
          const isValid = userStore.user.socialId === userInfo.data.sub;
          if (isValid) {
            const {
              data,
              resultCode,
              resultMessage,
            } = await AccountCheckControllerService.setSnsAuthCheckUsingGet({
              socialId: userInfo.data.sub || '',
              snsDiv: userStore.user?.socialType || '',
            });

            if (resultCode === `success`) {
              // 성공시
              rtnAuthSuccess(data?.token);
            } else if (resultMessage) {
              popupStore.show(resultMessage);
            }
          }
        }
      },
      // eslint-disable-next-line no-console
      onError: (e) => console.error(e),
    });

    const onSubmit: SubmitHandler<UserData> = useCallback(
      async (param) => {
        if (userStore.user) {
          const {
            data,
            resultCode,
            resultMessage,
          } = await AccountCheckControllerService.setPasswordCheckUsingGet({
            password: param.password,
          });
          if (resultCode === `success`) {
            // 성공시
            rtnAuthSuccess(data?.token);
          } else if (resultMessage) {
            popupStore.show(resultMessage);
          }
        }
      },
      [popupStore, userStore.user],
    );

    return (
      <>
        <UserForm onSubmit={handleSubmit(onSubmit)}>
          {userStore.isSocial() ? (
            <FormContainer>
              <H3_1
                css={`
                  margin-bottom: 96px;
                `}
              >
                아이디 재인증
              </H3_1>
              <H3_2
                css={`
                  margin-bottom: 96px;
                `}
              >
                개인정보를 안전하게 보호하기 위해 <br />
                SNS 아이디로 한 번 더 로그인해주세요.
              </H3_2>
              <Row>
                {userStore.user?.socialType === `N` && (
                  <Col align="center">
                    <NaverLogin
                      clientId={process.env.NAVER_LOGIN_CLIENT_ID || ``}
                      callbackUrl={`${process.env.SELF_URL}/auth/join`}
                      render={(props) => (
                        <SocialButton {...props}>
                          <img
                            src={iconNaver}
                            alt="네이버"
                            width="60"
                            height="60"
                          />
                          <br />
                          <SocialButtonText>네이버</SocialButtonText>
                        </SocialButton>
                      )}
                      onSuccess={authWithNaver}
                      onFailure={(e) => console.error(e)}
                    />
                  </Col>
                )}
                {userStore.user?.socialType === `F` && (
                  <Col align="center">
                    <FacebookLogin
                      appId={process.env.FACEBOOK_LOGIN_APP_ID || ``}
                      fields="name,email"
                      disableMobileRedirect
                      render={({ onClick }) => (
                        <SocialButton onClick={onClick}>
                          <img
                            src={iconFacebook}
                            alt="페이스북"
                            width="60"
                            height="60"
                          />
                          <br />
                          <SocialButtonText>페이스북</SocialButtonText>
                        </SocialButton>
                      )}
                      callback={authWithFacebook}
                    />
                  </Col>
                )}
                {userStore.user?.socialType === `G` && (
                  <Col align="center">
                    <SocialButton onClick={() => authWithGoogle()}>
                      <img src={iconGoogle} alt="구글" width="60" height="60" />
                      <br />
                      <SocialButtonText>구글</SocialButtonText>
                    </SocialButton>
                  </Col>
                )}
              </Row>
            </FormContainer>
          ) : (
            <FormContainer>
              <H3_1
                css={`
                  margin-bottom: 96px;
                  ${breakpoint(640)} {
                    margin-bottom: 72px;
                  }
                `}
              >
                아이디 재인증
              </H3_1>
              <H3_2
                css={`
                  margin-bottom: 96px;
                  ${breakpoint(640)} {
                    margin-bottom: 72px;
                  }
                `}
              >
                개인정보를 안전하게 보호하기 위해 <br />
                비밀번호를 한번 더 입력해주세요.
              </H3_2>
              <FormGroup>
                <Label required>비밀번호</Label>
                <InputPassword
                  name="password"
                  ref={register({
                    required: {
                      value: true,
                      message: `필수 입력입니다`,
                    },
                  })}
                  placeholder="8-16자의 영문 및 숫자,특수문자를 모두 포함"
                />
                {errors.password && (
                  <ErrorMessage>{errors.password.message}</ErrorMessage>
                )}
              </FormGroup>
              <Button type="submit" full>
                확인
              </Button>
            </FormContainer>
          )}
        </UserForm>
      </>
    );
  },
);
export default UserIdentification;
