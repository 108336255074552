import Button from '@/components/Button';
import FormGroup from '@/components/Form/FormGroup';
import Label from '@/components/Form/Label';
import InputPassword from '@/components/Input/InputPassword';
import InputText from '@/components/Input/InputText';
import { H2, H3_2, Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { FormContainer } from '@/page-blocks/auth/AuthCommon';
import {
  ButtonFlex,
  ContainerGroup,
  SectionTop,
  TitleArea,
} from '@/page-blocks/mypage/MypageCommon';
import { RouteComponentProps } from '@reach/router';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

const UpdatePassword: FC<RouteComponentProps | PageProps> = ({ location }) => (
  <LayoutWithTitle
    location={location}
    title="마이페이지"
    description="for every child, UNICEF"
  >
    <SectionTop>
      <ContainerGroup>
        <FormContainer>
          <TitleArea
            css={`
              ${breakpoint(640)} {
                margin-bottom: 0;
              }
            `}
          >
            <H2>
              <Tit size="s1-5" color="sky" weight="normal">
                안전한 사용을 위해 <br />
                새로운 비밀번호로 변경해 주세요.
              </Tit>
            </H2>
            <H3_2>
              회원님은 최근 3개월동안 비밀번호를 변경하지 않으셨습니다.
            </H3_2>
          </TitleArea>
        </FormContainer>
      </ContainerGroup>
    </SectionTop>
    <SectionTop>
      <ContainerGroup>
        <FormContainer>
          <FormGroup>
            <Label required>아이디</Label>
            <InputText name="userId" value="userid" disabled />
          </FormGroup>
          <FormGroup>
            <Label required>비밀번호</Label>
            <InputPassword
              name="password"
              placeholder="8-16자의 영문 및 숫자,특수문자를 모두 포함"
            />
          </FormGroup>
          <FormGroup>
            <Label required>새 비밀번호</Label>
            <InputPassword
              name="passwordNew"
              placeholder="8-16자의 영문 및 숫자,특수문자를 모두 포함"
            />
          </FormGroup>
          <FormGroup>
            <Label required>새 비밀번호 확인</Label>
            <InputPassword
              name="passwordNew2"
              placeholder="8-16자의 영문 및 숫자,특수문자를 모두 포함"
            />
          </FormGroup>
          <ButtonFlex>
            <li>
              <Button outline>다음에하기</Button>
            </li>
            <li>
              <Button>변경하기</Button>
            </li>
          </ButtonFlex>
        </FormContainer>
      </ContainerGroup>
    </SectionTop>
  </LayoutWithTitle>
);
export default UpdatePassword;
