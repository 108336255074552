import Checked from '@/assets/img/icons/ico_checked@2x.png';
import IcoRefresh from '@/assets/img/icons/ico_refresh@2x.png';
import { Tit } from '@/components/Titles';
import quizList from '@/data/mypage/quiz.json';
import { breakpoint } from '@/helpers/BreakpointHelper';
import React, { FC, useCallback, useState } from 'react';
import styled from 'styled-components';

const CardContent = styled.div`
  background: #fff;
  height: 100%;
  padding: 36px 16px 0;
  text-align: center;

  &::after {
    content: '';
    display: block;
    border-radius: 4px 0 0 0;
    width: 36px;
    height: 36px;
    border: 18px solid #72c9eb;
    border-top-color: #1cabe2;
    border-left-color: #1cabe2;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &.back {
    border: 4px solid #1cabe2;
    &::after {
      display: none;
    }
  }

  dt {
    .under {
      font-weight: 700;
      display: inline-block;
      position: relative;

      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 8px;
        background: #b2def1;
        position: absolute;
        left: 0;
        bottom: 4px;
      }
      .dec {
        position: relative;
        z-index: 1;
      }
    }
  }
  dd {
    margin-top: 16px;
    padding-bottom: 68px;
    position: relative;
  }

  .btt-link {
    width: 100%;
    text-align: center;
    padding: 0 30px;
    position: absolute;
    left: 0;
    bottom: 22px;
  }
  .link-answer {
    color: #828385;
    font-size: 14px;
    padding-left: 28px;
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      background: url(${IcoRefresh}) center no-repeat;
      background-size: cover;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .correct {
    color: #1cabe2;
    line-height: 2;
    display: inline-block;
    padding-top: 64px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    &::before {
      content: '';
      display: block;
      width: 48px;
      height: 48px;
      background: url(${Checked}) center no-repeat;
      background-size: cover;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, 0);
    }
  }
`;

const Card = styled.div`
  background: #72c9eb;
  padding: 15px;
  position: absolute;
  transition: transform ease 1s;
  transform-style: preserve-3d;
  height: 100%;
  width: 100%;
  cursor: pointer;
  backface-visibility: hidden;

  &.front {
    transform: rotateY(0deg);
  }
  &.back {
    transform: rotateY(-180deg);
  }
`;

const CardContainer = styled.div<{ flipped?: boolean }>`
  position: relative;
  transform-style: preserve-3d;
  height: 100%;

  ${({ flipped }) =>
    flipped &&
    `
    ${Card} {
      &.front {
        transform: rotateY(180deg);
      }
      &.back {
        transform: rotateY(0deg);
      }
    }
  `}

  ${breakpoint(`mobile`)} {
    height: 400px;
  }
`;

const Under = styled.strong`
  font-weight: 700;
  display: inline-block;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 8px;
    background: #b2def1;
    position: absolute;
    left: 0;
    bottom: 4px;
  }
  .dec {
    position: relative;
  }
`;

const getRandomIndex = () => Math.floor(Math.random() * quizList.length);

const QuizCard: FC = React.memo(() => {
  const [cardFlipped, setCardFlipped] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(getRandomIndex());
  const currentQuiz = quizList[currentIndex];

  // 다음 퀴즈 호출
  const loadNextQuiz = useCallback(() => {
    setCardFlipped(false);
    setTimeout(() => {
      setCurrentIndex(getRandomIndex());
    }, 300);
  }, []);

  return (
    <CardContainer flipped={cardFlipped}>
      {/* 앞 */}
      <Card className="front" onClick={() => setCardFlipped(true)}>
        <CardContent>
          <dl>
            <dt>
              <Under>
                <p className="dec">
                  유니세프{` `}
                  <Tit size="s4" color="sky">
                    Quiz
                  </Tit>
                </p>
              </Under>
            </dt>
            <dd>
              <p className="dec">{currentQuiz.question}</p>
            </dd>
          </dl>
          <div className="btt-link">
            <div className="link-answer">
              카드를 클릭하고 정답을 확인해 보세요
            </div>
          </div>
        </CardContent>
      </Card>
      {/* 뒤 */}
      <Card className="back" onClick={loadNextQuiz}>
        <CardContent>
          <p className="correct">{currentQuiz.answer}</p>
          <div className="btt-link">
            <div className="link-answer">
              카드를 클릭하면 다음 퀴즈로 넘어갑니다
            </div>
          </div>
        </CardContent>
      </Card>
    </CardContainer>
  );
});

export default QuizCard;
