import { breakpoint } from '@/helpers/BreakpointHelper';
import styled from 'styled-components';

export const Pagination = styled.div<{
  align?: 'left' | 'center' | 'right';
}>`
  display: block;
  font-size: 0;
  ${({ align }) => {
    if (align === `left`) {
      return `text-align: left`;
    }
    if (align === `center`) {
      return `text-align: center`;
    }
    return `text-align: right`;
  }}
`;
export const PaginationList = styled.div``;
export const PaginationItem = styled.span<{
  active?: boolean;
}>`
  display: inline-block;
  width: 48px;
  height: 48px;
  font-size: 16px;
  margin: 0 2px;
  background: ${(props) => (props.active ? `#1cabe2` : ``)};
  color: ${(props) => (props.active ? `#fff` : `#2d2926`)};

  ${breakpoint(`tablet`)} {
    width: 32px;
    height: 32px;
  }

  a,
  button {
    display: block;
    width: 100%;
    height: 100%;
    line-height: 48px;
    text-align: center;

    ${breakpoint(`tablet`)} {
      line-height: 32px;
    }
  }
  img {
    width: 24px;
  }
`;

export default Pagination;
