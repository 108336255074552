import ImageIcon from '../ImageIcon';
import Pagination, { PaginationItem, PaginationList } from '../Pagination';
import { PageInfo } from '@/helpers/PaginationHelper';
import React, { FC } from 'react';
import styled from 'styled-components';

const PageButton = styled.button`
  height: 100%;
  display: inline-flex;
  align-items: center;
`;

interface SimplePaginationProps extends PageInfo {
  handleCurrentPage?: any;
}

const SimplePagination: FC<SimplePaginationProps> = ({
  pageGroupSize,
  firstOfGroup,
  lastOfGroup,
  prevGroup,
  nextGroup,
  currentPage,
  totalPageCount,
  handleCurrentPage,
}) => (
  <Pagination align="center">
    <PaginationList>
      {pageGroupSize < totalPageCount && currentPage !== 1 && (
        <PaginationItem>
          <button type="button" onClick={() => handleCurrentPage(1)}>
            <ImageIcon name="pagination-first" />
          </button>
        </PaginationItem>
      )}
      {prevGroup < firstOfGroup && (
        <PaginationItem>
          <button type="button" onClick={() => handleCurrentPage(prevGroup)}>
            <ImageIcon name="pagination-prev" />
          </button>
        </PaginationItem>
      )}
      {Array.from({ length: lastOfGroup - firstOfGroup + 1 }).map((_, i) => {
        const page = firstOfGroup + i;
        return (
          <PaginationItem key={page} active={page === currentPage}>
            <PageButton type="button" onClick={() => handleCurrentPage(page)}>
              {page}
            </PageButton>
          </PaginationItem>
        );
      })}
      {nextGroup > lastOfGroup && (
        <PaginationItem>
          <button type="button" onClick={() => handleCurrentPage(nextGroup)}>
            <ImageIcon name="pagination-next" />
          </button>
        </PaginationItem>
      )}
      {pageGroupSize < totalPageCount && currentPage !== totalPageCount && (
        <PaginationItem>
          <button
            type="button"
            onClick={() => handleCurrentPage(totalPageCount)}
          >
            <ImageIcon name="pagination-last" />
          </button>
        </PaginationItem>
      )}
    </PaginationList>
  </Pagination>
);

export default SimplePagination;
