import PrivateRoute from '@/components/PrivateRoute';
import MyPage from '@/private-pages/mypage';
import UserDrop from '@/private-pages/mypage/drop';
import MyPageDropCompletePage from '@/private-pages/mypage/drop/complete';
import MissSupportHistory from '@/private-pages/mypage/miss-support-history';
import MyInfo from '@/private-pages/mypage/my-info';
import MyReceipt from '@/private-pages/mypage/my-receipt';
import OneToOne from '@/private-pages/mypage/onetoone';
import OneToOneForm from '@/private-pages/mypage/onetoone/form';
import MyReward from '@/private-pages/mypage/reward-list';
import SupportHistory from '@/private-pages/mypage/support-history';
import SupportHistoryConnect from '@/private-pages/mypage/support-history/connect';
import SupportHistoryConnectError from '@/private-pages/mypage/support-history/connect-error';
import SupportInfo from '@/private-pages/mypage/support-info';
import UpdatePassword from '@/private-pages/mypage/update-password';
import { Router } from '@reach/router';
import React, { FC } from 'react';

const MyPageRouter: FC = () => (
  <Router basepath="/mypage">
    <PrivateRoute path="/" component={MyPage} />
    <PrivateRoute path="/my-info" component={MyInfo} />
    <PrivateRoute path="/drop" component={UserDrop} />
    {/* 완료페이지는 로그아웃된 상태로 진입하기에 예외처리 */}
    <MyPageDropCompletePage path="/drop/complete" />
    <PrivateRoute path="/update-password" component={UpdatePassword} />
    <PrivateRoute path="/support-history" component={SupportHistory} />
    <PrivateRoute path="/miss-support-history" component={MissSupportHistory} />
    <PrivateRoute
      path="/support-history/connect"
      component={SupportHistoryConnect}
    />
    <PrivateRoute
      path="/support-history/connect-error"
      component={SupportHistoryConnectError}
    />
    <PrivateRoute path="/support-info" component={SupportInfo} />
    <PrivateRoute path="/my-receipt" component={MyReceipt} />
    <PrivateRoute path="/reward-list" component={MyReward} />
    <PrivateRoute path="/onetoone" component={OneToOne} />
    <PrivateRoute path="/onetoone/form" component={OneToOneForm} />
  </Router>
);
export default MyPageRouter;
