import {
  FoBannerControllerService,
  FoBannerVo,
  MyReceiptControllerService,
} from '@/__generated__/FrontApi';
import Banner from '@/assets/img/banner/banner_my_receipt@2x.png';
import Button from '@/components/Button';
import CampaignCard, { CampaignData } from '@/components/Card/CampaignCard';
import Container from '@/components/Container';
import { SectionTop } from '@/components/Css/FoundSection';
import Row from '@/components/Grid/Row';
import InputCheckbox from '@/components/Input/InputCheckbox';
import InputRadio from '@/components/Input/InputRadio';
import InputText from '@/components/Input/InputText';
import Select from '@/components/Select';
import { NotiBox, NotiList, NotiItem } from '@/components/Text/TextNoti';
import { H2, H3, H4, Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { isMobileDevice, openPopup } from '@/helpers/BrowserHelper';
import {
  MEMBER_TYPE_CORPORATION,
  MEMBER_TYPE_INDIVIDUAL,
  MEMBER_TYPE_INDIVIDUAL_BUSINESS,
} from '@/helpers/SupportHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import {
  ContainerGroup,
  ContentsArea,
  TitleArea,
  PayInfoList,
  PayInfoItem,
} from '@/page-blocks/mypage/MypageCommon';
import MypageRoutes from '@/page-blocks/mypage/MypageRoutes';
import { usePopupStore } from '@/stores/PopupStore';
import { useUserStore } from '@/stores/UserStore';
import { RouteComponentProps } from '@reach/router';
import dayjs from 'dayjs';
import { navigate } from 'gatsby';
import { observer } from 'mobx-react-lite';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';

const NotiTitle = styled.strong`
  font-size: 20px;
  display: block;
  margin-bottom: 8px;
  letter-spacing: -1.2px;
  line-height: 2;
  ${breakpoint(640)} {
    font-size: 16px;
  }
`;
const ButtonContainer = styled.div`
  width: 510px;
  display: flex;
  justify-content: space-between;
  ${breakpoint(1024)} {
    position: relative;
  }
  ${breakpoint(640)} {
    width: 100%;
    flex-wrap: wrap;
    ${Button} {
      position: relative;
      width: calc(50% - 5px);
      max-width: calc(50% - 5px);
      &:last-child {
        width: 100%;
        max-width: 100%;
        margin-top: 10px;
      }
    }
  }
`;
const RecieptFlex = styled.div`
  width: 100%;
`;
const RecieptFlexCell = styled.div`
  display: inline-block;
  min-width: 25%;
  vertical-align: middle;
  ${Select} {
    display: inline-block;
    width: 80%;
  }
  ${InputText} {
    display: inline-block;
    width: 256px;
    margin-left: 24px;
    vertical-align: middle;
  }
  span.text {
    display: inline-block;
    width: 20%;
    text-align: center;
  }
  ${breakpoint(800)} {
    min-width: 50%;
  }
  ${breakpoint(640)} {
    ${InputText} {
      width: 100%;
      margin-left: 0;
    }
  }
`;
const SubmitButton = styled.div`
  width: 544px;
  margin: 64px auto 0;
  ${breakpoint(640)} {
    width: 100%;
    margin: 72px auto 0;
  }
`;
const MyReceiptBanner = styled.div`
  background-color: #f8f9fa;
  height: 220px;
  padding: 46px 48px 0 48px;
  position: relative;
  ${H4} {
    line-height: 32px;
    margin-top: 16px;
  }
  img {
    display: none;
  }
  &::after {
    content: '';
    display: block;
    width: 568px;
    height: 220px;
    background: url(${Banner}) no-repeat center center;
    background-size: 100%;
    position: absolute;
    right: 0;
    top: 0;
  }
  ${breakpoint(1024)} {
    height: auto;
    &::after {
      width: 100%;
      height: 220px;
      background-size: auto 100%;
      background-position: right center;
      position: relative;
      right: -48px;
    }
  }
  ${breakpoint(640)} {
    padding: 0;
    ${Tit} {
      padding: 16px 24px 0 24px;
    }
    ${H4} {
      font-size: 14px;
      padding: 8px 24px 0 24px;
      line-height: 28px;
      margin-bottom: 64px;
      br {
        display: none;
      }
    }
    &::after {
      display: none;
    }
    img {
      display: block;
      width: 100%;
    }
  }
`;
const NoData = styled.div`
  text-align: center;
  border-top: 1px solid #e5e6e8;
  border-bottom: 1px solid #e5e6e8;
  padding: 96px 0;
  ${ButtonContainer} {
    width: 256px;
    margin: 16px auto 0;
  }
  ${breakpoint(640)} {
    ${H3} {
      font-size: 14px;
      line-height: 28px;
    }
    ${ButtonContainer} {
      ${Button} {
        width: 100%;
      }
    }
  }
`;

const MyReciptBanners = styled.div`
  margin-top: 96px;

  .bn-slide {
    .swiper-slide {
      width: 100%;

      .img-box {
        padding-top: 19.64%;
      }
    }
  }
`;

// 영수증 신청 폼
interface ReceiptFormData {
  dispJuminno: string;
  requestType: string[];
  selectYear: string;
  receiverFax: string;
}

const ReceiptRequestForm: FC = observer(() => {
  const userStore = useUserStore();
  const popupStore = usePopupStore();
  const { register, handleSubmit, watch } = useForm<ReceiptFormData>({
    defaultValues: {
      requestType: [`E`],
      dispJuminno: `0`,
    },
  });
  const requestType = watch(`requestType`);

  const onSubmit: SubmitHandler<ReceiptFormData> = useCallback(
    async ({ requestType: _requestType, ...formData }) => {
      if (!userStore.user) {
        return;
      }

      try {
        const {
          resultCode,
        } = await MyReceiptControllerService.myReciptApplyUsingPost({
          ...formData,
          requestType: _requestType.join(`,`),
          receiverAddress: userStore.user.address1,
          receiverAddress2: userStore.user.address2,
          receiverZipcode: userStore.user.zip,
          receiverMail: userStore.user.email,
          receiverName: userStore.user.name,
        });
        if (resultCode === `success`) {
          popupStore.show(
            `기부금 영수증 신청이 완료되었습니다. 확인 후 빠른 시일 내에 전달드리도록 하겠습니다.`,
          );
        }
      } catch (e) {
        if (e.response) {
          popupStore.show(e.response.data.resultMessage);
        }
      }
    },
    [popupStore, userStore.user],
  );

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TitleArea>
          <H2>영수증 발급 신청</H2>
          <div>
            후원자님이 원하시는 방법(이메일, 우편, 팩스)으로 기부금 영수증을
            받으실 수 있습니다.
          </div>
        </TitleArea>
        <ContentsArea noBorderBottom>
          <PayInfoList>
            <PayInfoItem type="form">
              <strong>신청년도</strong>
              <RecieptFlex>
                <RecieptFlexCell>
                  <Select ref={register} name="selectYear">
                    {/* 뒤로 11년 출력 */}
                    {[...Array(11)].map((e, i) => {
                      const year = dayjs().year() - i;
                      return (
                        <option key={year} value={year} selected={i === 1}>
                          {year}
                        </option>
                      );
                    })}
                  </Select>
                  <span className="text">년</span>
                </RecieptFlexCell>
              </RecieptFlex>
            </PayInfoItem>
            <PayInfoItem type="form">
              <strong>주민등록번호 표시</strong>
              <RecieptFlex>
                <RecieptFlexCell>
                  <InputRadio
                    ref={register}
                    name="dispJuminno"
                    label="앞자리만 표시"
                    value="0"
                  />
                </RecieptFlexCell>
                <RecieptFlexCell>
                  <InputRadio
                    ref={register}
                    name="dispJuminno"
                    label="전체표시"
                    value="1"
                  />
                </RecieptFlexCell>
              </RecieptFlex>
            </PayInfoItem>
            <PayInfoItem type="form">
              <strong>발급 방법</strong>
              <RecieptFlex>
                <RecieptFlexCell>
                  <InputCheckbox
                    ref={register}
                    name="requestType"
                    label="이메일"
                    value="E"
                  />
                </RecieptFlexCell>
                <RecieptFlexCell>
                  <InputCheckbox
                    ref={register}
                    name="requestType"
                    label="우편"
                    value="P"
                  />
                </RecieptFlexCell>
                <RecieptFlexCell
                  css={`
                    ${breakpoint(640)} {
                      width: 100%;
                    }
                  `}
                >
                  <InputCheckbox
                    ref={register}
                    name="requestType"
                    label="팩스"
                    value="F"
                  />
                  <InputText
                    ref={register}
                    name="receiverFax"
                    placeholder="팩스 번호 입력"
                    disabled={!requestType.includes(`F`)}
                  />
                </RecieptFlexCell>
              </RecieptFlex>
            </PayInfoItem>
          </PayInfoList>
          <NotiBox
            css={`
              margin-top: 16px;
            `}
          >
            <NotiList>
              <NotiItem>
                이메일로 신청한 기부금 영수증은 개인정보 보호를 위하여 암호화된
                파일로 첨부되어 발송됩니다.
              </NotiItem>
              <NotiItem>
                일부 기업·단체·구글 이메일의 경우, 보안 이슈로 인해 기부금
                영수증 이메일이 정상 수신 되지 않을 수 있습니다.
                <br />
                해당 이메일을 사용하시는 후원자님은 네이버, 다음 등 포털
                이메일로 변경해 사용하시길 권장드립니다. {` `}▷{` `}
                <a href="/mypage/my-info/">이메일 주소 변경하기</a>
              </NotiItem>
            </NotiList>
          </NotiBox>
        </ContentsArea>
        <SubmitButton>
          <Button type="submit" full>
            신청하기
          </Button>
        </SubmitButton>
      </form>
    </Container>
  );
});

// 기부금 영수증 페이지
const MyReceipt: FC<RouteComponentProps> = observer(({ location }) => {
  const userStore = useUserStore();
  const popupStore = usePopupStore();
  const status = useMemo(() => {
    if (!userStore.user) {
      return null;
    }
    // 후원연결이 안되어 있으면
    if (!userStore.user.donorId) {
      return `no-donor-id`;
    }

    // 주민번호, 사업자번호 올바르지 않으면
    if (!userStore.isValidJuminno()) {
      // 온라인출력 대상자 아님
      return `no-online`;
    }

    // 기부금 영수증 신청 후원내역 없을시
    if (!userStore.user.donorHistoryCount) {
      return `no-history`;
    }

    return `success`;
  }, [userStore]);

  const [banners, setBanners] = useState<CampaignData[]>([]);

  const loadBanners = useCallback(async () => {
    try {
      const {
        data: mypageReceiptBanners,
      } = await FoBannerControllerService.selectBannerListUsingGet({
        kind: '5001',
      });
      setBanners(
        mypageReceiptBanners.map((banner: FoBannerVo) => ({
          id: banner.indexNumber,
          pcImage: banner.imgAttGrpNoPc,
          mobileImage: banner.imgAttGrpNoMobile,
          link: banner.targetUrl,
          linkTargetBlank: banner.targetBlankYn === `Y`,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadBanners();
  }, [loadBanners]);

  return (
    <LayoutWithTitle
      location={location}
      title="마이페이지"
      description="for every child, UNICEF"
    >
      <MypageRoutes currentKey={5} />
      <SectionTop className="by-sub-main-layout">
        <ContainerGroup>
          <Container>
            <NotiBox>
              <NotiTitle>영수증 발급 안내</NotiTitle>
              <NotiList>
                <NotiItem>
                  후원자 본인 명의로만 발급 가능하며, 주민등록번호 13자리가 모두
                  정확히 등록된 개인 후원자의 경우 국세청 홈택스에서 자동 확인
                  가능합니다.
                </NotiItem>
                <NotiItem>
                  매년 초 우편으로 보내드리던 연말정산 기부금 영수증이 2016년
                  1월부터 일괄 발송되지 않습니다.
                </NotiItem>
                <NotiItem>
                  우편 발급 신청은 지속적으로 가능하며, 요청시 익일 발송되어
                  도착까지 3~4일이 소요됩니다. (주말/공휴일 제외)
                </NotiItem>
              </NotiList>
            </NotiBox>
          </Container>
          {/* 데이터 없음의 경우 */}

          {/* 후원내역 X / 후원연결 X */}
          {status === `no-donor-id` && (
            <Container>
              <NoData>
                <H3>
                  유니세프에 후원 중이신가요? <br />
                  신청할 후원내역이 확인되지 않습니다. <br />
                  회원정보와 후원내역을 연결 후 후원 내역을 관리할 수 있습니다.
                </H3>
                <ButtonContainer>
                  <Button
                    full
                    onClick={() => navigate(`/mypage/support-history/connect`)}
                  >
                    후원내역 연결하기
                  </Button>
                </ButtonContainer>
              </NoData>
            </Container>
          )}
          {/* 후원내역 X / 후원연결 O */}
          {status === `no-history` && (
            <Container>
              <NoData>
                <H3>신청할 후원내역이 없습니다.</H3>
                <ButtonContainer>
                  <Button
                    full
                    onClick={() => navigate(`/mypage/support-history`)}
                  >
                    후원내역 확인하기
                  </Button>
                </ButtonContainer>
              </NoData>
            </Container>
          )}
          {/* 후원내역 온라인 출력 불가 */}
          {status === `no-online` && (
            <Container>
              <NoData>
                <H3>
                  후원본부(02-737-1004, 평일 09:00~18:00)로 연락주시면 <br />
                  빠르게 도와드리겠습니다.
                </H3>
              </NoData>
            </Container>
          )}
          {status === `success` && (
            <>
              <Container>
                <TitleArea>
                  <H2>신청 정보</H2>
                  <Row
                    css={`
                      ${breakpoint(1024)} {
                        display: block !important;
                      }
                    `}
                  >
                    아래 정보로 영수증을 신청합니다. 변경사항이 있는 경우
                    개인정보 변경 후 신청하시기 바랍니다.
                    <ButtonContainer
                      css={`
                        ${breakpoint(1024)} {
                          margin-top: 16px;
                        }
                      `}
                    >
                      <Button
                        size="sm"
                        color="gray"
                        outline
                        width="160"
                        css={`
                          ${breakpoint(800)} {
                            width: 160px;
                          }
                          ${breakpoint(640)} {
                            position: absolute;
                            right: 0;
                            top: 0;
                            font-size: 14px;
                          }
                        `}
                        onClick={() => {
                          if (!isMobileDevice()) {
                            openPopup(
                              `/mypage/goyubunho.html`,
                              `goyubunho`,
                              `width=600, height=848, scrollbars=no`,
                            );
                          } else {
                            popupStore.show(
                              '고유번호증 출력은 PC에서 시도 하세요.',
                            );
                          }
                        }}
                      >
                        고유번호증 출력
                      </Button>
                      <Button
                        size="sm"
                        color="gray"
                        outline
                        width="160"
                        css={`
                          ${breakpoint(800)} {
                            width: 160px;
                          }
                          ${breakpoint(640)} {
                            position: absolute;
                            right: 0;
                            top: 0;
                            font-size: 14px;
                          }
                        `}
                        onClick={() => {
                          if (!isMobileDevice()) {
                            popupStore.show(
                              `해당 문서는 기부금 영수증 발급을 위한 제출용으로만 사용 가능합니다.`, 
                              {
                                onConfirm: (result) => {
                                  openPopup(
                                    `/mypage/permit.html`,
                                    `permit`,
                                    `width=600, height=848, scrollbars=no`,
                                  );
                                },
                              },
                            );
                          } else {
                            popupStore.show(
                              '법인허가증 출력은 PC에서 시도 하세요.',
                            );
                          }
                        }}
                      >
                        법인허가증 출력
                      </Button>
                      <Button
                        size="sm"
                        color="gray"
                        outline
                        width="160"
                        css={`
                          ${breakpoint(800)} {
                            width: 160px;
                          }
                          ${breakpoint(640)} {
                            position: absolute;
                            right: 0;
                            top: 0;
                            font-size: 14px;
                          }
                        `}
                        onClick={() => navigate(`/mypage/my-info`)}
                      >
                        개인정보 변경
                      </Button>
                    </ButtonContainer>
                  </Row>
                </TitleArea>
                <ContentsArea>
                  <PayInfoList>
                    <PayInfoItem>
                      <strong>회원번호</strong>
                      {userStore.user?.donorId}
                    </PayInfoItem>
                    <PayInfoItem>
                      <strong>성명·단체명</strong>
                      {userStore.user?.name}
                    </PayInfoItem>
                    {/* 개인회원의 경우 주민등록번호 */}
                    {userStore.user?.donorTypeCode ===
                      MEMBER_TYPE_INDIVIDUAL && (
                      <PayInfoItem>
                        <strong>주민등록번호</strong>
                        {userStore.user?.juminno}
                      </PayInfoItem>
                    )}
                    {/* 법인회원의 경우 사업자등록번호 */}
                    {[
                      MEMBER_TYPE_CORPORATION,
                      MEMBER_TYPE_INDIVIDUAL_BUSINESS,
                    ].includes(userStore.user?.donorTypeCode) && (
                      <PayInfoItem>
                        <strong>사업자등록번호</strong>
                        {userStore.user?.juminno}
                      </PayInfoItem>
                    )}
                    <PayInfoItem>
                      <strong>이메일주소</strong>
                      {userStore.user?.email}
                    </PayInfoItem>
                    <PayInfoItem>
                      <strong>주소</strong>
                      {userStore.user?.zip && `[${userStore.user?.zip}]`}
                      {` `}
                      {userStore.user?.address1} {userStore.user?.address2}
                    </PayInfoItem>
                  </PayInfoList>
                </ContentsArea>
              </Container>
              <ReceiptRequestForm />
            </>
          )}

          <MyReciptBanners>
            {banners.length > 0 && (
              <Container>
                <CampaignCard
                  campaigns={banners}
                  autoplay
                  overflowHidden
                  className="bn-slide"
                  img
                />
              </Container>
            )}
          </MyReciptBanners>

          {/* 온라인 필드트립 2022.01.15까지만 운영 배너
          {dayjs().isBefore(dayjs(`2022-01-15`)) ? (
            <Container>
              <LinkSafe
                to="https://www.unicef.or.kr/campaign/fieldtrip/intro.html?trackcode=ftrip21_rcpt"
                target="_blank"
              >
                <Image
                  pcSrc={BannerFieldTripPc}
                  mobileSrc={BannerFieldTripMob}
                />
              </LinkSafe>
            </Container>
          ) : (
            <Container>
              <LinkSafe
                to="/what-we-do/no-discrimination/saving-lives/"
                css={`
                  display: block;
                `}
              >
                <MyReceiptBanner>
                  <Tit size="s2">어린이를 위한 유니세프 사업</Tit>
                  <H4>
                    유니세프는 어린이가 살기 좋은 세상을 만들기 위해 <br />
                    5가지 사업 분야에 중점을 둡니다.
                  </H4>
                  <img src={`${BannerMobile}`} alt="" />
                </MyReceiptBanner>
              </LinkSafe>
            </Container>
          )}
           */}
          <Container>
            <TitleArea>
              <H2>
                <Tit size="s3" color="sky">
                  문의사항이 있으신가요?
                </Tit>
              </H2>
              <div
                css={`
                  line-height: 1.7;
                `}
              >
                기부금 영수증 발급과 관련해 문의사항이 있으시다면 <br />
                유니세프 후원본부(전화 02-737-1004 / 평일 09:00~18:00, 이메일
                donor@unicef.or.kr)로 연락주시기 바랍니다.
              </div>
            </TitleArea>
          </Container>
        </ContainerGroup>
      </SectionTop>
    </LayoutWithTitle>
  );
});

export default MyReceipt;
