import Button from '../Button';
import ErrorMessage from '../Form/ErrorMessage';
import FormGroup from '../Form/FormGroup';
import FormTextTip from '../Form/FormTextTip';
import LabelText from '../Form/LabelText';
import Col from '../Grid/Col';
import Row from '../Grid/Row';
import AddressButton from '../Input/AddressButton';
import InputRadio from '../Input/InputRadio';
import InputText from '../Input/InputText';
import {
  MyRewardControllerService,
  myRewardVo,
} from '@/__generated__/FrontApi';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { FormButton, RightCol } from '@/page-blocks/auth/AuthCommon';
import { usePopupStore } from '@/stores/PopupStore';
import { useUserStore } from '@/stores/UserStore';
import { observer } from 'mobx-react-lite';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';

const HistoryItem = styled.li`
  display: table;
  width: 100%;
  table-layout: fixed;
  padding: 32px 0;
  position: relative;
  border-bottom: 1px solid #b7b8ba;
  ${breakpoint(800)} {
    display: block;
    padding: 24px 0;
  }
  ${Button} {
    max-width: none;
    width: auto;
  }
`;
const AddressForm = styled(FormGroup)`
  width: 544px;
  margin-top: 24px;
  ${FormButton} {
    width: 160px;
  }
  ${breakpoint(800)} {
    width: 100%;
  }
`;
const InputTextCustom = styled.div<{ active?: boolean }>`
  border: 1px solid #e5e6e8;
  margin-top: -1px;
  ${({ active }) => {
    if (!active) {
      return `
            span {
              color: #2d2926 !important;
            }
          `;
    }
    return ``;
  }}
`;
const Tip = styled.p`
  color: #828385;
  font-size: 14px;
  font-weight: normal;
`;
const Cell = styled.div<{ type?: string; status?: string; price?: boolean }>`
  display: table-cell;
  ${InputTextCustom} {
    span {
      width: 10%;
      text-align: right;
      color: #b7b8ba;
    }
  }
  ${({ type }) => {
    if (type === `date` || type === `status`) {
      return `
            width: 15%;
            text-align: center;
            font-weight: bold;
            font-size: 20px;
            .sky {
              color: #1cabe2
            }
          `;
    }
    if (type === `reward`) {
      return `
        font-size: 16px;   
        width: 10%;   
        color: #828385;
      `;
    }
    if (type === `address`) {
      return `  
        width: 20%;
        text-align: right;
        ${Tip} {
          color: #828385 !important;
          margin-top: 16px;
        }
        ${Button} {
            min-width: 80px;
        }
      `;
    }
    return `padding-left: 24px;`;
  }}
  ${({ price }) => {
    if (price) {
      return `
            width: 40%;
            padding-right: 16px;
            ${InputText} {
              border: none;
              width: 90%;
              display: inline-block;
            }
          `;
    }
    return ``;
  }}
  ${({ status }) => {
    if (status === `dark`) {
      return `
            color: #2d2926
          `;
    }
    if (status === `light`) {
      return `
            color: #828385;
          `;
    }
    return ``;
  }}
  ${breakpoint(1024)} {
    ${({ type }) => {
      if (type === `address`) {
        return `
            vertical-align: bottom;
          `;
      }
      return ``;
    }}
  }
  ${breakpoint(800)} {
    display: block;
    ${({ status }) => {
      if (status === `dark` || status === `light`) {
        return `
            position: absolute;
            right: 0;
            top: 24px;
            font-size: 16px;
            text-align: right;
            white-space: nowrap;
          `;
      }
      return ``;
    }}
    ${({ type }) => {
      if (type === `date`) {
        return `
        text-align: left;
          `;
      }
      if (type === `status`) {
        return `
            position: absolute;
            right: 0;
            top: 24px;
            font-size: 16px;
            text-align: right;
            white-space: nowrap;
            width: 100%;
          `;
      }
      if (type === `address`) {
        return `
            width: 100%;
            text-align: right;
            ${Tip} {
                margin-top: 8px;
            }
        `;
      }
      return `padding-left: 0;`;
    }}
  }
  ${breakpoint(640)} {
    ${({ type }) => {
      if (type === `address`) {
        return `
        ${Tip} {
            font-size: 12px;
        }
        `;
      }
    }}
  }
`;
const Category = styled.div`
  min-height: 44px;
  line-height: 2;
  color: #828385;
  strong {
    color: #1cabe2;
    display: inline-block;
    margin-left: 40px;
    &.first {
      margin-left: 0;
    }
  }
  ${Button} {
    margin-left: 32px;
    ${breakpoint(640)} {
      display: block;
      margin-left: 90px;
      height: 36px;
      line-height: 36px;
      font-size: 14px;
    }
  }
  ${breakpoint(640)} {
    margin-top: 18px;
  }
`;
const PriceDetail = styled.div`
  margin-top: 18px;
  span {
    position: relative;
    padding-right: 16px;
    &.is-failed {
      color: #e2231a;
    }
    & + span {
      padding-left: 16px;
      &::before {
        content: '';
        display: block;
        width: 1px;
        height: 16px;
        background: #e5e6e8;
        position: absolute;
        left: 0;
        top: 5px;
      }
    }
  }
`;
const DueDateText = styled.div`
  color: #e2231a;
  margin-top: 16px;
`;
const AgreeCheckFlex = styled.div`
  margin: 16px 0 32px 0;
  label {
    width: 50%;
  }
`;

interface AddressFormData {
  zipCode: string;
  address: string;
  addressDetail: string;
  dpoAddressYn: 'Y' | 'N';
}

export type RewardAddressPostParams = Parameters<
  typeof MyRewardControllerService.postMyAddressEditUsingPost
>[0];

interface MyRewardAddressItemProps {
  item: myRewardVo;
  onItemChange: (params: RewardAddressPostParams) => Promise<boolean>;
}

const MyRewardAddressItem: FC<MyRewardAddressItemProps> = observer(
  ({ item, onItemChange }) => {
    const popupStore = usePopupStore();
    const userStore = useUserStore();
    const [isEditing, setIsEditing] = useState(false);
    const {
      register,
      setValue,
      handleSubmit,
      formState: { errors },
      reset,
    } = useForm<AddressFormData>();

    const onSubmit: SubmitHandler<AddressFormData> = useCallback(
      async (formData) => {
        // dp 테이블 우편번호 저장 안되고 있던 오류로 인하여
        // 우편번호가 없는 경우, 재입력 받을 수 있도록 수정.
        if (!formData.zipCode) {
          popupStore.show(`주소 입력을 확인해 주세요.`);
          return false;
        }
        const succeed = await onItemChange({
          ...formData,
          authKey: item.authKey,
          donorId: userStore.user?.donorId,
        });
        // 성공하면 수정 종료
        if (succeed) {
          setIsEditing(false);
        }
      },
      [item.authKey, onItemChange, userStore.user?.donorId],
    );

    // 넘어온 값 세팅
    useEffect(() => {
      reset(item);
    }, [item, reset]);

    return (
      <>
        <form onSubmit={handleSubmit(onSubmit)}>
          <HistoryItem>
            <Cell type="reward">
              {item.recordType === `P` ? `정기후원` : `일시후원`}
            </Cell>
            {isEditing ? (
              <Cell>
                <AddressForm className="FormGroup">
                  <LabelText require>주소</LabelText>
                  <Row>
                    <Col desktop="auto">
                      <InputText ref={register} name="zipCode" readOnly />
                    </Col>
                    <RightCol desktop="none">
                      <AddressButton
                        onSelectAddress={(address) => {
                          setValue(`zipCode`, address.zonecode);
                          setValue(`address`, address.address);
                        }}
                        renderButton={(buttonProps) => (
                          <FormButton {...buttonProps} outline>
                            주소검색
                          </FormButton>
                        )}
                      />
                    </RightCol>
                  </Row>
                  <Row>
                    <InputText
                      name="address"
                      ref={register({
                        required: {
                          value: true,
                          message: `필수 입력입니다`,
                        },
                      })}
                      readOnly
                    />
                  </Row>
                  {errors.address && (
                    <ErrorMessage>{errors.address.message}</ErrorMessage>
                  )}
                  <Row>
                    <InputText
                      name="addressDetail"
                      ref={register({
                        required: {
                          value: true,
                          message: `필수 입력입니다`,
                        },
                      })}
                    />
                  </Row>
                  {errors.addressDetail && (
                    <ErrorMessage>{errors.addressDetail.message}</ErrorMessage>
                  )}
                </AddressForm>
                <AddressForm>
                  <FormGroup>
                    <LabelText>
                      <strong>
                        입력하신 주소를 후원자 정보에 저장하시겠습니까?
                      </strong>
                    </LabelText>
                    <AgreeCheckFlex>
                      <InputRadio
                        ref={register({
                          required: {
                            value: true,
                            message: `필수 입력입니다`,
                          },
                        })}
                        label="예"
                        name="dpoAddressYn"
                        value="Y"
                      />
                      <InputRadio
                        ref={register({
                          required: {
                            value: true,
                            message: `필수 입력입니다`,
                          },
                        })}
                        label="아니오"
                        name="dpoAddressYn"
                        value="N"
                      />
                    </AgreeCheckFlex>
                    {errors.dpoAddressYn && (
                      <ErrorMessage>{errors.dpoAddressYn.message}</ErrorMessage>
                    )}
                    <FormTextTip>
                      '예'를 선택하시면 입력하신 주소를 후원자 정보에
                      저장합니다.
                    </FormTextTip>
                    <FormTextTip>
                      '아니오'를 선택하시면 배송 완료 후 파기합니다.
                    </FormTextTip>
                  </FormGroup>
                </AddressForm>
              </Cell>
            ) : (
              <Cell>
                <Category>
                  <strong className="first">
                    {item.description}
                    {item.rewardName && ` [${item.rewardName}]`}
                  </strong>
                </Category>
                <PriceDetail>
                  <span>{item.donationPaidDate}</span>
                  <span>{item.shippingDate} 발송 예정</span>
                  <span>{item.donationStatus}</span>
                  {item.etcOpt !== null && item.etcOpt !== '' && (
                    <span>사이즈: {item.etcOpt}</span>
                  )}
                </PriceDetail>
                {item.address ? (
                  <PriceDetail>
                    {item.address} {item.addressDetail}
                  </PriceDetail>
                ) : (
                  <DueDateText>수령 주소를 입력해주세요.</DueDateText>
                )}
              </Cell>
            )}
            {/* 수정버튼 */}
            {item.addressEditYn === `Y` && (
              <Cell type="address">
                {isEditing ? (
                  <Button type="submit" size="sm">
                    완료
                  </Button>
                ) : (
                  <>
                    <Button
                      outline
                      color="gray"
                      size="sm"
                      onClick={() =>
                        requestAnimationFrame(() => setIsEditing(true))
                      }
                      css={`
                        ${breakpoint(640)} {
                          padding: 7px;
                          font-size: 14px;
                        }
                      `}
                    >
                      주소 변경
                    </Button>
                    <Tip>발송예정일 7일 전까지 수정가능</Tip>
                  </>
                )}
              </Cell>
            )}
          </HistoryItem>
        </form>
      </>
    );
  },
);

export default MyRewardAddressItem;
