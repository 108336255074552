import Container from '@/components/Container';
import LinkSafe from '@/components/LinkSafe';
import Section from '@/components/Section';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { mypageMenu } from '@/page-blocks/mypage/MypageCommon';
import { useUserStore } from '@/stores/UserStore';
import { observer } from 'mobx-react-lite';
import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';

const MenuSection = styled(Section)`
  border-bottom: 3px solid #e5e6e8;
  ${Container} {
    padding: 0;
  }
`;
const MainMenu = styled.div``;
const MainMenuList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .swiper-container {
    width: 100%;
    overflow: visible;
  }
  .swiper-slide {
    height: 80px;
  }
  ${breakpoint(640)} {
    .swiper-slide {
      height: 56px;
    }
  }
  .receipt-menu {
    background-color: #1cabe2;
    width: 5px;
    height: 5px;
    border-radius: 75px;
    /* position: absolute; */
    /* top: 35%; */
    /* right: 5%; */
    left: 10px;

    ${breakpoint(`mobile`)} {
      top: 17px;
      /* right: 20px; */
      left: 10px;
    }
  }
`;
const MainMenuItem = styled.div<{ active?: boolean }>`
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #b7b8ba;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: color 0.15s ease-in-out;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 3px;
    background-color: transparent;
    position: absolute;
    left: 0;
    bottom: -3px;
    transition: background-color 0.15s ease-in-out;
  }
  ${breakpoint(640)} {
    line-height: 56px;
    font-size: 16px;
  }
  &:hover {
    color: #1cabe2;
    &::before {
      background-color: #1cabe2;
    }
  }
  ${({ active }) => {
    if (active) {
      return `
            color: #1cabe2;
            &::before {
              background-color: #1cabe2;
            }
          `;
    }
    return ``;
  }}
  a {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
`;

interface MypageRoutesProps {
  currentKey: number;
}

const MypageRoutes: FC<MypageRoutesProps> = observer(({ currentKey }) => {
  const userStore = useUserStore();

  // 발송현황 필터링
  const menus = useMemo(() => {
    if (userStore.user?.isRewardYn !== `Y`) {
      return mypageMenu.filter((menu) => menu.id !== 6);
    }
    return mypageMenu;
  }, [userStore.user?.isRewardYn]);

  return (
    <MenuSection>
      <Container>
        <MainMenu>
          <MainMenuList>
            <Swiper
              slidesPerView={menus.length}
              navigation={false}
              // scrollbar={{ draggable: false }}
              noSwiping
              allowTouchMove={false}
              breakpoints={{
                1025: {
                  slidesPerView: menus.length,
                  initialSlide: currentKey - 1,
                },
                800: {
                  allowTouchMove: true,
                  slidesPerView: 4,
                  initialSlide: currentKey - 1,
                },
                320: {
                  allowTouchMove: true,
                  slidesPerView: 2.5,
                  initialSlide: currentKey - 1,
                },
              }}
            >
              <div className="swiper-container">
                {menus.map((routes) => (
                  <SwiperSlide key={routes.id}>
                    {routes.name === `기부금 영수증` ? (
                      <MainMenuItem active={currentKey === routes.id}>
                        <LinkSafe to={routes.link}>
                          <span>{routes.name}</span>
                          <span
                            style={{
                              paddingLeft: `3px`,
                              paddingTop: `37px`,
                              height: `100px`,
                            }}
                          >
                            <div className="receipt-menu" />
                          </span>
                        </LinkSafe>
                      </MainMenuItem>
                    ) : (
                      <MainMenuItem active={currentKey === routes.id}>
                        <LinkSafe to={routes.link}>{routes.name}</LinkSafe>
                      </MainMenuItem>
                    )}
                  </SwiperSlide>
                ))}
              </div>
            </Swiper>
          </MainMenuList>
        </MainMenu>
      </Container>
    </MenuSection>
  );
});
export default MypageRoutes;
