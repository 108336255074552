import { MyQnaControllerService } from '@/__generated__/FrontApi';
import IcoFaqCategory1 from '@/assets/img/icons/ico_faq_category_01@2x.png';
import IcoFaqCategory2 from '@/assets/img/icons/ico_faq_category_02@2x.png';
import IcoFaqCategory3 from '@/assets/img/icons/ico_faq_category_03@2x.png';
import IcoFaqCategory4 from '@/assets/img/icons/ico_faq_category_04@2x.png';
import IcoFaqCategory5 from '@/assets/img/icons/ico_faq_category_05@2x.png';
import IcoFaqCategory6 from '@/assets/img/icons/ico_faq_category_06@2x.png';
import Button from '@/components/Button';
import ContactUs from '@/components/ContactUs';
import Container from '@/components/Container';
import LinkSafe from '@/components/LinkSafe';
import AccordionComponent, {
  AccordionItem,
} from '@/components/MypageAccordion';
import SimplePagination from '@/components/Pagination/SimplePagination';
import { NotiBox, NotiList, NotiItem } from '@/components/Text/TextNoti';
import { H2, H3_1, H3_2 } from '@/components/Titles';
import { ONE_TO_ONE_CATEGORIES } from '@/helpers/BoardHelper';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { getCurrentPageInfo, PageInfo } from '@/helpers/PaginationHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import {
  ContainerGroup,
  SectionTop,
  TitleArea,
  ContainerFooter,
} from '@/page-blocks/mypage/MypageCommon';
import MypageRoutes from '@/page-blocks/mypage/MypageRoutes';
import { navigate } from 'gatsby';
import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

const FaqCategory = styled(SectionTop)`
  position: relative;
  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 440px;
    background: #e2f0f6;
    position: absolute;
    left: 0;
    top: 0;
  }
`;
const FaqLinkParagraph = styled.p`
  text-align: right;
  font-size: 14px;
  color: #828385 !important;
  margin-top: 48px;
  position: relative;
  a {
    color: #1cabe2;
    text-decoration: underline;
    padding-left: 8px;
    &:hover {
      text-decoration: underline;
    }
  }
  ${breakpoint(640)} {
    margin-top: 32px;
  }
`;
const FaqCategoryList = styled.div`
  margin: 32px -32px -32px 0;
  ${breakpoint(1160)} {
    margin: 32px -1% -1% 0;
  }
  ${breakpoint(800)} {
    margin: 24px 0 0 0;
    padding-bottom: 40px;
    overflow-x: scroll;
    white-space: nowrap;
  }
  ${breakpoint(640)} {
    width: calc(100% + 40px);
    padding-left: 20px;
    padding-right: 20px;
    margin-left: -20px;
  }
`;
const FaqCategoryItem = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 0 32px 32px 0;
  width: 352px;
  height: 328px;
  overflow: hidden;
  border-radius: 0 0 60px 0;
  box-shadow: 3px 10px 28px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;
  text-align: center;
  z-index: 0;
  &::before {
    content: 'Q';
    font-size: 48px;
    color: #e2f0f6;
    position: absolute;
    left: 24px;
    top: 0;
  }
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 0 0 60px 0;
    border: 1px solid #1cabe2;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }
  &:hover {
    ${H3_2} {
      color: #1cabe2;
    }
    &::after {
      border-width: 4px;
    }
  }
  img {
    width: 80px;
  }
  a {
    display: block;
    width: 100%;
    height: 100%;
    padding: 64px 22px 0;
  }
  ${H3_2} {
    line-height: 1.75;
    margin-top: 24px;
    letter-spacing: -0.8px;
  }
  ${breakpoint(1160)} {
    width: 32.3%;
    height: 30vw;
    margin: 0 1% 1% 0;
    a {
      padding: 16% 0 0 0;
    }
  }
  ${breakpoint(1024)} {
    &::before {
      font-size: 40px;
      left: 16px;
      top: 8px;
    }
    ${H3_2} {
      font-size: 20px;
      line-height: 32px;
      margin-top: 8px;
    }
    a {
      padding: 10% 0 0 0;
    }
  }
  ${breakpoint(800)} {
    width: 234px;
    height: 218px;
    margin: 0;
    & + div {
      margin-left: 24px;
    }
    img {
      width: 56px;
    }
    a {
      padding: 42px 8px 0;
    }
    ${H3_2} {
      font-size: 16px;
      line-height: 26px;
      margin-top: 16px;
    }
  }
  ${breakpoint(640)} {
  }
`;
const ButtonAlign = styled.div`
  text-align: right;
  ${Button} {
    width: 160px;
  }
  ${breakpoint(640)} {
    ${Button} {
      width: 147px;
      font-size: 14px;
    }
  }
`;
const AccordionBody = styled.div`
  font-size: 16px;
  ${breakpoint(`mobile`)} {
    font-size: 14px;
  }
`;
const OneToOneCategory = styled.div`
  display: table-cell;
  font-weight: bold;
  width: 100px;
  color: #1cabe2;
  vertical-align: middle;
  ${breakpoint(1024)} {
    display: inline-block;
    margin-right: 8px;
    width: auto;
  }
  ${breakpoint(640)} {
    font-size: 14px;
  }
`;
const OneToOneStatus = styled.div`
  display: table-cell;
  width: 90px;
  color: #828385;
  vertical-align: middle;
  ${breakpoint(1024)} {
    display: inline-block;
    width: auto;
  }
  ${breakpoint(640)} {
    font-size: 14px;
  }
`;
const OneToOneSubject = styled.div`
  display: table-cell;
  font-weight: bold;
  font-size: 20px;
  padding-left: 16px;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${breakpoint(1024)} {
    display: block;
    padding: 0 40px 0 0;
    margin-top: 8px;
  }
  ${breakpoint(800)} {
    font-size: 16px;
  }
`;
const OneToOneDate = styled.div`
  display: table-cell;
  padding: 0 32px 0 24px;
  width: 13%;
  vertical-align: middle;
  ${breakpoint(1024)} {
    display: block;
    padding: 0;
    margin-top: 8px;
    width: 100%;
  }
  ${breakpoint(800)} {
    font-size: 14px;
  }
`;
const NavigationButton = styled.div`
  padding: 32px 24px;
  cursor: pointer;
  position: relative;
  display: table;
  width: 100%;
  table-layout: fixed;
  height: 104px;

  ${breakpoint(1024)} {
    display: block;
    padding: 24px 0;
    height: auto;
  }
  ${breakpoint(800)} {
    padding: 16px 0;
  }
  ${breakpoint(640)} {
    font-size: 16px;
    padding: 20px 0;
  }
`;
const FaqAccordionList = styled.div`
  margin-top: 32px;
  .is-active {
    ${OneToOneSubject} {
      white-space: normal;
      text-overflow: initial;
      word-break: break-all;
      overflow: visible;
    }
  }
`;
const StatusIcon = styled.span<{ complete?: boolean }>`
  border: 1px solid #b7b8ba;
  color: #828385;
  font-size: 14px;
  display: inline-block;
  height: 28px;
  line-height: 26px;
  padding: 0 16px;
  letter-spacing: -0.8px;
  vertical-align: top;
  ${({ complete }) => {
    if (complete) {
      return `
            color: #1cabe2;
            border-color: #1cabe2;
          `;
    }
    return ``;
  }}
  ${breakpoint(640)} {
    font-size: 12px;
    height: 24px;
    line-height: 22px;
    padding: 0 12px;
  }
`;
const Question = styled.div`
  padding: 32px 32px 32px 96px;
  color: #2d2926;
  line-height: 1.7;
  white-space: break-spaces;
  ${breakpoint(1024)} {
    padding: 16px 0;
  }
`;
const Answer = styled.div`
  background: #f8f9fa;
  padding: 32px 32px 32px 170px;
  white-space: break-spaces;
  ${breakpoint(1024)} {
    padding: 16px 16px 16px 0;
  }
  p {
    a {
      color: #1cabe2 !important;
    }
  }
`;
const AnswerInner = styled.div`
  position: relative;
  color: #2d2926;
  line-height: 1.7;
  &::before {
    content: 'A';
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    color: #fff;
    background-color: #1cabe2;
    text-align: center;
    line-height: 40px;
    font-weight: bold;
    position: absolute;
    left: -56px;
    top: 0;
  }
  ${breakpoint(1024)} {
    padding-left: 40px;
    &::before {
      width: 32px;
      height: 32px;
      line-height: 32px;
      left: 0;
      top: -4px;
    }
  }
`;
const ContactContainer = styled(ContainerFooter)`
  ${breakpoint(640)} {
    article .header {
      width: 0;
    }
    article .flex {
      padding: 0;
      width: 100%;
    }
  }
`;
const NoData = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #828385;
  padding: 96px 16px;
  border-top: 1px solid #e5e6e8;
  border-bottom: 1px solid #e5e6e8;
  ${breakpoint(800)} {
    font-size: 16px;
  }
  ${breakpoint(640)} {
    font-size: 14px;
    padding: 72px 16px;
  }
`;
const contents = `질문 예시 데이터 질문 예시 데이터 질문 예시 데이터 질문 예시 데이터 질문 예시 데이터 질문 예시 데이터 `;
const contactData = [
  {
    id: 1,
    costumer: `후원본부`,
    tel: `02-737-1004`,
    email: `donor@unicef.or.kr`,
  },
];
const itemsPerPage = 5;
const OneToOne: FC = ({ location }) => {
  const [items, setItems] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageInfo, setPageInfo] = useState<PageInfo>();

  const handleCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  const loadItems = useCallback(async (targetPage: number) => {
    const {
      data,
      resultCode,
      totalCount,
    } = await MyQnaControllerService.getMyQnaListUsingGet({
      page: targetPage,
      pagePerCount: itemsPerPage,
    });
    if (resultCode === `success`) {
      setItems(data);

      // 페이지네이션 정보
      const currentPageInfo = getCurrentPageInfo({
        totalItemCount: totalCount || 0,
        currentPage: targetPage,
        pageGroupSize: 5,
        itemsPerPage,
        baseUri: `/`,
        keyword: ``,
      });

      setPageInfo(currentPageInfo);
    }
  }, []);

  useEffect(() => {
    loadItems(currentPage);
  }, [currentPage, loadItems]);

  return (
    <LayoutWithTitle
      location={location}
      title="마이페이지"
      description="for every child, UNICEF"
    >
      <MypageRoutes currentKey={7} />
      <FaqCategory>
        <Container>
          <NotiBox
            css={`
              position: relative;
            `}
          >
            <NotiList>
              <NotiItem>FAQ에서 더 빠른 답변을 확인하실 수 있습니다.</NotiItem>
              <NotiItem>
                답변을 확인할 수 없다면 문의하기를 이용해 직접 문의해주세요.
              </NotiItem>
            </NotiList>
          </NotiBox>
          <FaqLinkParagraph>
            궁금한 것이 있나요?
            <LinkSafe to="/faq">FAQ에서 확인하기</LinkSafe>
          </FaqLinkParagraph>
          <FaqCategoryList>
            <FaqCategoryItem>
              <LinkSafe to="/faq?category=1&hashTag=101&keyword=후원한%20기부금#categorySection">
                <img src={IcoFaqCategory1} alt="" />
                <H3_2>
                  제가 후원한 기부금은 <br />
                  어떻게 어린이에게 전달되나요?
                </H3_2>
              </LinkSafe>
            </FaqCategoryItem>
            <FaqCategoryItem>
              <LinkSafe to="/faq?category=1&hashTag=101&keyword=기부금%20영수증%20신청#categorySection">
                <img src={IcoFaqCategory2} alt="" />
                <H3_2>
                  기부금 영수증 신청은 <br />
                  어떻게 해야 하나요?
                </H3_2>
              </LinkSafe>
            </FaqCategoryItem>
            <FaqCategoryItem>
              <LinkSafe to="/faq?category=1&hashTag=101&keyword=후원을%20신청했는데#categorySection">
                <img src={IcoFaqCategory3} alt="" />
                <H3_2>
                  후원을 신청했는데 <br />
                  웹사이트에서 확인되지 않습니다. <br />
                  어떻게 해야 하나요?
                </H3_2>
              </LinkSafe>
            </FaqCategoryItem>
            <FaqCategoryItem>
              <LinkSafe to="/faq?category=1&hashTag=101&keyword=후원%20정보%20변경#categorySection">
                <img src={IcoFaqCategory4} alt="" />
                <H3_2>
                  후원 정보를 변경하고 싶은데, <br />
                  어떻게 해야 하나요? <br />
                  (개인정보, 결제정보 등)
                </H3_2>
              </LinkSafe>
            </FaqCategoryItem>
            <FaqCategoryItem>
              <LinkSafe to="/faq?category=1&hashTag=102&keyword=캠페인%20참여%20굿즈#categorySection">
                <img src={IcoFaqCategory5} alt="" />
                <H3_2>
                  캠페인 참여 굿즈 <br />
                  (팀팔찌, 프로미스링) <br />
                  배송은 언제쯤 될까요?
                </H3_2>
              </LinkSafe>
            </FaqCategoryItem>
            <FaqCategoryItem>
              <LinkSafe to="/faq?category=1&hashTag=101&keyword=후원을%20중단#categorySection">
                <img src={IcoFaqCategory6} alt="" />
                <H3_2>
                  후원을 중단하고 싶은데, <br />
                  어떻게 해야 하나요?
                </H3_2>
              </LinkSafe>
            </FaqCategoryItem>
          </FaqCategoryList>
        </Container>
      </FaqCategory>
      <SectionTop>
        <ContainerGroup>
          <Container>
            <ButtonAlign>
              <Button onClick={() => navigate(`/mypage/onetoone/form`)}>
                문의하기
              </Button>
            </ButtonAlign>
            <FaqAccordionList>
              {items.length ? (
                <>
                  <AccordionComponent>
                    {items.map((item, i) => (
                      <AccordionItem
                        key={item.indexNumber}
                        toggleKey={i}
                        renderToggle={(onClick) => (
                          <NavigationButton
                            onClick={onClick}
                            className="accordion-opener"
                          >
                            <OneToOneCategory>
                              {ONE_TO_ONE_CATEGORIES[item.optData4]}
                            </OneToOneCategory>
                            <OneToOneStatus className="icons">
                              {item.replyOkYn === `Y` ? (
                                <StatusIcon complete>답변완료</StatusIcon>
                              ) : (
                                <StatusIcon>답변대기</StatusIcon>
                              )}
                            </OneToOneStatus>
                            <OneToOneSubject>{item.subject}</OneToOneSubject>
                            <OneToOneDate className="dates">
                              {item.firstRegisterDate}
                            </OneToOneDate>
                          </NavigationButton>
                        )}
                      >
                        <AccordionBody>
                          <Question
                            dangerouslySetInnerHTML={{ __html: item.contents }}
                          />
                          {item.replyData?.map((reply) => (
                            <Answer key={reply.indexNumber}>
                              <AnswerInner
                                dangerouslySetInnerHTML={{
                                  __html: reply.replyContents || `&nbsp;`,
                                }}
                              />
                            </Answer>
                          ))}
                        </AccordionBody>
                      </AccordionItem>
                    ))}
                  </AccordionComponent>
                  {pageInfo && (
                    <div
                      css={`
                        margin-top: 96px;
                      `}
                    >
                      <SimplePagination
                        {...pageInfo}
                        handleCurrentPage={handleCurrentPage}
                      />
                    </div>
                  )}
                </>
              ) : (
                <NoData>등록하신 문의가 없습니다.</NoData>
              )}
            </FaqAccordionList>
          </Container>
          <ContactContainer>
            <TitleArea>
              <H2>
                <H3_1
                  css={`
                    color: #1cabe2;
                  `}
                >
                  빠른 답변을 원하시나요?
                </H3_1>
              </H2>
              <p>
                빠른 답변을 원하시면 전화문의 바랍니다. 후원 중단 문의는 정확한
                안내를 위해 전화로 접수 받고 있습니다.
              </p>
            </TitleArea>
            <h3 className="a11y">문의</h3>
            <ContactUs itemData={contactData} />
          </ContactContainer>
        </ContainerGroup>
      </SectionTop>
    </LayoutWithTitle>
  );
};
export default OneToOne;
