import { MyRewardControllerService } from '@/__generated__/FrontApi';
import Container from '@/components/Container';
import LinkSafe from '@/components/LinkSafe';
import MyRewardAddressItem, {
  RewardAddressPostParams,
} from '@/components/Mypage/MyRewardAddressItem';
import { NotiBox, NotiList, NotiItem } from '@/components/Text/TextNoti';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { ContainerGroup, SectionTop } from '@/page-blocks/mypage/MypageCommon';
import MypageRoutes from '@/page-blocks/mypage/MypageRoutes';
import { usePopupStore } from '@/stores/PopupStore';
import { observer } from 'mobx-react-lite';
import React, { FC, useCallback, useEffect, useState } from 'react';

// 발송현황 인터페이스
export interface MyRewardVo {
  giftId: string;
  zipCode: string;
  address: string;
  addressDetail: string;
  addressEditYn: 'Y' | 'N';
  donorName: string;
  solicitCode: string;
}

const MyReward: FC = observer(({ location }) => {
  const popupStore = usePopupStore();
  const [items, setItems] = useState<MyRewardVo[]>([]);

  // 발송현황 로드
  const loadItems = useCallback(async () => {
    const { data } = await MyRewardControllerService.getMyRewardListUsingGet();

    setItems(data);
  }, []);

  useEffect(() => {
    loadItems();
  }, [loadItems]);

  const handleItemChange = useCallback(
    async (params: RewardAddressPostParams) => {
      try {
        const {
          resultCode,
          resultMessage,
        } = await MyRewardControllerService.postMyAddressEditUsingPost(params);
        if (resultCode === `success`) {
          // 데이터 업데이트
          loadItems();
          return true;
        }

        popupStore.show(resultMessage);
      } catch (e) {
        if (e.response) {
          popupStore.show(
            e.response.data.resultMessage || `저장중 오류가 발생했습니다`,
          );
        }
      }

      return false;
    },
    [loadItems, popupStore],
  );

  return (
    <LayoutWithTitle
      title="마이페이지"
      location={location}
      description="for every child, UNICEF"
    >
      <MypageRoutes currentKey={6} />
      <SectionTop>
        <ContainerGroup>
          <Container>
            <NotiBox>
              <NotiList>
                <NotiItem>
                  반지, 팔찌는 첫 후원금이 납부된 이후 일반우편으로 순차
                  발송됩니다.
                </NotiItem>
                <NotiItem>
                  발송예정일 후 1-2주 이내에 우편함에서 수령하실 수 있습니다.
                </NotiItem>
                <NotiItem>
                  일시후원만 신청하거나 후원금을 정상적으로 납부하지 않은 경우
                  발송이 제한될 수 있습니다.
                </NotiItem>
                <NotiItem>
                  발송 관련 문의는 1:1 게시판으로 연락 부탁드립니다.
                </NotiItem>
                <NotiItem>
                  기타 문의사항은 <LinkSafe to="/faq">FAQ</LinkSafe>를
                  확인해주세요.
                </NotiItem>
              </NotiList>
            </NotiBox>
          </Container>
          <Container>
            <ul>
              {items.map((item) => (
                <MyRewardAddressItem
                  key={item.giftId}
                  item={item}
                  onItemChange={handleItemChange}
                />
              ))}
            </ul>
          </Container>
        </ContainerGroup>
      </SectionTop>
    </LayoutWithTitle>
  );
});

export default MyReward;
